import React from "react";
import TableCell from "@mui/material/TableCell";

export default function ExpandableRowTitle({title}) {
    return (
        <TableCell component="th" scope="row">
            {title}
        </TableCell>
    );
}
