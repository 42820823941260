import React from "react";
import {Link} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import {doTranslate, Translate} from "../Translate";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        textAlign: "center",
        textDecoration: "none",
        "&:hover, &:focus": {
            boxShadow: "0px 2px 4px 1px rgb(0 0 0 / 20%)"
        },
        height: "100%"
    },
    flexContainer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    },
    mb10: {
        marginBottom: "10px"
    }
}));

export default function Statistics() {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={6} md={3} lg={3}>
                    <Paper
                        className={classes.paper}
                        name={"music"}
                        component={Link}
                        to="/statistics/music"
                    >
                        <div className={classes.flexContainer}>
                            <img src={"/img/feiyrIcons/music.png"} alt={doTranslate("MusicStats")}
                                 className={classes.mb10}/>
                            <Typography variant="body1" component={"span"}>
                                <Translate text="MusicStats"/>
                            </Typography>
                        </div>
                    </Paper>
                </Grid>

                <Grid item xs={6} md={3} lg={3}>
                    <Paper
                        className={classes.paper}
                        name="ebook"
                        component={Link}
                        to="/statistics/ebooks"
                    >
                        <div className={classes.flexContainer}>
                            <img src={"/img/feiyrIcons/ebook.png"} alt={doTranslate("EbookStats")}
                                 className={classes.mb10}/>
                            <Typography variant="body1" component={"span"}>
                                <Translate text="EbookStats"/>
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <Paper
                        className={classes.paper}
                        name={"spotify playlist"}
                        component={Link}
                        to="/statistics/playlists/spotify"
                    >
                        <div className={classes.flexContainer}>
                            <img src={"/img/feiyrIcons/spotify.png"} alt={doTranslate("Spotifyplaylist")}
                                 className={classes.mb10}/>
                            <Typography variant="body1" component={"span"}>
                                <Translate text="Spotifyplaylist"/>
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <Paper
                        className={classes.paper}
                        name={"apple music playlist"}
                        component={Link}
                        to="/statistics/playlists/applemusic"
                    >
                        <div className={classes.flexContainer}>
                            <img src={"/img/feiyrIcons/apple.png"} alt={doTranslate("AppleMusicBundles")}
                                 className={classes.mb10}/>
                            <Typography variant="body1" component={"span"}>
                                <Translate text="AppleMusicBundles"/>
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <Paper
                        className={classes.paper}
                        name={"sales"}
                        component={Link}
                        to="/statistics/nova"
                    >
                        <div className={classes.flexContainer}>
                            <img src={"/img/feiyrIcons/physicalStatistics.png"} alt={doTranslate("Physical")} className={classes.mb10}/>
                            <Typography variant="body1" component={"span"}>
                                <Translate text="Nova Sales"/>
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
