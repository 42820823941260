import React, {useState} from "react";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import useWindowDimensions from "../../js/windowDimensions";
import {doTranslate, Translate} from "../Translate";
import IsoDate from "../../js/IsoDate";
import {FeiyrApi} from "../../js/FeiyrApi";
import DataTable from "../datatable/DataTable";
import ErrorBox from "../ErrorBox";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    expandableRow: {
        backgroundColor: theme.palette.expandableRow.main
    },
    unreleased: {
        backgroundColor: theme.palette.unreleased.main,
        color: theme.palette.common.black
    },
    pending: {
        backgroundColor: theme.palette.pending.main,
        color: theme.palette.text.primary
    },
    published: {
        backgroundColor: theme.palette.published.main,
        color: theme.palette.text.primary
    }
}));

export const MusicBundles = ({type}) => {
    const classes = useStyles();
    const [error, setError] = useState(null);
    const [data, setData] = React.useState(false);
    const [labels, setLabels] = React.useState([]);
    const [entryCount, setEntryCount] = React.useState(0);
    const {width} = useWindowDimensions();
    const columns = [
        {
            name: "cover",
            label: doTranslate("Cover"),
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({
                    style: {width: "20px", paddingLeft: 0},
                }),
                setCellProps: () => ({style: {width: "20px", paddingLeft: 0}}),
                customBodyRender: (value, tableMeta) => {
                    if (value === undefined || value === null || value.toString().trim() === "") {
                        return (
                            <img
                                alt={tableMeta.rowData[1]}
                                src="https://accounts.feiyr.com/img/icons/no_image2.png"
                                style={{width: "100%", padding: "0px", margin: "0px"}}
                            />
                        );
                    }
                    else {
                        return (
                            <img
                                alt={tableMeta.rowData[1]}
                                src={`${process.env.REACT_APP_DOWNLOAD_URL}/cover/65/0/${tableMeta.rowData[5]}_80.jpg`}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                        "https://accounts.feiyr.com/img/icons/no_image2.png";
                                }}
                                style={{width: "100%", padding: "0px", margin: "0px"}}
                            />
                        );
                    }
                },
            },
        },
        {
            name: "artist",
            label: doTranslate("Info"),
            options: {
                filter: false,
                sort: true,
                searchable: true,
                setCellHeaderProps: () => ({
                    style: {width: "100%", flexGrow: 1, paddingLeft: 10},
                }),
                setCellProps: () => ({style: {flexGrow: 1, paddingLeft: 10}}),
                customBodyRender: (value, tableMeta) => {
                    const artist = tableMeta.rowData[1];
                    const title = tableMeta.rowData[7];
                    return (
                        <div>
                            <p style={{fontSize: "0.7rem", margin: "0"}}>{artist}</p>
                            <p
                                style={{
                                    fontWeight: "700",
                                    paddingTop: "0rem",
                                    paddingBottom: "0.5rem",
                                    margin: "0",
                                }}
                            >
                                {title}
                            </p>
                        </div>
                    );
                },
            },
        },

        {
            name: "published",
            label: doTranslate("Status"),
            options: {
                filter: true,
                sort: true,
                filterOptions: {
                    names: [doTranslate("Unreleased"), doTranslate("Pending"), doTranslate("Released")],
                },
                setCellHeaderProps: () => ({
                    style: {textAlign: "right", width: "100%"},
                }),
                setCellProps: () => ({
                    style: {textAlign: "right", marginRight: "1rem"},
                }),
                customBodyRender: (value) => {
                    switch (value) {
                        case doTranslate("Released"):
                            return (
                                <Tooltip
                                    title={doTranslate("Released")}
                                    placement="left"
                                    style={{zIndex: "99999", textAlign: "right"}}
                                >
                                    <Chip
                                        label={width >= 500 ? doTranslate("Released") : doTranslate("R")}
                                        style={{
                                            zIndex: "99999",
                                            textAlign: "right",
                                        }}
                                        className={classes.published}
                                    />
                                </Tooltip>
                            );
                        case doTranslate("Unreleased"):
                            return (
                                <Tooltip
                                    title={doTranslate("Unreleased")}
                                    placement="left"
                                    style={{zIndex: "99999", textAlign: "right"}}
                                >
                                    <Chip
                                        label={width >= 500 ? doTranslate("Unreleased") : doTranslate("U")}
                                        style={{
                                            zIndex: "99999",
                                            textAlign: "right",
                                        }}
                                        className={classes.unreleased}
                                    />
                                </Tooltip>
                            );
                        case doTranslate("Pending"):
                            return (
                                <Tooltip
                                    title={doTranslate("Pending")}
                                    placement="left"
                                    style={{zIndex: "99999", textAlign: "right"}}
                                >
                                    <Chip
                                        label={width >= 500 ? doTranslate("Pending") : doTranslate("P")}
                                        style={{
                                            zIndex: "99999",
                                            textAlign: "right",
                                        }}
                                        className={classes.pending}
                                    />
                                </Tooltip>
                            );
                        default:
                            return "Error";
                    }
                },
            },
        },
        {
            name: "digital_release_date",
            label: doTranslate("Release Date"),
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },
        {
            name: "EAN",
            label: doTranslate("EAN"),
            options: {
                filter: false,
                display: false,
                searchable: true,
            },
        },
        {
            name: "bundle_id",
            label: doTranslate("Bundle ID"),
            options: {
                filter: false,
                display: false,
                searchable: true,
            },
        },
        {
            name: "label",
            label: doTranslate("Label"),
            options: {
                filter: true,
                display: false,
                searchable: true,
                filterOptions: {
                    names: labels,
                }
            },
        },
        {
            name: "title",
            label: doTranslate("Title"),
            options: {
                filter: false,
                display: false,
                searchable: true,
            },
        },
    ];

    function fetchB(dataTable) {
        new FeiyrApi().get("/Bundle/FullList/" + localStorage.getItem("userId"), {type: type}, dataTable)
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    let values = res.data.values.map((el) => {
                        let title = el.title;
                        if (el.version !== null && el.version.toString().trim() !== "") {
                            if (title.match(/\(.+\)/) !== null) {
                                title += " [" + el.version + "]";
                            }
                            else {
                                title += " (" + el.version + ")";
                            }
                        }
                        let published = doTranslate("Unreleased");
                        if (el.publish_status === 2 || el.publish_status === "2") {
                            published = doTranslate("Released");
                        }
                        else if (el.publish_status === 1 || el.publish_status === "1") {
                            published = doTranslate("Pending");
                        }
                        return {
                            cover: el.cover_file_name,
                            artist: el.artist,
                            published: published,
                            digital_release_date: el.digital_release_date,
                            EAN: el.EAN,
                            bundle_id: el.bundle_id,
                            label: el.label,
                            title: title
                        };
                    });
                    setLabels(res.data.labels);
                    setData(values);
                    setEntryCount(res.data.display.iTotalRecords);
                }
                else {
                    setError({userMessage: "Error fetching data"});
                }
            })
            .catch(err => {
                err.userMessage = "Error fetching data";
                setError(err);
            });
    }

    const weirdFilter = (i, value) => {
        if (columns[i]["name"] === "published") {
            if (value === doTranslate("Unreleased")) {
                return 0;
            }
            else if (value === doTranslate("Pending")) {
                return 1;
            }
            else {
                return 2;
            }
        }
        return value;
    }

    const renderExpandableRow = (rowData) => {
        const chipClass =
            rowData[2].props.title === doTranslate("Unreleased")
                ? classes.unreleased
                : (rowData[2].props.title === doTranslate("Released")
                    ? classes.published
                    : classes.pending
                );
        return (
            <React.Fragment>
                <tr>
                    <td colSpan={6}>
                        <TableContainer component={Paper}>
                            <Table
                                aria-label=""
                                style={{width: "100%"}}
                                className={classes.expandableRow}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Translate text="Details"/></TableCell>
                                        <TableCell align="right">
                                            {width < 500 && (
                                                <Chip
                                                    label={rowData[2].props.title}
                                                    style={{
                                                        zIndex: "99999",
                                                    }}
                                                    className={chipClass}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <Translate text="EAN"/>:
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="right">
                                            {rowData[4]}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <Translate text="Bundle ID"/>:
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="right">
                                            {rowData[5]}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <Translate text="Label"/>:
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="right">
                                            {rowData[6]}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <Translate text="Release Date"/>:
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="right">
                                            {(IsoDate(rowData[3])).toLocaleDateString()}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {error === null ? (
                <DataTable
                    title={doTranslate("Bundle List")}
                    data={data}
                    entryCount={entryCount}
                    columns={columns}
                    fetchData={fetchB}
                    weirdFilter={weirdFilter}
                    tableOptions={{
                        "renderExpandableRow": renderExpandableRow
                    }}
                />
            ) : (
                <ErrorBox error={error}/>
            )}
        </React.Fragment>
    );
};
