import React, {useState} from "react";
import {createData, playlistBodyRender,} from "./PlayListTableSettings";
import {doTranslate} from "../../Translate";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ExpandableRowTitle from "../../ExpandableRowTitle";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DataTable from "../../datatable/DataTable";
import {FeiyrApi} from "../../../js/FeiyrApi";
import ErrorBox from "../../ErrorBox";
import IconButton from "@mui/material/IconButton";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    img: {
        height: "1.5rem",
        width: "auto",
    },
    expandableRow: {
        backgroundColor: theme.palette.expandableRow.main
    },
}));

export const Spotify = () => {
    const [error, setError] = useState(null);
    const [data, setData] = useState(false);
    const [entryCount, setEntryCount] = useState(0);
    const [artists, setArtists] = useState([]);
    const [tracks, setTracks] = useState([]);
    const [available, setAvailable] = useState();
    const classes = useStyles();

    const handleClick = (e, value) => {
        e.currentTarget.name = "spotifyBrowser"
            ? window.open(`https://open.spotify.com/playlist/${value}`, "_blank")
            : null;
        e.currentTarget.name = "spotifyApp"
            ? window.open(`spotify:playlist:${value}`, "_blank")
            : null;
    };

    const columns = [
        {
            name: "artist",
            label: doTranslate("Artist"),
            options: {
                filter: true,
                sort: false,
                display: false,
                searchable: true,
                filterOptions: {
                    names: artists,
                }
            }
        },
        {
            name: "title",
            label: doTranslate("Title"),
            options: {
                filter: true,
                sort: false,
                display: false,
                searchable: true,
                filterOptions: {
                    names: tracks,
                }
            }
        },
        {
            name: "playlist_name",
            label: doTranslate("Playlist Name"),
            options: {
                filter: false,
                sort: false,
                display: false,
                searchable: true,
            }
        },
        {
            name: "followers",
            label: doTranslate("Followers"),
            options: {
                filter: false,
                sort: true,
                display: false,
                searchable: false,
                setCellHeaderProps: () => ({
                    style: {textAlign: "right", width: "5%"},
                }),
                setCellProps: () => ({
                    style: {textAlign: "right", marginRight: "1rem"},
                }),
            },
        },
        {
            name: "plays",
            label: doTranslate("Plays"),
            options: {
                display: false,
                filter: false,
                sort: true,
                searchable: false,
                setCellHeaderProps: () => ({
                    style: {textAlign: "right", width: "5%"},
                }),
                setCellProps: () => ({
                    style: {textAlign: "right", marginRight: "1rem"},
                }),
            },
        },
        {
            name: "playlistInfo",
            label: doTranslate("Info"),
            options: {
                filter: false,
                sort: true,
                searchable: false,
                setCellHeaderProps: () => ({
                    style: {flexGrow: 1, paddingLeft: 10},
                }),
                setCellProps: () => ({style: {flexGrow: 1, paddingLeft: 10}}),
                customBodyRender: (value, tableMeta) => {
                    return playlistBodyRender(value, tableMeta);
                },
            },
        },
        {
            name: "playlist_id",
            label: doTranslate("Links"),
            options: {
                filter: false,
                sort: true,
                searchable: false,
                setCellHeaderProps: () => ({
                    style: {textAlign: "right", width: "5%"},
                }),
                setCellProps: () => ({
                    style: {textAlign: "right", marginRight: "1rem"},
                }),
                customBodyRender: (value) => {
                    return (
                        <div>
                            <IconButton
                                onClick={(e) => handleClick(e, value)}
                                name={"spotifyApp"}
                            >
                                <img src="/img/Spotify_Icon_RGB_Green.png" className={classes.img} alt={"icon"}/>
                            </IconButton>
                        </div>
                    );
                },
            },
        },
    ];

    function fetchData(dataTable) {
        if (available) {
            getPlaylistTable(dataTable);
        }
        else {
            new FeiyrApi().get("/LiveSales/StatsAvailable/" + localStorage.getItem("userId"))
                .then(res => {
                    if (res !== undefined && (res.success === true || res.success === "true")) {
                        setAvailable(true);
                        getPlaylistTable(dataTable);
                    }
                    else {
                        setAvailable(false);
                    }
                })
                .catch(err => {
                    err.userMessage = "Error fetching availability status";
                    setError(err);
                });
        }
    }

    function getPlaylistTable(dataTable) {
        new FeiyrApi().get("/SpotifyPlaylists/Table/" + localStorage.getItem("userId"), {}, dataTable)
            .then((res) => {
                if (res !== undefined && (res.success === "true" || res.success === true)) {
                    let d = res.data.values.map((el) => {
                        return createData(
                            el.artist,
                            el.playlistname,
                            el.playlist_id,
                            el.plays,
                            el.title,
                            el.track_id,
                            el.followers
                        );
                    });
                    setData(d);
                    setEntryCount(res.data.display.iTotalRecords);
                    setArtists(res.data.artists);
                    setTracks(res.data.tracks);
                }
                else {
                    setError({userMessage: "Error fetching data"});
                }
            })
            .catch(err => {
                err.userMessage = "Error fetching data";
                setError(err);
            });
    }

    const weirdFilter = (i, value) => {
        if (columns[i]["name"] === "published") {
            if (value === doTranslate("Unreleased")) {
                value = 0;
            }
            else if (value === doTranslate("Pending")) {
                value = 1;
            }
            else {
                value = 2;
            }
        }
        return value;
    };
    const renderExpandableRow = (rowData) => {
        return (
            <React.Fragment>
                <tr>
                    <td colSpan={6}>
                        <TableContainer component={Paper}>
                            <Table
                                aria-label="simple table"
                                style={{width: "100%"}}
                                className={classes.expandableRow}
                            >
                                <TableHead>
                                    <TableRow>
                                        <ExpandableRowTitle title={doTranslate("Details")}/>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <ExpandableRowTitle title={doTranslate("Followers")}/>
                                        <TableCell component="th" scope="row" align="right">
                                            {rowData[3]}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <ExpandableRowTitle title={doTranslate('Plays')}/>
                                        <TableCell component="th" scope="row" align="right">
                                            {rowData[4]}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </td>
                </tr>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {error === null ? (
                <DataTable
                    title={doTranslate("Spotify Playlist")}
                    data={data}
                    entryCount={entryCount}
                    columns={columns}
                    fetchData={fetchData}
                    weirdFilter={weirdFilter}
                    tableOptions={{
                        "renderExpandableRow": renderExpandableRow
                    }}
                />
            ) : (
                <ErrorBox error={error}/>
            )}
        </React.Fragment>
    );
};
