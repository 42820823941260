import React, {useEffect} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Cookies from "js-cookie";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AuthModal(props) {
    const {location, setAppConnected} = props;
    const [open, setOpen] = React.useState(false);
    const lang =
        localStorage.getItem("lang") !== null
            ? localStorage.getItem("lang")
            : navigator.language.slice(0, 2) || navigator.userLanguage.slice(0, 2);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const queryString = location.search;
        const urlParams = new URLSearchParams(queryString);
        const authorizationCode = urlParams.get("code");

        if (authorizationCode !== "") {
            if (typeof Cookies.get("token" + process.env.REACT_APP_COOKIE_VERSION) === "undefined") {
                setAppConnected(false);
                setOpen(true);
            }
            else if (authorizationCode !== null) {
                setOpen(false);
            }
            /*else {
                setAppConnected(true);
            }*/
        }
        else {
            setOpen(false);
        }
    }, [location.search, setAppConnected]);

    const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_OAUTH_CLIENT_SECRET;
    const url = process.env.REACT_APP_URL;
    const authUrl = `${url}/authorize?response_type=code&client_id=${clientId}&state=${clientSecret}`;

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {lang === "de"
                        ? "Dein Account ist scheinbar noch nicht verknüpft."
                        : "It seems that your are not connected."}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {lang === "de"
                            ? "Um die Anwendung mit Deinen persönlichen Daten zu verwenden, melde Dich bitte an."
                            : "In order to use the app with your personal data, please log into your Feiyr account."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {lang === "de" ? "Abbrechen" : "Cancel"}
                    </Button>
                    <Button onClick={handleClose} color="primary" href={authUrl}>
                        {lang === "de" ? "Anmelden" : "Login"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
