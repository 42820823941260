import React, {useState} from "react";
import {doTranslate, Translate} from "../Translate";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {FeiyrApi} from "../../js/FeiyrApi";
import DataTable from "../datatable/DataTable";
import ErrorBox from "../ErrorBox";

export default function LinkItJobs({type}) {
    const [error, setError] = useState(null);
    const [data, setData] = useState(false);
    const [entryCount, setEntryCount] = useState(0);

    const columns = [
        {
            name: "cover",
            label: doTranslate("Cover"),
            options: {
                filter: false,
                sort: true,
                display: true,
                searchable: false,
                customBodyRender: (value, tableMeta) => {
                    let src;
                    if (type === "ebook") {
                        src = "/ebookCover/1/" + tableMeta.rowData[6] + "_80.jpg";
                    }
                    else if (type === "physical") {
                        src = "/novamedia/frontself/" + tableMeta.rowData[6] + ".jpg/80.jpg";
                    }
                    else {
                        src = "/cover/65/0/" + tableMeta.rowData[6] + "_80.jpg";
                    }
                    let alt = tableMeta.rowData[1] + " - " + tableMeta.rowData[2];
                    return (
                        <img
                            alt={alt}
                            src={`${process.env.REACT_APP_DOWNLOAD_URL}${src}`}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                    "https://accounts.feiyr.com/img/icons/no_image2.png";
                            }}
                            style={{width: "100%", padding: "0px", margin: "0px"}}
                        />
                    );
                },
            },
        },
        {
            name: "title",
            label: doTranslate("Product"),
            options: {
                filter: false,
                sort: false,
                display: true,
                searchable: true,
                setCellHeaderProps: () => ({
                    style: {width: "100%", flexGrow: 1, paddingLeft: 10},
                }),
                setCellProps: () => ({style: {flexGrow: 1, paddingLeft: 10}}),
                customBodyRender: (value, tableMeta) => {
                    const artist = tableMeta.rowData[2];
                    const title = tableMeta.rowData[1];
                    return (
                        <div>
                            <p style={{fontSize: "0.7rem", margin: "0"}}>{artist}</p>
                            <p
                                style={{
                                    fontWeight: "700",
                                    paddingTop: "0rem",
                                    paddingBottom: "0.5rem",
                                    margin: "0",
                                }}
                            >
                                {title}
                            </p>
                        </div>
                    );
                },
            }
        },
        {
            name: "artist",
            label: doTranslate("Artist"),
            options: {
                filter: false,
                sort: false,
                display: false,
                searchable: true
            }
        },
        {
            name: "widget_calls",
            label: doTranslate("Widget Calls"),
            options: {
                filter: false,
                sort: false,
                display: false,
                searchable: false
            }
        },
        {
            name: "actions",
            label: doTranslate("Actions"),
            options: {
                filter: false,
                sort: false,
                display: true,
                searchable: false,
                customBodyRender: (value, tableMeta) => {
                    const copyLink = () => {
                        navigator.clipboard.writeText(tableMeta.rowData[7]);
                    }
                    return (
                        <div>
                            <IconButton onClick={copyLink}>
                                <Typography component="span" style={{fontSize: "0.8rem"}}>
                                    <Translate text="Copy Link"/>
                                </Typography>
                            </IconButton>
                        </div>
                    );
                }
            }
        },
        {
            name: "job_id",
            label: "Job ID",
            options: {
                filter: false,
                sort: false,
                display: false,
                searchable: false
            }
        },
        {
            name: "content_id",
            label: "Content ID",
            options: {
                filter: false,
                sort: false,
                display: false,
                searchable: false
            }
        },
        {
            name: "link",
            label: "Link",
            options: {
                filter: false,
                sort: false,
                display: false,
                searchable: false
            }
        }
    ];

    function fetchData(dataTable) {
        let params = {
            user_id: localStorage.getItem("userId")
        };
        let endpoint = "LinkGenerator";
        if (type === "ebook") {
            endpoint = "LinkGeneratorEbooks";
        }
        else if (type === "physical") {
            endpoint = "LinkGeneratorPhysical";
        }
        new FeiyrApi().get("/" + endpoint + "/Table", params, dataTable)
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    let values = res.data.values.map((el) => {
                        let artist, content_id;
                        if (type === "ebook") {
                            artist = el.author_name;
                            content_id = el.book_id;
                        }
                        else if (type === "physical") {
                            artist = el.artist;
                            content_id = el.EAN;
                        }
                        else {
                            artist = el.artist_name;
                            content_id = el.bundle_id;
                        }
                        return {
                            cover: null,
                            title: el.title,
                            artist: artist,
                            widget_calls: el.widget_calls,
                            actions: null,
                            job_id: el.job_id,
                            content_id: content_id,
                            link: el.complete_link
                        };
                    });
                    setData(values);
                    setEntryCount(res.data.display.iTotalRecords);
                } else {
                    setError({userMessage: "Error fetching data"});
                }
            })
            .catch(err => {
                err.userMessage = "Error fetching data";
                setError(err);
            });
    }

    let title;
    if (type === "ebook") {
        title = "Link-It EBook";
    }
    else if (type === "physical") {
        title = "Link-It Physical";
    }
    else {
        title = "Link-It Music";
    }

    return (
        <React.Fragment>
            {error === null ? (
                <DataTable
                    title={doTranslate(title)}
                    data={data}
                    entryCount={entryCount}
                    columns={columns}
                    fetchData={fetchData}
                    tableOptions={{
                        expandableRows: false,
                        expandableRowsOnClick: false
                    }}
                />
            ) : <ErrorBox error={error}/>}
        </React.Fragment>
    )
}