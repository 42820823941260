import React from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import MailIcon from "@mui/icons-material/Mail";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import PublicIcon from "@mui/icons-material/Public";
import PhoneIcon from "@mui/icons-material/Phone";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LinkIcon from "@mui/icons-material/Link";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {MenuItem} from "./MenuItem";
import {Logout} from "./Logout";
import Divider from "@mui/material/Divider";
import PeopleAlt from "@mui/icons-material/PeopleAlt";
import AssignmentInd from "@mui/icons-material/AssignmentInd";

export default function MainListItems(props) {
    const listItems = [
        {
            key: "home",
            url: "/",
            text: "Home",
            icon: <HomeIcon/>,
            external: false,
        },
        {
            key: "statistics",
            url: "/statistics",
            text: "Statistics",
            icon: <BarChartIcon/>,
            external: false,
        },
        {
            key: "bundles",
            url: "/bundles",
            text: "Bundles",
            icon: <PublicIcon/>,
            external: false,
        },
        {
            key: "linkit",
            url: "/linkit",
            text: "Link-It",
            icon: <LinkIcon/>,
            external: false
        },
        {
            key: "artistprofiles",
            url: "/artistprofiles",
            text: "Artist Profiles",
            icon: <AssignmentInd/>,
            external: false,
        },
        {
            key: "licenserequests",
            url: "/licenserequests",
            text: "License Requests",
            icon: <PeopleAlt/>,
            external: false,
        },
        {
            key: "statements",
            url: "/statements",
            text: "BankStatements",
            icon: <AttachMoneyIcon/>,
            external: false,
        },
        {
            key: "nova",
            url: "/nova",
            text: "Nova MD",
            icon: <MenuBookIcon/>,
            external: false
        },
        {
            key: "inbox",
            url: "/inbox",
            text: "Inbox",
            icon: <MailIcon/>,
            external: false,
        },
        {
            key: "contact",
            url: "/contact",
            text: "Contact",
            icon: <PhoneIcon/>,
            external: false,
        },
        {
            key: "faq",
            url: `https://www.feiyr.com/${localStorage.getItem("lang")}/faq.html`,
            text: "FAQ",
            icon: <ContactSupportIcon/>,
            external: true,
        },
        {
            key: "settings",
            url: `/settings`,
            text: "Settings",
            icon: <SettingsIcon/>,
            external: false,
        },
    ];

    return (
        <div>
            {listItems.map((el) => {
                return (
                    <MenuItem
                        key={el.key}
                        url={el.url}
                        text={el.text}
                        icon={el.icon}
                        external={el.external}
                    />
                );
            })}
            <Divider/>
            <Logout appConnected={props.appConnected} setAppConnected={props.setAppConnected}/>
        </div>
    );
}
