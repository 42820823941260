import React, {useEffect, useState} from "react";
import {doTranslate, Translate} from "../Translate";
import {FeiyrApi} from "../../js/FeiyrApi";
import DataTable from "../datatable/DataTable";
import IsoDate from "../../js/IsoDate";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import ExpandableRowTitle from "../ExpandableRowTitle";
import makeStyles from "@mui/styles/makeStyles";
import ErrorBox from "../ErrorBox";

const useStyles = makeStyles((theme) => ({
    expandableRow: {
        backgroundColor: theme.palette.expandableRow.main
    }
}));

export function NovaInventory() {
    const classes = useStyles();
    const [error, setError] = useState(null);
    const [data, setData] = React.useState(false);
    const [dates, setDates] = React.useState([]);
    const [entryCount, setEntryCount] = React.useState(0);
    const [dt, setDt] = React.useState(false);

    const nowIso = new Date(Date.now()).toISOString().substr(0, 10);

    const columns = [
        {
            name: "articleno",
            label: doTranslate("Article Number"),
            options: {
                filter: false,
                sort: true,
                display: false,
                searchable: true,
            },
        },
        {
            name: "ean",
            label: doTranslate("EAN"),
            options: {
                filter: false,
                sort: true,
                display: false,
                searchable: true,
            }
        },
        {
            name: "artist",
            label: doTranslate("Artist, Title"),
            options: {
                filter: false,
                sort: true,
                display: true,
                searchable: true,
                customBodyRender: (value, tableMeta) => {
                    const artist = tableMeta.rowData[2];
                    const title = tableMeta.rowData[3];
                    return (
                        <div>
                            <p style={{fontSize: "0.7rem", margin: "0"}}>{artist}</p>
                            <p
                                style={{
                                    fontWeight: "700",
                                    paddingTop: "0rem",
                                    paddingBottom: "0.5rem",
                                    margin: "0",
                                }}
                            >
                                {title}
                            </p>
                        </div>
                    );
                },
            }
        },
        {
            name: "title",
            label: doTranslate("Title"),
            options: {
                filter: false,
                sort: true,
                display: false,
                searchable: true
            }
        },
        {
            name: "purchasePrice",
            label: doTranslate("Purchase Price"),
            options: {
                filter: false,
                sort: true,
                display: false,
                searchable: false,
            }
        },
        {
            name: "release_date",
            label: doTranslate("Release Date"),
            options: {
                filter: false,
                sort: true,
                display: false,
                searchable: false
            }
        },
        {
            name: "lastInvoice",
            label: doTranslate("Last Invoice"),
            options: {
                filter: false,
                sort: true,
                display: false,
                searchable: false,
            }
        },
        {
            name: "stock",
            label: doTranslate("Stock"),
            options: {
                filter: false,
                sort: true,
                display: true,
                searchable: false,
            }
        },
        {
            name: "consignment",
            label: doTranslate("Consignment"),
            options: {
                filter: false,
                sort: false,
                display: false,
                searchable: false
            }
        },
        {
            name: "date",
            label: doTranslate("Date"),
            options: {
                filter: true,
                sort: false,
                display: false,
                searchable: false,
                filterOptions: {
                    names: dates,
                    renderValue: (value) => new Date(value).toLocaleDateString()
                },
                customFilterListOptions: {
                    render: (value) => new Date(value).toLocaleDateString()
                }
            }
        }
    ];

    function fetchData(dataTable, isoDate) {
        if (isoDate === undefined || isoDate === null) {
            setDt(dataTable);
        }
        if (isoDate === undefined || isoDate === null || isoDate === nowIso) {
            isoDate = "1001-01-01";
        }
        new FeiyrApi().get("/NovaReturnRequest/Inventory/" + localStorage.getItem("userId") + "/" + encodeURIComponent(isoDate), {}, dataTable)
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    let values = res.data.values.map((el) => {
                        let consignment = el.consignment,
                            stock = el.stock;
                        if (consignment === null || consignment === undefined || isNaN(consignment)) {
                            consignment = 0;
                        }
                        if (stock === null || stock === undefined || isNaN(stock) || stock < 0) {
                            stock = 0;
                        }
                        return {
                            articleno: el.articleno,
                            ean: el.ean,
                            artist: el.artist,
                            title: el.title,
                            purchasePrice: el.purchasePrice,
                            release_date: el.release_date,
                            lastInvoice: el.lastInvoice,
                            stock: stock - consignment,
                            consignment: consignment,
                        };
                    });
                    setData(values);
                    setEntryCount(res.data.display.iTotalRecords);
                }
                else {
                    setError({userMessage: "Error fetching data"});
                }
            })
            .catch(err => {
                err.userMessage = "Error fetching data";
                setError(err);
            });
    }

    useEffect(() => {
        new FeiyrApi().get("/NovaReturnRequest/InventoryDates/" + localStorage.getItem("userId"))
            .then(res => {
                let localDates = [];
                localDates.push(new Date(Date.now()).getTime());
                //localDates.push(new Date(Date.now()).toLocaleDateString());
                res.dates.forEach(date => {
                    localDates.push(new IsoDate(date).getTime());
                    //localDates.push(new IsoDate(date).toLocaleDateString());
                });
                localDates.sort((a, b) => b - a);
                setDates(localDates);
            })
            .catch(e => console.error(e));
    }, []);

    const renderExpandableRow = (rowData) => {
        return (
            <React.Fragment>
                <tr>
                    <td colSpan={6}>
                        <TableContainer component={Paper}>
                            <Table
                                aria-label="simple table"
                                style={{width: "100%"}}
                                className={classes.expandableRow}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Translate text="Details"/></TableCell>
                                        <TableCell align="right"/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <ExpandableRowTitle title={doTranslate('Article Number')}/>
                                        <TableCell component="th" scope="row" align="right">
                                            {rowData[0]}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <ExpandableRowTitle title={doTranslate('EAN')}/>
                                        <TableCell component="th" scope="row" align="right">
                                            {rowData[1]}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <ExpandableRowTitle title={doTranslate('Release Date')}/>
                                        <TableCell component="th" scope="row" align="right">
                                            {IsoDate(rowData[5]).toLocaleDateString()}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <ExpandableRowTitle title={doTranslate('Last Invoice')}/>
                                        <TableCell component="th" scope="row" align="right">
                                            {rowData[6] !== null ? IsoDate(rowData[6]).toLocaleDateString() : ""}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <ExpandableRowTitle title={doTranslate('Consignment')}/>
                                        <TableCell component="th" scope="row" align="right">
                                            {rowData[8]}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </td>
                </tr>
            </React.Fragment>
        );
    };

    const onFilterChange = (column, filterList) => {
        let date = null;
        for (let i = 0; i < filterList.length; i++) {
            if (filterList[i].length > 0) {
                let value = filterList[i][0];
                date = new Date(value).toISOString().substr(0, 10);
            }
        }
        fetchData(dt, date);
    };

    return (
        <React.Fragment>
            {error === null ? (
                <DataTable
                    title={doTranslate("Nova Inventory")}
                    data={data}
                    entryCount={entryCount}
                    columns={columns}
                    fetchData={fetchData}
                    tableOptions={{
                        "renderExpandableRow": renderExpandableRow,
                        "onFilterChange": onFilterChange,
                        "sortFilterList": false
                    }}
                />
            ) : (
                <ErrorBox error={error}/>
            )}
        </React.Fragment>
    );
}