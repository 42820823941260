import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {doTranslate} from "../Translate";
import IconButton from "@mui/material/IconButton";
import Remove from "@mui/icons-material/Remove";
import Add from "@mui/icons-material/Add";
import {getSpotifyUri, isValidSpotifyUri, isValidURL} from "../../js/URL";

export default function ProductLink(props) {
    const [validationError, setValidationError] = useState("");

    const onChangeLink = (event) => {
        let value = event.target.value;
        if (props.partnerShopId === 158) {
            value = getSpotifyUri(value, "album");
            if (!isValidSpotifyUri(value, "album")) {
                setValidationError(doTranslate("Invalid Spotify URI"));
            }
            else {
                setValidationError("");
            }
        }
        else if (!isValidURL(value)) {
            setValidationError(doTranslate(doTranslate("Invalid URI")));
        }
        else {
            setValidationError("");
        }
        props.setLink(props.index, value);
    }

    return (
        <React.Fragment>
            <Grid item xs={10}>
                <TextField
                    style={{width: "100%"}}
                    required
                    id={"product-" + props.index}
                    label={doTranslate("Product Link")}
                    onChange={onChangeLink}
                    value={props.link}
                    variant="outlined"
                    error={validationError !== ""}
                    helperText={validationError}
                />
            </Grid>
            <Grid item xs={2}>
                {props.removable && (
                    <IconButton edge="end" color="inherit" onClick={() => props.onRemove(props.index)}>
                        <Remove/>
                    </IconButton>
                )}
                <IconButton edge="end" color="inherit" onClick={() => props.onAdd()}>
                    <Add/>
                </IconButton>
            </Grid>
        </React.Fragment>
    );
}