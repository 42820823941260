import React from "react";
import {Link} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import {doTranslate, Translate} from "../Translate";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        textAlign: "center",
        textDecoration: "none",
        "&:hover, &:focus": {
            boxShadow: "0px 2px 4px 1px rgb(0 0 0 / 20%)"
        },
        height: "100%"
    },
    flexContainer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    },
    mb10: {
        marginBottom: "10px"
    }
}));

export default function Bundles() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={6} md={3} lg={3}>
                    <Paper
                        className={classes.paper}
                        name={"music"}
                        component={Link}
                        to="/bundles/music"
                    >
                        <div className={classes.flexContainer}>
                            <img src={"/img/feiyrIcons/music.png"} alt={doTranslate("MusicBundles")}
                                 className={classes.mb10}/>
                            <Typography variant="body1" component={"span"}>
                                <Translate text="MusicBundles"/>
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <Paper
                        className={classes.paper}
                        name={"ebook"}
                        component={Link}
                        to="/bundles/ebook"
                    >
                        <div className={classes.flexContainer}>
                            <img src={"/img/feiyrIcons/ebook.png"} alt={doTranslate("EbookBundles")}
                                 className={classes.mb10}/>
                            <Typography variant="body1" component={"span"}>
                                <Translate text="EbookBundles"/>
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <Paper
                        className={classes.paper}
                        name={"radioPlay"}
                        component={Link}
                        to="/bundles/radio-play"
                    >
                        <div className={classes.flexContainer}>
                            <img src={"/img/feiyrIcons/audio.png"} alt={doTranslate("RadioPlay")}
                                 className={classes.mb10}/>
                            <Typography variant="body1" component={"span"}>
                                <Translate text="RadioPlay"/>
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <Paper
                        className={classes.paper}
                        name={"ucg"}
                        component={Link}
                        to="/bundles/ucg"
                    >
                        <div className={classes.flexContainer}>
                            <img src={"/img/feiyrIcons/ugc.png"} alt={doTranslate("UCG")} className={classes.mb10}/>
                            <Typography variant="body1" component={"span"}>
                                <Translate text="UCG"/>
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
