import React from "react";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import Divider from "@mui/material/Divider";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import FormattedTextParser from "../../../js/FormattedTextParser";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

export default function ListItems({data, blogType, classes}) {
    return Object.entries(data).map((el, i) => {
        if (i < 3) {
            const html = `${el[1].content.slice(0, 500)}...`;
            return (
                <React.Fragment key={i}>
                    <ListItem
                        alignItems="flex-start"
                        button
                        component="a"
                        href={el[1].id}
                        target={"_blank"}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                {blogType === "buch" ? <MenuBookIcon/> : <MusicVideoIcon/>}
                            </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                            primary={el[1].title}
                            secondary={
                                <Typography
                                    component={"span"}
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    {FormattedTextParser(html, false, false)}
                                </Typography>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li"/>
                </React.Fragment>
            );
        }
        else {
            return null;
        }
    });
}
