import React, {useEffect, useMemo, useState} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import createTheme from "@mui/material/styles/createTheme";
import makeStyles from "@mui/styles/makeStyles";
import {ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Route, withRouter} from "react-router-dom";
import BottomNav from "./components/navigation/BottomNav";
import useWindowDimensions from "./js/windowDimensions";
import {Settings} from "./components/settings/Settings";
import Dashboard from "./components/dashboard/Dashboard";
import {Notification} from "./components/Notification";
import {Inbox} from "./components/messages/Inbox";
import {Message} from "./components/messages/Message";
import TopNav from "./components/navigation/TopNav";
import SideNav from "./components/navigation/SideNav";
import AuthModal from "./components/template/AuthModal";
import SuccessModal from "./components/template/SuccessModal";
import Cookies from "js-cookie";
import Statistics from "./components/statistics/Statistics";
import {FeiyrApi, postRefreshToken} from "./js/FeiyrApi";
import Ebooks from "./components/statistics/Ebooks";
import Music from "./components/statistics/Music";
import Bundles from "./components/bundles/Bundles";
import {MusicBundles} from "./components/bundles/MusicBundles";
import {EbookBundles} from "./components/bundles/EbookBundles";
import PwaInstallPrompt from "./components/template/PwaInstallPrompt";
import {Statements} from "./components/Statements";
import {Spotify} from "./components/statistics/playlists/Spotify";
import {AppleMusic} from "./components/statistics/playlists/AppleMusic";
import {doTranslate, getLanguagePack} from "./components/Translate";
import ReactGA from 'react-ga4';
import LinkIt from "./components/linkIt/LinkIt";
import LinkItJobs from "./components/linkIt/LinkItJobs";
import {NovaInventory} from "./components/nova/Inventory";
import ErrorBox from "./components/ErrorBox";
import Changes from "./components/Changes";
import LoadingBar from "./components/template/LoadingBar";
import Contact from "./components/messages/Contact";
import {LicenseRequests, OpenLicenseRequests} from "./components/licenseRequests/LicenseRequests";
import Nova from "./components/nova/Nova";
import {NovaSales} from "./components/statistics/NovaSales";
import OrderRequest from "./components/nova/orderRequest/OrderRequest";
import ReturnRequest from "./components/nova/ReturnRequest";
import ArtistProfiles from "./components/artistProfiles/ArtistProfiles";
import OrderRequestSpecial from "./components/nova/orderRequest/OrderRequestSpecial";

const App = (props) => {
    const [open, setOpen] = useState(false);
    const [authModalopen, setauthModalopen] = useState(false);
    const [userId, setUserId] = useState({success: false, userId: 0});
    const [appConnected, setAppConnected] = useState(false);
    const [endOfPage, setEndOfPage] = useState(false);
    const [error, setError] = useState(null);
    const date = new Date(Date.now()).toISOString().substr(0, 10).split("-");

    const {width} = useWindowDimensions();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [darkMode, setDarkMode] = useState(Cookies.get("feiyrAppTheme") === "dark");

    const monthNames = [
        doTranslate("January"),
        doTranslate("February"),
        doTranslate("March"),
        doTranslate("April"),
        doTranslate("May"),
        doTranslate("June"),
        doTranslate("July"),
        doTranslate("August"),
        doTranslate("September"),
        doTranslate("October"),
        doTranslate("November"),
        doTranslate("December"),
    ];
    const displayDate = `${date[2]}. ${monthNames[date[1] - 1]} ${date[0]}`;

    const classes = makeStyles((theme) => ({
        root: {
            display: "flex",
        },
        appBarSpacer: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            overflow: "auto",
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            marginBottom: 32,
        },
    }));

    const handleDrawerChange = (stateFromComponent) => {
        setOpen(stateFromComponent);
    };

    const handleAuthModal = (stateFromComponent) => {
        setauthModalopen(stateFromComponent);
    };

    const onScroll = (event) => {
        const {target} = event;
        if (target.scrollHeight - target.scrollTop <= target.offsetHeight + 10) {
            setEndOfPage(true);
            if (props.location.pathname.match(/^\/inbox$/)) {
                target.scrollTop -= 11;
            }
        }
    };

    const handleAuth = () => {
        getLanguagePack()
            .then(() => {
                return new FeiyrApi().get("/user/fromtoken", {accessToken: JSON.parse(Cookies.get("token" + process.env.REACT_APP_COOKIE_VERSION)).access_token});
            })
            .then((res) => {
                if (res.success === true) {
                    localStorage.setItem("userId", res.userId);
                    setUserId(res);
                }
            })
            .catch(err => {
                err.userMessage = "Authorization failed";
                setError(err);
            });
    };

    useEffect(() => {
        if (localStorage.getItem("lang") == null) {
            localStorage.setItem("lang", navigator.language.slice(0, 2) || navigator.userLanguage.slice(0, 2));
        }
        if (localStorage.getItem("userId") !== null) {
            let userId = parseInt(localStorage.getItem("userId"));
            if (userId > 0) {
                setUserId({success: true, userId: userId});
            }
        }
        const queryString = props.location.search;
        const urlParams = new URLSearchParams(queryString);
        const authorizationCode = urlParams.get("code");

        if (authorizationCode !== null || Cookies.get("token" + process.env.REACT_APP_COOKIE_VERSION) !== undefined) {
            if (authorizationCode !== null) {
                let myHeaders = new Headers();
                myHeaders.append("Authorization", process.env.REACT_APP_TOKEN_AUTH);
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                let urlencoded = new URLSearchParams();
                urlencoded.append("grant_type", "authorization_code");
                urlencoded.append("code", authorizationCode);
                myHeaders.append("Content-Length", urlencoded.toString().length.toString());
                let requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow",
                    credentials: 'include'
                };
                fetch(`${process.env.REACT_APP_URL}/token`, requestOptions)
                    .then((response) => response.json())
                    .then((result) => new Promise((resolve, reject) => {
                        if (result !== undefined && result.access_token !== undefined) {
                            setAppConnected(true);
                            setauthModalopen(false);
                            resolve(result);
                        }
                        else {
                            reject(new Error("Error fetching token"));
                        }
                    }))
                    .then(() => handleAuth())
                    .catch(err => {
                        err.userMessage = "Authorization failed";
                        setError(err);
                    });
            }
            else {
                postRefreshToken(JSON.parse(Cookies.get("token" + process.env.REACT_APP_COOKIE_VERSION)).refresh_token)
                    .then(() => {
                        setAppConnected(true);
                        handleAuth();
                    })
                    .catch(err => {
                        err.userMessage = "Authorization failed";
                        setError(err);
                    });
            }
        }
    }, []);

    ReactGA.initialize('G-LBJBKWDB4P');
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: props.location
        });
    }, [props.location]);

    useEffect(() => {
        if (Cookies.get("feiyrAppTheme") !== undefined) {
            setDarkMode(Cookies.get("feiyrAppTheme") === "dark");
        }
        else if (prefersDarkMode) {
            setDarkMode(true);
        }
        else {
            setDarkMode(false);
        }
    }, [prefersDarkMode]);

    const Theme = useMemo(() => {
        return createTheme({
            palette: {
                mode: darkMode ? 'dark' : 'light',
                primary: {
                    main: "#f5a200",
                },
                secondary: {
                    main: darkMode ? "#fafafa" : "#1e1e1e",
                },
                unreleased: {
                    main: "#ffeb3b"
                },
                pending: {
                    main: "#ff7043"
                },
                published: {
                    main: "#4caf50"
                },
                expandableRow: {
                    main: darkMode ? "#616161" : "#ededed"
                }
            },
            overrides: {
                MuiToolbar: {
                    root: {
                        margin: 0,
                        padding: 0,
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "center"
                    },
                },
                MuiTablePagination: {
                    input: {
                        marginRight: 16,
                    },
                    actions: {
                        marginLeft: 0
                    }
                },
                MuiIconButton: {
                    root: {
                        paddingRight: 0,
                    },
                }
            },
        })
    }, [darkMode]);

    return (
        <React.Fragment>
            <ThemeProvider theme={Theme}>
                <CssBaseline/>
                <div className={classes.root}>
                    <TopNav
                        handleDrawerChange={handleDrawerChange}
                        navStatus={open}
                        appConnected={appConnected}
                        setAppConnected={setAppConnected}
                    />

                    {width > 1024 && (
                        <SideNav
                            handleDrawerChange={handleDrawerChange}
                            navStatus={open}
                            appConnected={appConnected}
                            setAppConnected={setAppConnected}
                        />
                    )}
                    <main className={classes.content} onScroll={onScroll}>
                        <div className={classes.appBarSpacer}/>
                        <Container
                            maxWidth="lg"
                            className={classes.container}
                        >
                            {error === null ? (
                                <React.Fragment>
                                    {(userId.success === true || userId.success === "true") && appConnected ? (
                                        <React.Fragment>
                                            <Notification/>
                                            <Route exact path="/">
                                                <Dashboard userId={userId} date={displayDate}/>
                                            </Route>
                                            <Route path="/settings">
                                                <Settings darkMode={darkMode} setDarkMode={setDarkMode}/>
                                            </Route>
                                            <Route exact path="/statistics">
                                                <Statistics/>
                                            </Route>
                                            <Route path="/statistics/ebooks">
                                                <Ebooks/>
                                            </Route>
                                            <Route path="/statistics/music">
                                                <Music/>
                                            </Route>
                                            <Route path="/statistics/playlists/spotify">
                                                <Spotify/>
                                            </Route>
                                            <Route path="/statistics/playlists/applemusic">
                                                <AppleMusic/>
                                            </Route>
                                            <Route path="/statistics/nova">
                                                <NovaSales/>
                                            </Route>
                                            <Route exact path="/bundles">
                                                <Bundles/>
                                            </Route>
                                            <Route path="/bundles/music">
                                                <MusicBundles type={"bundle"}/>
                                            </Route>
                                            <Route path="/bundles/radio-play">
                                                <MusicBundles type={"audio"}/>
                                            </Route>
                                            <Route path="/bundles/ucg">
                                                <MusicBundles type={"ugc"}/>
                                            </Route>
                                            <Route path="/bundles/ebook">
                                                <EbookBundles/>
                                            </Route>
                                            <Route path="/artistprofiles">
                                                <ArtistProfiles/>
                                            </Route>
                                            <Route path="/statements">
                                                <Statements/>
                                            </Route>
                                            <Route exact path="/inbox">
                                                <Inbox endOfPage={endOfPage} setEndOfPage={setEndOfPage}/>
                                            </Route>
                                            <Route exact path="/inbox/:id">
                                                <Message {...props}/>
                                            </Route>
                                            <Route exact path="/linkit">
                                                <LinkIt/>
                                            </Route>
                                            <Route exact path="/linkit/music">
                                                <LinkItJobs type={"music"}/>
                                            </Route>
                                            <Route exact path="/linkit/ebook">
                                                <LinkItJobs type={"ebook"}/>
                                            </Route>
                                            <Route exact path="/linkit/physical">
                                                <LinkItJobs type={"physical"}/>
                                            </Route>
                                            <Route exact path="/nova">
                                                <Nova/>
                                            </Route>
                                            <Route path="/nova/inventory">
                                                <NovaInventory/>
                                            </Route>
                                            <Route exact path="/nova/orderrequest">
                                                <OrderRequest/>
                                            </Route>
                                            <Route exact path="/nova/orderrequest/special">
                                                <OrderRequestSpecial/>
                                            </Route>
                                            <Route path="/nova/returnrequest">
                                                <ReturnRequest/>
                                            </Route>
                                            <Route path="/changes">
                                                <Changes/>
                                            </Route>
                                            <Route path="/contact">
                                                <Contact/>
                                            </Route>
                                            <Route exact path="/licenserequests">
                                                <LicenseRequests/>
                                            </Route>
                                            <Route path="/licenserequests/feiyr">
                                                <OpenLicenseRequests ugc={false}/>
                                            </Route>
                                            <Route path="/licenserequests/ugc">
                                                <OpenLicenseRequests ugc={true}/>
                                            </Route>
                                        </React.Fragment>
                                    ) : (
                                        <LoadingBar/>
                                    )}
                                </React.Fragment>
                            ) : (
                                <ErrorBox error={error}/>
                            )}
                        </Container>

                        <PwaInstallPrompt/>

                        {appConnected ? (
                            <SuccessModal {...props} />
                        ) : (
                            <AuthModal
                                handleAuthModal={handleAuthModal}
                                {...props}
                                authModalopen={authModalopen}
                                appConnected={appConnected}
                                setAppConnected={setAppConnected}
                            />
                        )}
                        {width <= 1024 && <BottomNav route={props.location.pathname}/>}
                    </main>
                </div>
            </ThemeProvider>
        </React.Fragment>
    );
};

export default withRouter(App);
