import React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function Changes() {
    const changeLog = {
        "v1.3.9": [
            "Added UGC Livetrends"
        ],
        "v1.3.8": [
            "Added a notice on the dashboard if the license agreement was amended and needs to be accepted",
            "Fixed an issue where some success messages have not been displayed correctly"
        ],
        "v1.3": [
            "Added the Artist Profile tool",
            "Bunch of minor bug fixes"
        ],
        "v1.2": [
            "Implemented Dark Mode",
            "Added Nova Md statistics",
            "Added Nova Md Delivery Approval",
            "Added Nova Md Return Requests",
            "Specific Nova Md icons",
            "Various improvements under the hood"
        ],
        "v1.1": [
            "Added this changelog",
            "Errors are no longer hidden to the user",
            "Updated Music and EBook Live Sales styles",
            "Increased support messages performance and fixed some minor issues",
            "Authorization should now always work perfectly and reloads should not result in errors anymore",
            "Table texts are now translated",
            "Added the ability to send a new support message",
            "Added license requests and actions to approve/deny them",
        ]
    };

    return (
        <Paper style={{padding: "16px"}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant={"h4"} component={"h1"}>Changelog</Typography>
                </Grid>
                {Object.keys(changeLog).map(heading => {
                    return (
                        <Grid item xs={12}>
                            <Typography variant={"h5"} component={"h2"}>{heading}</Typography>
                            <List>
                                {changeLog[heading].map(entry => {
                                    return (
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckCircleOutlineIcon/>
                                            </ListItemIcon>
                                            <ListItemText>
                                                {entry}
                                            </ListItemText>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Grid>
                    );
                })}
            </Grid>
        </Paper>
    );
};