import React from "react";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    tableFooter: {
        margin: 0,
        marginBottom: "60px",
        padding: 0,
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "center"
    }
}));

export default function CustomFooter(props) {
    const classes = useStyles();
    const handleRowChange = (event) => {
        changeRowsPerPage(event.target.value);
    };

    const handlePageChange = (page) => {
        changePage(page);
    };

    const {
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        textLabels,
        changePage,
    } = props;

    return (
        <TableFooter>
            <TableRow>
                <TableCell class={classes.tableFooter} colSpan={1000}>
                    <TablePagination
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelRowsPerPage={textLabels.rowsPerPage}
                        labelDisplayedRows={({from, to, count}) =>
                            `${from}-${to} ${textLabels.displayRows} ${count}`
                        }
                        backIconButtonProps={{
                            "aria-label": textLabels.previous,
                        }}
                        nextIconButtonProps={{
                            "aria-label": textLabels.next,
                        }}
                        rowsPerPageOptions={[10, 20, 100]}
                        onPageChange={(_, page) => handlePageChange(page)}
                        onRowsPerPageChange={(event) => handleRowChange(event)}
                    />
                </TableCell>
            </TableRow>
        </TableFooter>
    );
}
