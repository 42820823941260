import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormattedTextParser from "../../../js/FormattedTextParser";

export default function Entries({classes, systemNews}) {
    return Object.entries(systemNews).map((el, i) => {
        if (i < 3) {
            return (
                <Accordion key={i}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        {el[1].headline === "" ? (
                            <Typography className={classes.heading} component={"span"}>
                                "Important Information"
                            </Typography>
                        ) : (
                            <Typography className={classes.heading} component={"span"}>
                                {FormattedTextParser(el[1].headline)}
                            </Typography>
                        )}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography
                            component={"span"}
                            style={{width: "100%", wordWrap: "break-word"}}
                        >
                            {FormattedTextParser(el[1].content)}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            );
        }
        else {
            return null;
        }
    });
}
