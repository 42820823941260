import React, {useState} from "react";
import {doTranslate, Translate} from "../Translate";
import {FeiyrApi} from "../../js/FeiyrApi";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IsoDate from "../../js/IsoDate";
import DataTable from "../datatable/DataTable";
import ErrorBox from "../ErrorBox";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    expandableRow: {
        backgroundColor: theme.palette.expandableRow.main
    }
}));

export function NovaSales() {
    const classes=useStyles();
    const [error, setError] = useState(null);
    const [data, setData] = useState(false);
    const [articleNumbers, setArticleNumbers] = useState([]);
    const [entryCount, setEntryCount] = useState(0);
    const columns = [
        {
            name: "release_date",
            label: "release_date",
            options: {
                display: false,
                sort: true,
                filter: false,
                searchable: false,
            }
        },
        {
            name: "ean",
            label: "ean",
            options: {
                display: false,
                sort: true,
                filter: false,
                searchable: true,
            }
        },
        {
            name: "artist",
            label: doTranslate("Artist, Title"),
            options: {
                filter: false,
                sort: true,
                display: true,
                searchable: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div>
                            <p style={{fontSize: "0.7rem", margin: "0"}}>{tableMeta.rowData[2]}</p>
                            <p
                                style={{
                                    fontWeight: "700",
                                    paddingTop: "0rem",
                                    paddingBottom: "0.5rem",
                                    margin: "0",
                                }}
                            >
                                {tableMeta.rowData[3]}
                            </p>
                        </div>
                    );
                },
            }
        },
        {
            name: "title",
            label: "title",
            options: {
                display: false,
                sort: true,
                filter: false,
                searchable: true,
            }
        },
        {
            name: "article_no",
            label: doTranslate("Article Number"),
            options: {
                display: false,
                sort: true,
                filter: true,
                searchable: true,
                filterOptions: {
                    names: articleNumbers,
                }
            }
        },
        {
            name: "sales",
            label: doTranslate("Sales"),
            options: {
                display: true,
                sort: true,
                filter: false,
                searchable: false,
            }
        },
        {
            name: "promos",
            label: "promos",
            options: {
                display: false,
                sort: true,
                filter: false,
                searchable: false,
            }
        },
        {
            name: "comission",
            label: "comission",
            options: {
                display: false,
                sort: false,
                filter: false,
                searchable: false,
            }
        },
        {
            name: "credits",
            label: "credits",
            options: {
                display: false,
                sort: true,
                filter: false,
                searchable: false,
            }
        },
        {
            name: "deliveries",
            label: "deliveries",
            options: {
                display: false,
                sort: true,
                filter: false,
                searchable: false,
            }
        },
        {
            name: "sreturns",
            label: "sreturns",
            options: {
                display: false,
                sort: true,
                filter: false,
                searchable: false,
            }
        },
        {
            name: "ordered",
            label: "ordered",
            options: {
                display: false,
                sort: true,
                filter: false,
                searchable: false,
            }
        },
        {
            name: "backorder",
            label: "backorder",
            options: {
                display: false,
                sort: true,
                filter: false,
                searchable: false,
            }
        },
        {
            name: "onstock",
            label: "onstock",
            options: {
                display: false,
                sort: false,
                filter: false,
                searchable: false,
            }
        },
        {
            name: "actions",
            label: "actions",
            options: {
                display: false,
                sort: false,
                filter: false,
                searchable: false,
            }
        }
    ];

    function fetchData(dataTable) {
        console.log(dataTable);
        new FeiyrApi().get("/Nova/Articles/" + localStorage.getItem("userId"), {}, dataTable)
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    let values = res.data.values.map((el) => {
                        if (el.reserviert === undefined || el.reserviert === null) {
                            el.reserviert = 0;
                        }
                        return {
                            release_date: IsoDate(el.release_date),
                            ean: el.EAN,
                            artist: el.artist,
                            title: el.title,
                            article_no: el.articleNo,
                            sales: el.verkauft,
                            promos: el.promotion,
                            comission: el.kommisionslager,
                            credits: el.retoure,
                            deliveries: el.bezogen,
                            sreturns: el.labelrueckfuehrung,
                            ordered: el.bestellt,
                            backorder: el.rueckstand,
                            onstock: el.frei - el.reserviert
                        };
                    });
                    setData(values);
                    setArticleNumbers(res.data.articleNumbers);
                    setEntryCount(res.data.display.iTotalRecords);
                }
                else {
                    setError({userMessage: "Error fetching data"});
                }
            })
            .catch(err => {
                err.userMessage = "Error fetching data";
                setError(err);
            });
    }

    const renderExpandableRow = (rowData) => {
        return (
            <tr>
                <td colSpan={6}>
                    <TableContainer component={Paper}>
                        <Table
                            aria-label=""
                            style={{width: "100%"}}
                            className={classes.expandableRow}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell><Translate text="Details"/></TableCell>
                                    <TableCell align="right"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Translate text="EAN"/>:
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {rowData[1]}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Translate text="Article Number"/>:
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {rowData[4]}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Translate text="Release Date"/>:
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {rowData[0].toLocaleDateString()}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Translate text="Sales"/>:
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {rowData[5]}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Translate text="Promos"/>:
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {rowData[6]}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Translate text="Comission"/>:
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {rowData[7]}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Translate text="Returns"/>:
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {rowData[8]}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Translate text="Deliveries"/>:
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {rowData[9]}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Translate text="Label Returns"/>:
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {rowData[10]}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Translate text="Ordered"/>:
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {rowData[11]}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Translate text="Backorder"/>:
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {rowData[12]}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Translate text="Stock"/>:
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {rowData[13]}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </td>
            </tr>
        );
    }

    return (
        <React.Fragment>
            {error === null ? (
                <DataTable
                    title={doTranslate("Nova Sales")}
                    data={data}
                    entryCount={entryCount}
                    columns={columns}
                    fetchData={fetchData}
                    tableOptions={{
                        "renderExpandableRow": renderExpandableRow
                    }}
                />
            ) : (
                <ErrorBox error={error}/>
            )}
        </React.Fragment>
    );
}