import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {doTranslate, Translate} from "../Translate";
import React, {useState} from "react";

export default function ReturnRequestArticle(props) {
    const [returnQuantity, setReturnQuantity] = useState(props.article.quantity);
    const [disposalQuantity, setDisposalQuantity] = useState(0);

    const onDisposalQuantityChange = (event) => {
        let value = parseInt(event.target.value);
        if (value < 0) {
            value = 0;
        }
        else if (value > props.article.quantity) {
            value = props.article.quantity;
        }
        setDisposalQuantity(value);
        setReturnQuantity(props.article.quantity - value);
        props.onDisposalQuantityChange(props.article.ARTICLEID, value);
    };

    return (
        <React.Fragment>
            <ListItem key={props.jobId + "-" + props.article.ARTICLEID}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <p style={{fontSize: "0.7rem", margin: "0"}}>{props.article.ARTIST}</p>
                        <p style={{
                            fontWeight: "700",
                            paddingTop: "0rem",
                            paddingBottom: "0.5rem",
                            margin: "0",
                        }}>{props.article.TITLE1}</p>
                        <p style={{fontSize: "0.7rem", margin: "0"}}>{props.article.ARTICLENO}</p>
                        <p style={{fontSize: "0.7rem", margin: "0"}}>{props.article.EAN}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <Translate text="Total Quantity:"/> {props.article.quantity}<br/>
                        <Translate text="Return Quantity:"/> {returnQuantity}
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            style={{width: "100%"}}
                            required
                            id={"disposal-quantity-" + props.jobId + "-" + props.article.ARTICLEID}
                            label={doTranslate("Disposal Quantity")}
                            onChange={onDisposalQuantityChange}
                            value={disposalQuantity}
                            variant="outlined"
                            type="number"
                        />
                    </Grid>
                </Grid>
            </ListItem>
            <ListItem divider/>
        </React.Fragment>
    );
}