import React, {useCallback, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {FeiyrApi} from "../../js/FeiyrApi";
import Paper from "@mui/material/Paper";
import {Translate} from "../Translate";
import LoadingBar from "../template/LoadingBar";
import Alert from "@mui/material/Alert";
import parse from 'html-react-parser';
import ErrorBox from "../ErrorBox";
import AdminInCharge from "./AdminInCharge";
import ContactForm from "./ContactForm";
import Employees from "./Employees";
import Typography from "@mui/material/Typography";

export default function Contact() {
    const [error, setError] = useState(null);
    const [allowSending, setAllowSending] = useState(false);
    const [categories, setCategories] = useState(null);
    const [subCategories, setSubCategories] = useState(null);
    const [content, setContent] = useState(null);
    const [categoryId, setCategoryId] = useState(0);
    const [subCategoryId, setSubCategoryId] = useState(0);
    const [selectedContent, setSelectedContent] = useState(0);
    const [selectedContentType, setSelectedContentType] = useState(null);
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        new FeiyrApi().get("/SupportMessages/Categories")
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    let categories = {};
                    Object.entries(res.data).forEach(entry => {
                        categories[entry[0]] = parse(entry[1]);
                    });
                    setCategories(categories);
                }
                else {
                    setError({userMessage: "Unable to fetch categories"});
                }
            })
            .catch(err => {
                err.userMessage = "Unable to fetch categories";
                setError(err);
            });
    }, []);

    useEffect(() => {
        setSubCategoryId(0);
        new FeiyrApi().get("/SupportMessages/SubCategories/" + categoryId)
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true") && Object.keys(res.data).length > 0) {
                    let subCategories = {};
                    Object.entries(res.data).forEach(entry => {
                        subCategories[entry[0]] = parse(entry[1]);
                    });
                    setSubCategories(subCategories);
                }
                else {
                    setSubCategories(null);
                }
            })
            .catch(err => {
                err.userMessage = "Unable to fetch sub categories";
                setError(err);
            });
    }, [categoryId]);

    useEffect(() => {
        let feiyrApi = new FeiyrApi();
        setSelectedContent(0);
        setSelectedContentType(null);
        if (categoryId === 1) {
            if (subCategoryId === 1) {
                feiyrApi.get("/Bundle/SimpleList/" + localStorage.getItem("userId"), {hasTracks: false})
                    .then(res => {
                        if (res !== undefined && (res.success === "true" || res.success === true)) {
                            setContent(res.data);
                            setSelectedContentType("bundleid");
                        }
                        else {
                            setError({userMessage: "Unable to fetch releases"});
                        }
                    })
                    .catch(err => {
                        err.userMessage = "Unable to fetch releases";
                        setError(err);
                    });
                setAllowSending(false);
            }
            else if (subCategoryId === 2) {
                feiyrApi.get("/Track/SimpleList/" + localStorage.getItem("userId"))
                    .then(res => {
                        if (res !== undefined && (res.success === "true" || res.success === true)) {
                            setContent(res.data);
                            setSelectedContentType("trackid");
                        }
                        else {
                            setError({userMessage: "Unable to fetch tracks"});
                        }
                    })
                    .catch(err => {
                        err.userMessage = "Unable to fetch tracks";
                        setError(err);
                    });
                setAllowSending(false);
            }
            else if (subCategoryId > 0) {
                setAllowSending(true);
            }
        }
        else if (categoryId === 3) {
            if (subCategoryId === 1) {
                feiyrApi.get("/EBook/SimpleList/" + localStorage.getItem("userId"))
                    .then(res => {
                        if (res !== undefined && (res.success === "true" || res.success === true)) {
                            setContent(res.data);
                            setSelectedContentType("bookid");
                        }
                        else {
                            setError({userMessage: "Unable to fetch ebooks"});
                        }
                    })
                    .catch(err => {
                        err.userMessage = "Unable to fetch ebooks";
                        setError(err);
                    });
                setAllowSending(false);
            }
            else if (subCategoryId > 0) {
                setAllowSending(true);
            }
        }
        else if (categoryId === 0) {
            setAllowSending(false);
        }
        else {
            setAllowSending(true);
        }
    }, [categoryId, subCategoryId]);

    const handleCategorySelection = (event) => {
        setCategoryId(parseInt(event.target.value));
        setSubCategoryId(0);
    };

    const handleSubCategorySelection = (event) => {
        setSubCategoryId(parseInt(event.target.value));
    };

    const handleSubjectChange = (event) => {
        setSubject(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleContentSelection = useCallback((event) => {
        setSelectedContent(parseInt(event.target.value));
        setAllowSending(parseInt(event.target.value) !== 0);
    }, []);

    const formSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setSuccess(null);
        let payload = {
            category_id: categoryId,
            sub_category_id: subCategoryId === 0 ? "" : subCategoryId,
            subject: subject,
            additional_id: selectedContent === 0 ? "" : selectedContent,
            additional_id_type: selectedContentType == null ? "" : selectedContentType,
            content: message
        };
        new FeiyrApi().post("/SupportMessages/Create/" + localStorage.getItem("userId"), payload)
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    setSubject("");
                    setMessage("");
                    setSubCategoryId(0);
                    setCategoryId(0);
                    setSuccess(true);
                }
                else {
                    setSuccess(false);
                }
            })
            .catch(error => {
                setSuccess(false);
                console.error(error);
            });
    };

    return (
        <>
            {error === null ? (
                <React.Fragment>
                    <Paper style={{width: "100%", padding: "16px", marginBottom: "16px"}}>
                        {categories !== null ? (
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h1">
                                        <Translate text="Create new support message"/>
                                    </Typography>
                                </Grid>
                                {success !== null ? (
                                    <Grid item xs={12}>
                                        {success === true ? (
                                            <React.Fragment>
                                                <Alert severity="success">
                                                    <Translate text={"MessageSentSuccessText"}/>
                                                </Alert>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <Alert severity="error"><Translate
                                                    text={"MessageSentErrorText"}/></Alert>
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                ) : ""}
                                <Grid item xs={12}>
                                    <ContactForm
                                        formSubmit={formSubmit}
                                        categoryId={categoryId}
                                        handleCategorySelection={handleCategorySelection}
                                        categories={categories}
                                        subCategoryId={subCategoryId}
                                        handleSubCategorySelection={handleSubCategorySelection}
                                        subCategories={subCategories}
                                        content={content}
                                        selectedContent={selectedContent}
                                        handleContentSelection={handleContentSelection}
                                        allowSending={allowSending}
                                        handleSubjectChange={handleSubjectChange}
                                        subject={subject}
                                        handleMessageChange={handleMessageChange}
                                        message={message}
                                    />
                                </Grid>
                            </Grid>
                        ) : <LoadingBar/>}
                    </Paper>
                    <Employees/>
                    <AdminInCharge/>
                </React.Fragment>
            ) : (
                <ErrorBox error={error}/>
            )}
        </>
    );
}