import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {doTranslate, Translate} from "../../Translate";
import Button from "@mui/material/Button";
import Send from "@mui/icons-material/Send";
import Alert from "@mui/material/Alert";
import LoadingBar from "../../template/LoadingBar";
import {FeiyrApi} from "../../../js/FeiyrApi";
import ErrorBox from "../../ErrorBox";

export default function OrderRequestForm(props) {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [note, setNote] = useState("");

    const onNoteChange = (event) => {
        setNote(event.target.value);
    };

    const onFormSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setSuccess(false);
        setLoading(true);
        let payload = {
            articleid: props.articleId,
            note: note
        };
        new FeiyrApi().post("/OrderRequests/Insert/" + localStorage.getItem("userId"), payload)
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    setNote("");
                    setSuccess(true);
                }
                else {
                    setError({userMessage: "Error submitting order request"});
                }
                setLoading(false);
            })
            .catch(err => {
                err.userMessage = "Error submitting order request";
                setError(err);
                setLoading(false);
            });
    };

    return (
        <React.Fragment>
            {error === null ? (
                <React.Fragment>
                    {loading === false ? (
                        <React.Fragment>
                            {success === false ? (
                                <form onSubmit={onFormSubmit} style={{width: "100%"}}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                style={{width: "100%"}}
                                                id="order-request-note"
                                                label={doTranslate("Note")}
                                                multiline
                                                rows={5}
                                                maxRows={100}
                                                onChange={onNoteChange}
                                                value={note}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={6}/>
                                        <Grid item xs={6}>
                                            <Button variant="contained" color="primary" type="submit" startIcon={<Send/>} fullWidth={true}>
                                                <Translate text={"Request"}/>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            ) : (
                                <Alert severity="success">
                                    <Translate text="OrderRequestSuccessMessage"/>
                                </Alert>
                            )}
                        </React.Fragment>
                    ) : (
                        <LoadingBar/>
                    )}
                </React.Fragment>
            ) : (
                <ErrorBox error={error}/>
            )}
        </React.Fragment>
    )
}