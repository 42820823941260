import React from "react";
import LanguageSelector from "./LanguageSelector";
import Link from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Switch from "@mui/material/Switch";
import makeStyles from "@mui/styles/makeStyles";
import {Translate} from "../Translate";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import Brightness4 from "@mui/icons-material/Brightness4";
import Divider from "@mui/material/Divider";
import Copyright from "../dashboard/Copyright";
import Cookies from "js-cookie";

const useStyles = makeStyles(() => ({
    img: {
        width: "24px",
        height: "auto",
    },
}));

export function Settings(props) {
    const classes = useStyles();

    const toggleDarkMode = () => {
        props.setDarkMode(!props.darkMode);
        if (props.darkMode) {
            Cookies.set("feiyrAppTheme", "light", {expires: 365});
            props.setDarkMode(false);
        }
        else {
            Cookies.set("feiyrAppTheme", "dark", {expires: 365});
            props.setDarkMode(true);
        }
    };

    return (
        <React.Fragment>
            <List>
                <ListItem>
                    <ListItemIcon>
                        <Brightness4/>
                    </ListItemIcon>
                    <ListItemText id="toggle-dark-mode" primary={<Translate text="Dark Mode"/>}/>
                    <ListItemSecondaryAction>
                        <Switch
                            color="primary"
                            edge="end"
                            onChange={toggleDarkMode}
                            checked={props.darkMode}
                            inputProps={{'aria-labelledby': 'toggle-dark-mode'}}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <LanguageSelector/>
                <Divider/>
                <ListItem button component={Link} href="https://accounts.feiyr.com" target="_blank">
                    <ListItemIcon>
                        <img src={"/img/feiyrIcons/feiyr.png"} className={classes.img} alt={"icon"}/>
                    </ListItemIcon>
                    <ListItemText>
                        <Translate text="GoToFeiyrHomepage"/>
                    </ListItemText>
                </ListItem>
            </List>
            <Box pt={4}>
                <Copyright/>
            </Box>
        </React.Fragment>
    )
}
