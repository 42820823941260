import React, {useEffect, useState} from "react";
import Chart from "../charts/Chart";
import makeStyles from "@mui/styles/makeStyles";
import DefaultPieChart from "../charts/DefaultPieChart";
import TopEbooks from "./TopEbooks";
import Grid from "@mui/material/Grid";
import TinyBar from "../charts/TinyBar";
import {Translate} from "../Translate";
import {FeiyrApi} from "../../js/FeiyrApi";
import ErrorBox from "../ErrorBox";
import LoadingBar from "../template/LoadingBar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import BackButton from "../BackButton";

const useStyles = makeStyles(() => ({
    paper: {
        paddingTop: "1rem",
        display: "flex",
        overflow: "hidden",
        flexDirection: "column",
        padding: "16px"
    }
}));

export default function Ebooks() {
    const classes = useStyles();

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const [shops, setShops] = useState();
    const [topEbooks, setTopEbooks] = useState();
    const [trend, setTrend] = useState();
    const [countries, setCountries] = useState();

    useEffect(() => {
        setLoading(true);
        let params = {
            filterValues: {
                start_date: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
                end_date: new Date(Date.now()).toISOString().substr(0, 10)
            }
        };
        let tableParams = {
            filterValues: {
                start_date: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
                end_date: new Date(Date.now()).toISOString().substr(0, 10)
            },
            tableParams: {
                sSearch: "",
                iDisplayStart: 0,
                iDisplayLength: 3
            }
        };
        let feiyrApi = new FeiyrApi();
        Promise.all([
            feiyrApi.get("/EbookLiveSales/Shops/" + localStorage.getItem("userId"), params),
            feiyrApi.get("/EbookLiveSales/Countries/" + localStorage.getItem("userId"), params),
            feiyrApi.get("/EbookLiveSales/TopEbooks/" + localStorage.getItem("userId"), tableParams),
            feiyrApi.get("/EbookLiveSales/Trend/" + localStorage.getItem("userId"), params),
        ]).then(res => new Promise((resolve, reject) => {
            let success = true;
            res.forEach(response => {
                if (response === undefined || (response.success !== true && response.success !== "true")) {
                    success = false;
                }
            });
            if (success) {
                resolve(res);
            }
            else {
                reject(new Error("Error fetching data"));
            }
        })).then(res => {
            setShops(res[0].data);
            setCountries(res[1].data);
            setTopEbooks(res[2]);
            setTrend(res[3].data);
            setLoading(false);
        }).catch(err => {
            err.userMessage = "Error fetching data";
            setError(err);
            setLoading(false);
        });
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <BackButton/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={"h5"} component={"h1"}>
                    <Translate text={"Ebook Live Trends"}/>
                </Typography>
            </Grid>
            {loading ? (
                <Grid item xs={12}>
                    <LoadingBar/>
                </Grid>
            ) : ""}
            {error === null ? (
                <React.Fragment>
                    {/* Chart */}
                    <Grid item xs={12} md={8} lg={8}>
                        <Paper className={classes.paper}>
                            {typeof trend !== "undefined" ? (
                                <Chart
                                    liveTrend={trend}
                                    graphName={<Translate text="LiveSalesEbooks"/>}
                                />
                            ) : (
                                <Translate text="NoDataAvailable"/>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Paper className={classes.paper} style={{height: "100%"}}>
                            {typeof topEbooks !== "undefined" ? (
                                <TopEbooks fetchData={topEbooks}/>
                            ) : (
                                <Translate text="NoDataAvailable"/>
                            )}
                        </Paper>
                    </Grid>
                    {/* Countries */}
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                        <Paper className={classes.paper}>
                            {typeof countries !== "undefined" ? (
                                <TinyBar
                                    graphName={<Translate text="Countries"/>}
                                    fetchData={countries}
                                />
                            ) : (
                                <Translate text="NoDataAvailable"/>
                            )}
                        </Paper>
                    </Grid>
                    {/* Shops */}
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Paper className={classes.paper}>
                            {typeof shops !== "undefined" ? (
                                <DefaultPieChart
                                    fetchData={shops}
                                    graphName={<Translate text="Shops"/>}
                                />
                            ) : (
                                <Translate text="NoDataAvailable"/>
                            )}
                        </Paper>
                    </Grid>
                </React.Fragment>
            ) : (
                <Grid item xs={12}>
                    <ErrorBox error={error}/>
                </Grid>
            )}
        </Grid>
    );
}
