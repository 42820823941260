import React, {useEffect, useState} from "react";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Switch from "@mui/material/Switch";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import {doTranslate, Translate} from "../Translate";
import TextField from "@mui/material/TextField";
import {getSpotifyUri, isValidSpotifyUri, isValidURL} from "../../js/URL";

export default function NewOrExisting(props) {
    const [currentValidationError, setCurrentValidationError] = useState("");
    const [existingValidationError, setExistingValidationError] = useState("");
    const [change, setChange] = useState(false);
    const [newArtistProfile, setNewArtistProfile] = useState(false);
    const [currentProfileLink, setCurrentProfileLink] = useState("");
    const [existingProfileLink, setExistingProfileLink] = useState("");

    const onChangeContributor = () => {
        setChange(prev => !prev);
    };

    const onChangeNewProfile = () => {
        setNewArtistProfile(prev => !prev);
    };

    const onChangeCurrentProfile = (event) => {
        let value = event.target.value;
        if (props.partnerShopId === 158) {
            value = getSpotifyUri(value, "artist");
            if (!isValidSpotifyUri(value, "artist")) {
                setCurrentValidationError(doTranslate("Invalid Spotify URI"));
            }
            else {
                setCurrentValidationError("");
            }
        }
        else if (!isValidURL(value)) {
            setCurrentValidationError(doTranslate("Invalid URI"));
        }
        else {
            setCurrentValidationError("");
        }
        setCurrentProfileLink(value);
    };

    const onChangeExistingProfile = (event) => {
        let value = event.target.value;
        if (props.partnerShopId === 158) {
            value = getSpotifyUri(value, "artist");
            if (!isValidSpotifyUri(value, "artist")) {
                setExistingValidationError(doTranslate("Invalid Spotify URI"));
            }
            else {
                setExistingValidationError("");
            }
        }
        else if (!isValidURL(value)) {
            setExistingValidationError(doTranslate("Invalid URI"));
        }
        else {
            setExistingValidationError("");
        }
        setExistingProfileLink(value);
    };

    useEffect(() => {
        let data = {};
        data[props.contributorId] = {
            newProfile: newArtistProfile,
            existingProfile: existingProfileLink,
            currentProfile: currentProfileLink
        };
        props.setContributorData(prev => {
            return {...prev, ...data};
        });
    }, [newArtistProfile, existingProfileLink, currentProfileLink]);

    return (
        <React.Fragment>
            <ListItem key={props.contributorId}>
                <ListItemText
                    id={"contributor-profile-settings-" + props.contributorId}
                    primary={props.contributorName}
                />
                <ListItemSecondaryAction>
                    <Switch
                        color="primary"
                        edge="end"
                        onChange={onChangeContributor}
                        checked={change}
                        inputProps={{'aria-labelledby': "contributor-profile-settings-" + props.contributorId}}
                    />
                </ListItemSecondaryAction>
            </ListItem>
            {change && (
                <React.Fragment>
                    <ListItem key={props.contributorId + "-settings"}>
                        <ListItemText
                            id={"contributor-profile-settings-" + props.contributorId + "-new-profile"}
                            primary={<Translate text={"New Profile"}/>}
                        />
                        <ListItemSecondaryAction>
                            <Switch
                                color="primary"
                                edge="end"
                                onChange={onChangeNewProfile}
                                checked={newArtistProfile}
                                inputProps={{'aria-labelledby': "contributor-profile-settings-" + props.contributorId + "-new-profile"}}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem key={props.contributorId + "-current-profile"}>
                        <TextField
                            style={{width: "100%"}}
                            required
                            id={"contributor-current-profile-" + props.contributorId}
                            label={doTranslate("Current Profile Link")}
                            onChange={onChangeCurrentProfile}
                            value={currentProfileLink}
                            variant="outlined"
                            error={currentValidationError !== ""}
                            helperText={currentValidationError}
                        />
                    </ListItem>
                    {newArtistProfile === false && (
                        <ListItem key={props.contributorId + "-existing-profile"}>
                            <TextField
                                style={{width: "100%"}}
                                required
                                id={"contributor-existing-profile-" + props.contributorId}
                                label={doTranslate("Existing Profile Link")}
                                onChange={onChangeExistingProfile}
                                value={existingProfileLink}
                                variant="outlined"
                                error={existingValidationError !== ""}
                                helperText={existingValidationError}
                            />
                        </ListItem>
                    )}
                </React.Fragment>
            )}
            <Divider/>
        </React.Fragment>
    );
}