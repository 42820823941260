import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {Link} from "react-router-dom";
import {doTranslate, Translate} from "../Translate";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        textAlign: "center",
        textDecoration: "none",
        "&:hover, &:focus": {
            boxShadow: "0px 2px 4px 1px rgb(0 0 0 / 20%)"
        },
        height: "100%"
    },
    flexContainer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    },
    mb10: {
        marginBottom: "10px"
    }
}));

export default function LinkIt() {
    const classes = useStyles();
    return (
        <Grid container spacing={3}>
            <Grid item xs={6} md={3} lg={3}>
                <Paper
                    className={classes.paper}
                    name={"music"}
                    component={Link}
                    to="/linkit/music"
                >
                    <div className={classes.flexContainer}>
                        <img src={"/img/feiyrIcons/music.png"} alt={doTranslate("Music")}
                             className={classes.mb10}/>
                        <Typography variant="body1" component={"span"}>
                            <Translate text="Music"/>
                        </Typography>
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
                <Paper
                    className={classes.paper}
                    name={"ebook"}
                    component={Link}
                    to="/linkit/ebook"
                >
                    <div className={classes.flexContainer}>
                        <img src={"/img/feiyrIcons/ebook.png"} alt={doTranslate("EBook")}
                             className={classes.mb10}/>
                        <Typography variant="body1" component={"span"}>
                            <Translate text="EBook"/>
                        </Typography>
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
                <Paper
                    className={classes.paper}
                    name={"physical"}
                    component={Link}
                    to="/linkit/physical"
                >
                    <div className={classes.flexContainer}>
                        <img src={"/img/feiyrIcons/physical.png"} alt={doTranslate("Physical")}
                             className={classes.mb10}/>
                        <Typography variant="body1" component={"span"}>
                            <Translate text="Physical"/>
                        </Typography>
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
}