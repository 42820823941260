import React, {useEffect, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import {FeiyrApi} from "../../js/FeiyrApi";
import useWindowDimensions from "../../js/windowDimensions";
import {Translate} from "../Translate";
import LoadingBar from "../template/LoadingBar";
import SingleThread from "./SingleThread";
import Grid from "@mui/material/Grid";
import {Link} from "react-router-dom";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IsoDate from "../../js/IsoDate";
import ErrorBox from "../ErrorBox";
import Add from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        marginBottom: "16px",
    },
    inline: {
        display: "inline",
    },
    paper: {
        padding: "16px 16px",
        width: "100%",
        margin: "6px 0px",
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
    zeroFlex: {
        flex: 0,
    },
    centerText: {
        flex: "0",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "1em",
    },
    content: {
        overflowY: "scroll",
    },
    marginRight: {
        marginRight: "6px",
    },
    minWidth: {
        minWidth: "70px",
        fontSize: "12px",
        textAlign: "left",
    },
    newMessageLink: {
        float: "right",
        color: theme.palette.primary.main
    }
}));

export const Inbox = (props) => {
    const classes = useStyles();
    const [error, setError] = useState(null);
    const [combinedMessages, setCombinedMessages] = useState([]);
    const [page, setPage] = useState(1);
    const {width} = useWindowDimensions();
    const [maxPage, setMaxPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const getMessages = () => {
        setLoading(true);
        let params = {
            language: "de",
            page: page,
            sortCol: "date",
            sortDir: "desc"
        };
        new FeiyrApi().get(`/SupportMessages/Threads/${localStorage.getItem("userId")}`, params)
            .then(res => new Promise((resolve, reject) => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    resolve(res);
                }
                else {
                    reject(new Error("Error fetching messages"));
                }
            }))
            .then(res => Promise.all(
                Object.entries(res.data).map(async (el) => {
                    if (el[0] === "max_page") {
                        setMaxPage(el[1]);
                        return {maxPage: el[1]};
                    }
                    else if (el[0] === "current_page") {
                        setPage(el[1] + 1);
                        return {currentPage: Number(el[1])};
                    }
                    else if (
                        el[0] === "first_visible_page" ||
                        el[0] === "last_visible_page"
                    ) {
                        return {[`${el[0]}`]: Number(el[1])};
                    }
                    else {
                        let previewText;
                        if (el[1].preview_text === undefined || el[1].preview_text === null || el[1].preview_text.toString().trim() === "") {
                            previewText = "";
                        }
                        else {
                            previewText = el[1].preview_text;
                        }
                        return {
                            subject: el[1].subject,
                            content: previewText,
                            date: el[1].date,
                            from: el[1].support_or_system,
                            id: el[0],
                        };
                    }
                })
            ))
            .then(res => {
                setCombinedMessages((prev) => {
                    return res !== null && prev !== null && prev !== undefined
                        ? [...prev, ...res.slice(0, res.length - 4)]
                        : res.slice(0, res.length - 4);
                });
                setLoading(false);
                props.setEndOfPage(false);
            })
            .catch((err) => {
                setLoading(false);
                err.userMessage = "Error fetching messages";
                setError(err);
            });
    };

    useEffect(() => {
        if (!loading) {
            setCombinedMessages([]);
            getMessages(page);
        }
    }, []);

    useEffect(() => {
        if (page <= maxPage && props.endOfPage && !loading) {
            setPage((prev) => {
                return prev++;
            });
            getMessages(page);
        }
    }, [props.endOfPage, maxPage]);

    return (
        <React.Fragment>
            <Paper style={{padding: "16px"}}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant={"h5"} component={"h1"}>
                            <Translate text="Inbox"/>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Link to="/contact" className={classes.newMessageLink}>
                            <Add style={{marginTop: ".3rem"}}/>
                        </Link>
                    </Grid>
                </Grid>
                {error === null ? (
                    <List className={classes.root}>
                        {typeof combinedMessages !== "undefined" && combinedMessages != null ? (
                            combinedMessages
                                // Sorts threads date DESC
                                .sort((a, b) => {
                                    return IsoDate(b.date) - IsoDate(a.date);
                                })
                                .map((combinedMessage, i) => {
                                    if (combinedMessage.length !== 0) {
                                        return (
                                            <SingleThread
                                                threads={combinedMessage}
                                                width={width}
                                                key={i}
                                            />
                                        );
                                    }
                                    else {
                                        return false;
                                    }
                                })
                        ) : ""}
                        {loading && <LoadingBar/>}
                    </List>
                ) : (
                    <ErrorBox error={error}/>
                )}
            </Paper>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
            >
                <Grid item lg={12} xs={12}/>
            </Grid>
        </React.Fragment>
    );
};
