import React from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MainListItems from "./MainListItems";
import MenuIcon from "@mui/icons-material/Menu";

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
    menuIcon: {
        color: theme.palette.text.primary
    }
}));

export default function MobileDrawer(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        setState({...state, [anchor]: open});
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom" || anchor === "left" || anchor === "right",
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <MainListItems appConnected={props.appConnected} setAppConnected={props.setAppConnected}/>
        </div>
    );

    return (
        <div>
            <React.Fragment key={"top"}>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={toggleDrawer("right", true)}
                >
                    <MenuIcon className={classes.menuIcon}/>
                </IconButton>
                <Drawer
                    anchor={"right"}
                    open={state["right"]}
                    onClose={toggleDrawer("right", false)}
                >
                    {list("right")}
                </Drawer>
            </React.Fragment>
        </div>
    );
}
