import React from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MainListItems from "./MainListItems";

import List from "@mui/material/List";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    menuButton: {
        marginRight: 36,
    },

    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        minHeight: "100vh"
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },

    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function SideNav({navStatus, handleDrawerChange, appConnected, setAppConnected}) {
    const classes = useStyles();

    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: clsx(classes.drawerPaper, !navStatus && classes.drawerPaperClose),
            }}
            open={navStatus}
        >
            <div className={classes.toolbarIcon}>
                <IconButton onClick={() => handleDrawerChange(false)}>
                    <ChevronLeftIcon/>
                </IconButton>
            </div>
            <Divider/>
            <List onClick={() => handleDrawerChange(false)}>
                <MainListItems appConnected={appConnected} setAppConnected={setAppConnected}/>
            </List>
        </Drawer>
    );
}
