import React, {useState} from "react";
import {doTranslate} from "../Translate";
import {FeiyrApi} from "../../js/FeiyrApi";
import IsoDate from "../../js/IsoDate";
import Paper from "@mui/material/Paper";
import DataTable from "../datatable/DataTable";
import ErrorBox from "../ErrorBox";
import ReturnRequestForm from "./ReturnRequestForm";
import Chip from "@mui/material/Chip";
import Check from "@mui/icons-material/Check";
import Clear from "@mui/icons-material/Clear";
import Create from "@mui/icons-material/Create";
import Grid from "@mui/material/Grid";
import BackButton from "../BackButton";
import IconButton from "@mui/material/IconButton";
import {default as ExternalLink} from "@mui/material/Link/Link";
import InfoIcon from "@mui/icons-material/Info";

export default function ReturnRequest() {
    const [error, setError] = useState(null);
    const [data, setData] = useState(false);
    const [entryCount, setEntryCount] = useState(0);

    const columns = [
        {
            name: "job_id",
            label: doTranslate("ID"),
            options: {
                display: true,
                sort: true,
                filter: false,
                searchable: false,
            }
        },
        {
            name: "create_date",
            label: doTranslate("Date"),
            options: {
                display: true,
                sort: true,
                filter: false,
                searchable: false,
                customBodyRender: value => IsoDate(value).toLocaleDateString()
            }
        },
        {
            name: "articleList",
            label: "articleList",
            options: {
                display: false,
                sort: true,
                filter: false,
                searchable: false,
            }
        },
        {
            name: "status",
            label: "status",
            options: {
                display: false,
                sort: true,
                filter: false,
                searchable: false,
            }
        },
        {
            name: "display_status",
            label: doTranslate("Status"),
            options: {
                display: true,
                sort: false,
                filter: false,
                searchable: false,
                customBodyRender: value => {
                    if (value === 0) {
                        return <Chip
                            icon={<Create />}
                            label={doTranslate("Please review")}
                            color="primary"
                            variant="outlined"
                            clickable
                        />;
                    } else if (value === 1) {
                        return <Chip
                            icon={<Check />}
                            label={doTranslate("Accepted")}
                            variant="outlined"
                        />;
                    } else if (value === 2) {
                        return <Chip
                            icon={<Check />}
                            label={doTranslate("Finished")}
                            variant="outlined"
                        />;
                    } else {
                        return <Chip
                            icon={<Clear />}
                            label={doTranslate("Denied")}
                            variant="outlined"
                        />;
                    }
                }
            }
        },
    ];

    function fetchData(dataTable) {
        new FeiyrApi().get("/NovaReturnRequest/" + localStorage.getItem("userId"), {}, dataTable)
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    res.data.values = res.data.values.map(value => {
                        value.status = parseInt(value.status);
                        value.display_status = value.status;
                        return value;
                    });
                    setData(res.data.values);
                    setEntryCount(res.data.display.iTotalRecords);
                }
                else {
                    setError({userMessage: "Error fetching data"});
                }
            })
            .catch(err => {
                err.userMessage = "Error fetching data";
                setError(err);
            });
    }

    /*
    0: job_id
    1: create_date
    2: articleList
    3: status
     */

    const renderExpandableRow = (rowData) => {
        if (rowData[3] !== 0) {
            return null;
        }
        return (
            <tr>
                <td colSpan={4}>
                    <Paper style={{padding: "16px"}}>
                        <ReturnRequestForm jobId={rowData[0]}/>
                    </Paper>
                </td>
            </tr>
        );
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={10}>
                <BackButton/>
            </Grid>
            <Grid item xs={2} style={{textAlign: "right"}}>
                <IconButton
                    component={ExternalLink}
                    href={"https://accounts.feiyr.com/bookdistribution/tools/returnRequest/description.html"}
                    target={"_blank"} color={"primary"}
                    style={{padding: "6px"}}
                >
                    <InfoIcon/>
                </IconButton>
            </Grid>
            {error === null ? (
                <Grid item xs={12}>
                    <DataTable
                        title={doTranslate("Return Request")}
                        data={data}
                        entryCount={entryCount}
                        columns={columns}
                        fetchData={fetchData}
                        tableOptions={{
                            "renderExpandableRow": renderExpandableRow
                        }}
                        showBackButton={false}
                    />
                </Grid>
            ) : (
                <ErrorBox error={error}/>
            )}
        </Grid>
    );
}