export function getSpotifyUri(value, checkFor) {
    if (!isValidSpotifyUri(value, checkFor)) {
        let regex = new RegExp("(http(s)?:\\/\\/)?(open.)?spotify.com\\/" + checkFor + "\\/[a-z\\d]+", "gi");
        if (value.match(regex)) {
            let uri = new URL(value);
            return "spotify:" + checkFor + ":" + uri.pathname.split("/").pop();
        }
    }
    return value;
}

export function isValidSpotifyUri(value, checkFor) {
    let prefix = "spotify:" + checkFor + ":";
    return value.substr(0, prefix.length) === prefix;
}

export function isValidURL(value) {
    let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,})' + // domain name
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(value);
}