import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../template/Title";
import {Translate} from "../Translate";

export default function MusicTopTable({fetchData, type, title}) {
    let data = [];
    if (typeof fetchData.error !== 'object') {
        if (type === "tracks") {
            data =
                typeof fetchData.error !== 'object'
                    ? Object.entries(fetchData.data.values).map((el, i) => {
                        return {
                            id: el[1].product_id,
                            artist: el[1].artist,
                            title: el[1].title,
                            amount: el[1].qnt
                        };
                    })
                    : false;
        }
        if (type === "artist") {
            data =
                typeof fetchData.error !== 'object'
                    ? Object.entries(fetchData.data.values).map((el, i) => {
                        return {
                            id: i,
                            artist: el[1].artist,
                            title: "",
                            amount: el[1].qnt
                        };
                    })
                    : false;
        }
    }

    return (
        <React.Fragment>
            <Title>{title}</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell><Translate text={"Artist"}/></TableCell>
                        {type === "tracks" && <TableCell><Translate text={"Title"}/></TableCell>}
                        <TableCell align="right"><Translate text={"Amount"}/></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data !== false ? (
                        data.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.artist}</TableCell>
                                {type === "tracks" && <TableCell>{row.title}</TableCell>}
                                <TableCell align="right">{row.amount}</TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <Translate text="Loading"/>
                    )}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}
