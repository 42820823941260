import React from "react";

export const createData = (
    artist,
    playlistName,
    playlist_id,
    plays,
    title,
    track_id,
    followers
) => {
    return {
        artist: artist,
        playlistInfo: `${artist},${playlistName},${title}`,
        playlist_name: playlistName,
        playlist_id: playlist_id,
        plays: plays,
        title: title,
        track_id: track_id,
        followers: followers,
    };
};

export const playlistBodyRender = (value, tableMeta) => {
    const artist = tableMeta.rowData[0];
    const title = tableMeta.rowData[1];
    const playlistName = tableMeta.rowData[2];
    return (
        <div>
            <p style={{fontSize: "0.7rem", margin: "0"}}>{artist}</p>
            <p style={{fontSize: "0.7rem", margin: "0"}}>{title}</p>
            <p
                style={{
                    fontWeight: "700",
                    paddingTop: "0rem",
                    paddingBottom: "0.5rem",
                    margin: "0",
                }}
            >
                {playlistName}
            </p>
        </div>
    );
};
