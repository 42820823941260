import React, {useCallback, useState} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {doTranslate, Translate} from "./Translate";
import FormattedTextParser from "../js/FormattedTextParser";
import ExpandableRowTitle from "./ExpandableRowTitle";
import IsoDate from "../js/IsoDate";
import DataTable from "./datatable/DataTable";
import {FeiyrApi} from "../js/FeiyrApi";
import ErrorBox from "./ErrorBox";
import Link from "@mui/material/Link";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    expandableRow: {
        backgroundColor: theme.palette.grey[700]
    },
    credit: {
        color: theme.palette.success,
    },
    payment: {
        color: theme.palette.error,
    }
}));

export const Statements = () => {
    const classes = useStyles();
    const [error, setError] = useState(null);
    const [data, setData] = useState(false);
    const [entryCount, setEntryCount] = useState(0);
    const [years, setYears] = useState([]);

    const columns = [
        {
            name: "create_date",
            label: doTranslate("Date"),
            options: {
                filter: true,
                sort: true,
                searchable: false,
                customBodyRender: (value) => {
                    return IsoDate(value).toLocaleDateString();
                },
                filterOptions: {
                    names: years
                }
            },
        },
        {
            name: "booking_text",
            label: doTranslate("Booking Text"),
            options: {
                filter: false,
                display: true,
                searchable: true,
                sort: false,
            },
        },
        {
            name: "corresponding_id",
            label: "Corresponding ID",
            options: {
                filter: false,
                sort: false,
                searchable: true,
                display: false,
            },
        },
        {
            name: "value",
            label: doTranslate("Value"),
            options: {
                filter: true,
                sort: true,
                display: true,
                searchable: false,
                filterOptions: {
                    names: [doTranslate("Revenues"), doTranslate("Cost")],
                },
                customBodyRender: (value) => {
                    if (Number(value) < 0) {
                        return <span style={{color: "red"}}>{value} €</span>;
                    }
                    else {
                        return <span style={{color: "green"}}>{value} €</span>;
                    }
                },
            },
        },
        {
            name: "causer_ip",
            label: "Causer IP",
            options: {
                filter: false,
                display: false,
                searchable: true,
            }
        },
        {
            name: "product_text",
            label: "Product Text",
            options: {
                filter: false,
                display: false,
                searchable: false,
            },
        },
        {
            name: "accounting_text",
            label: "Accounting Text",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                display: false,
            },
        },
        {
            name: "corresponding_id_2",
            label: "Corresponding ID 2",
            options: {
                filter: false,
                sort: false,
                searchable: true,
                display: false,
            },
        },
    ];

    const fetchS = useCallback((dataTable) => {
        new FeiyrApi().get("/Statements/" + localStorage.getItem("userId"), {}, dataTable)
            .then((res) => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    setYears(res.data.years);
                    setData(res.data.values);
                    setEntryCount(res.data.display.iTotalRecords);
                }
                else {
                    setError({userMessage: "Error fetching data"});
                }
            })
            .catch(err => {
                err.userMessage = "Error fetching data";
                setError(err);
            });
    }, []);

    const weirdFilter = useCallback((i, value) => {
        if (columns[i]["name"] === "value") {
            if (value === doTranslate("Revenues")) {
                value = 1;
            }
            else if (value === doTranslate("Cost")) {
                value = -1;
            }
        }
        return value;
    }, []);

    const renderExpandableRow = useCallback((rowData) => {
        return (
            <React.Fragment>
                <tr>
                    <td colSpan={6}>
                        <TableContainer component={Paper}>
                            <Table
                                aria-label="simple table"
                                style={{width: "100%"}}
                                className={classes.expandableRow}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Translate text="Details"/></TableCell>
                                        <TableCell align="right"/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <ExpandableRowTitle title={doTranslate('Product')}/>
                                        <TableCell component="th" scope="row" align="right">
                                            {rowData[6] === "Invoice" || rowData[6] === "Credit" ? (
                                                <Link
                                                    href={`https://accounts.feiyr.com/invoice/print/${rowData[4]}.pdf`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <Translate text="Download"/> {rowData[4]}
                                                </Link>
                                            ) : (
                                                FormattedTextParser(rowData[7])
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </td>
                </tr>
            </React.Fragment>
        );
    }, []);

    return (
        <React.Fragment>
            {error === null ? (
                <DataTable
                    title={doTranslate("Statements")}
                    data={data}
                    entryCount={entryCount}
                    columns={columns}
                    fetchData={fetchS}
                    weirdFilter={weirdFilter}
                    tableOptions={{
                        "renderExpandableRow": renderExpandableRow
                    }}
                    showBackButton={false}
                />
            ) : (
                <ErrorBox error={error}/>
            )}
        </React.Fragment>
    );
};
