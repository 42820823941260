import Grid from "@mui/material/Grid";
import {doTranslate, Translate} from "../Translate";
import React, {useState} from "react";
import Send from "@mui/icons-material/Send";
import SupportFaq from "./SupportFaq";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

export default function ContactForm(props) {
    const [faqRead, setFaqRead] = useState(null);

    const handleFaqRead = function(value) {
        setFaqRead(value);
    };

    return (
        <form onSubmit={(event) => props.formSubmit(event)} style={{width: "100%"}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl style={{width: "100%"}} variant="outlined">
                        <InputLabel id="category-select-label">
                            <Translate text={"Category"}/>
                        </InputLabel>
                        <Select
                            labelId="category-select-label"
                            label={<Translate text={"Category"}/>}
                            id="category-select"
                            value={props.categoryId}
                            onChange={(event) => props.handleCategorySelection(event)}
                        >
                            <MenuItem key={0} value={0}>
                                <Translate text={"Please select"}/>
                            </MenuItem>
                            {Object.entries(props.categories).map(category => {
                                return (
                                    <MenuItem key={category[0]} value={category[0]}>
                                        {category[1]}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                {props.subCategories !== null ? (
                    <Grid item xs={12}>
                        <FormControl style={{width: "100%"}} variant="outlined">
                            <InputLabel id="sub-category-select-label">
                                <Translate text={"Subcategory"}/>
                            </InputLabel>
                            <Select
                                labelId="sub-category-select-label"
                                label={<Translate text={"Subcategory"}/>}
                                id="sub-category-select"
                                value={props.subCategoryId}
                                onChange={(event) => props.handleSubCategorySelection(event)}
                            >
                                <MenuItem key={0} value={0}>
                                    <Translate text={"Please select"}/>
                                </MenuItem>
                                {Object.entries(props.subCategories).map(subCategory => {
                                    return (
                                        <MenuItem key={subCategory[0]} value={subCategory[0]}>
                                            {subCategory[1]}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                ) : ""}
                {props.content !== null ? (
                    <Grid item xs={12}>
                        <FormControl style={{width: "100%"}} variant="outlined">
                            <InputLabel id="content-select-label">
                                <Translate text={"Content"}/>
                            </InputLabel>
                            <Select
                                labelId="content-select-label"
                                label={<Translate text={"Content"}/>}
                                id="content-select"
                                value={props.selectedContent}
                                onChange={(event) => props.handleContentSelection(event)}
                            >
                                <MenuItem key={0} value={0}>
                                    <Translate text={"Please select"}/>
                                </MenuItem>
                                {Object.entries(props.content).map(content => {
                                    return (
                                        <MenuItem key={content[0]} value={content[0]}>
                                            {content[1]}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                ) : ""}
                {props.allowSending !== false ? (
                    <React.Fragment>
                        <SupportFaq categoryId={props.categoryId} subCategoryId={props.subCategoryId} setFaqRead={handleFaqRead}/>
                        {faqRead !== false && (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <TextField
                                        style={{width: "100%"}}
                                        required
                                        id="subject"
                                        label={doTranslate("Subject")}
                                        onChange={(event) => props.handleSubjectChange(event)}
                                        value={props.subject}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        style={{width: "100%"}}
                                        required
                                        id="message"
                                        label={doTranslate("Message")}
                                        multiline
                                        rows={5}
                                        maxRows={100}
                                        onChange={(event) => props.handleMessageChange(event)}
                                        value={props.message}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" color="primary" type="submit" size="large" startIcon={<Send/>}>
                                        <Translate text={"Send"}/>
                                    </Button>
                                </Grid>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ) : ""}
            </Grid>
        </form>
    );
}