import React from "react";
import Alert from "@mui/material/Alert";
import {Translate} from "./Translate";

export default function ErrorBox({error}) {

    return (
        <Alert severity="error">
            <b><Translate text="Error encountered"/></b><br/>
            {error.userMessage !== undefined && typeof error.userMessage === "string" ? (
                <p>
                    <b><Translate text="Additional Info:"/></b><br/>
                    {error.userMessage}
                </p>
            ) : (
                <p>
                    <Translate text="Generic Error"/>
                </p>
            )}
        </Alert>
    );
}