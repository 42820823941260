import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {Translate} from "./Translate";
import Button from "@mui/material/Button";
import React from "react";

export default function BackButton() {
    const onClick = () => {
        window.history.back();
    };
    return (
        <Button variant="outlined" type="button" onClick={onClick}>
            <ArrowBackIosIcon/>
            <Translate text={"Back"}/>
        </Button>
    );
}