import React from "react";
import "../../styles/Message.css";

import {default as MuiTimelineItem} from "@mui/lab/TimelineItem";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import FormattedTextParser from "../../js/FormattedTextParser";
import clsx from "clsx";
import IsoDate from "../../js/IsoDate";
import {Translate} from "../Translate";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "6px 32px",
        margin: "6px 0px",
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
    centerText: {
        flex: "0",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "1em",
    },
    textLeft: {
        textAlign: "left",
    },
    dialogue: {
        flex: 0,
    },
}));

const TimelineItem = withStyles({
    missingOppositeContent: {
        "&:before": {
            flex: 0,
            padding: "6px 32px",
        },
    },
    content: {
        padding: "16px 0",
    },
})(MuiTimelineItem);

export default function MessageContainer(props) {
    const {content, from, date} = props.message;
    const messageDate = IsoDate(date).toLocaleString(navigator.language, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: "numeric",
        minute: "numeric"
    });
    const classes = useStyles();
    if (from === "Support") {
        return (
            <TimelineItem>
                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                        {messageDate}
                        <Typography variant="h6" component="h1">
                            <Translate text={"Support"}/>
                        </Typography>
                        <Typography style={{width: "100%", wordWrap: "break-word"}}>
                            {FormattedTextParser(content, true)}
                        </Typography>
                    </Paper>
                </TimelineContent>
                <TimelineOppositeContent className={clsx(classes.textLeft, classes.dialogue)}/>
            </TimelineItem>
        );
    }
    else if (from === "System") {
        return (
            <TimelineItem>
                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                        {messageDate}
                        <Typography variant="h6" component="h1">
                            <Translate text={"System"}/>
                        </Typography>
                        <Typography style={{width: "100%", wordWrap: "break-word"}}>
                            {FormattedTextParser(content, true)}
                        </Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        );
    }
    else {
        return (
            <TimelineItem>
                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                        {messageDate}
                        <Typography variant="h6" component="h1">
                            <Translate text={"You"}/>
                        </Typography>
                        <Typography style={{width: "100%", wordWrap: "break-word"}}>
                            {FormattedTextParser(content, true)}
                        </Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        );
    }
}
