import React from "react";
import {Translate} from "../Translate";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import makeStyles from "@mui/styles/makeStyles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import useWindowDimensions from "../../js/windowDimensions";

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
    },
    label: {
        fontSize: "0.75rem"
    }
}));

export default function FilterMenu(props) {
    const classes = useStyles();
    const minDate = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
    const maxDate = new Date(Date.now()).toISOString().substr(0, 10);
    const {width} = useWindowDimensions();
    return (
        <React.Fragment>
            {props.filterMenuOpen === true ? (
                <React.Fragment>
                    {/* Filter */}
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3} lg={2}>
                            <FormControl className={classes.formControl} variant="outlined">
                                <InputLabel id="format-select-label">
                                    <Translate text="Format"/>
                                </InputLabel>
                                <Select
                                    labelId="format-select-label"
                                    label={<Translate text="Format"/>}
                                    id="format-select"
                                    value={props.format}
                                    onChange={props.onChangeFormat}
                                >
                                    <MenuItem value="streaming">
                                        <Translate text="Streaming"/>
                                    </MenuItem>
                                    <MenuItem value="download">
                                        <Translate text="Download"/>
                                    </MenuItem>
                                    <MenuItem value="ugc">
                                        <Translate text="UserGeneratedContent"/>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {width >= 960 && <Grid item xs={0} md={3} lg={6}/>}
                        <Grid item xs={12} md={3} lg={2}>
                            <FormControl className={classes.formControl} variant="outlined">
                                <TextField
                                    id="start-date"
                                    type="date"
                                    defaultValue={props.startDate.toISOString().substr(0, 10)}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={props.onChangeStartDate}
                                    variant="outlined"
                                    inputProps={{
                                        min: minDate,
                                        max: maxDate
                                    }}
                                    label={<Translate text="Start Date"/>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3} lg={2}>
                            <FormControl className={classes.formControl} variant="outlined">
                                <TextField
                                    id="end-date"
                                    type="date"
                                    defaultValue={props.endDate.toISOString().substr(0, 10)}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={props.onChangeEndDate}
                                    variant="outlined"
                                    inputProps={{
                                        min: minDate,
                                        max: maxDate
                                    }}
                                    label={<Translate text="End Date"/>}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    {/* Filter Chips */}
                    <Grid container spacing={3}>
                        {props.filterValues !== undefined && props.filterValues.artist !== undefined && props.filterValues.artist.length > 0 ? (
                            <Grid item xs={12} md={3} lg={2}>
                                <FormControl className={classes.formControl} variant="outlined">
                                    <InputLabel id="artist-select-label">
                                        <Translate text="Artist"/>
                                    </InputLabel>
                                    <Select
                                        labelId="artist-select-label"
                                        label={<Translate text="Artist"/>}
                                        id="artist-select"
                                        value={props.artist}
                                        onChange={props.onChangeArtist}
                                    >
                                        <MenuItem value=""><Translate text={"All"}/></MenuItem>
                                        {props.filterValues.artist.map((artist) => {
                                            return <MenuItem value={artist.value}>{artist.display}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        ) : ""}
                        {props.filterValues !== undefined && props.filterValues.track !== undefined && props.filterValues.track.length > 0 ? (
                            <Grid item xs={12} md={3} lg={2}>
                                <FormControl className={classes.formControl} variant="outlined">
                                    <InputLabel id="track-select-label">
                                        <Translate text="Track"/>
                                    </InputLabel>
                                    <Select
                                        labelId="track-select-label"
                                        label={<Translate text="Track"/>}
                                        id="track-select"
                                        value={props.track}
                                        onChange={props.onChangeTrack}
                                    >
                                        <MenuItem value=""><Translate text={"All"}/></MenuItem>
                                        {props.filterValues.track.map((track) => {
                                            return <MenuItem value={track.value}>{track.display}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        ) : ""}
                        {props.filterValues !== undefined && props.filterValues.bundle !== undefined && props.filterValues.bundle.length > 0 ? (
                            <Grid item xs={12} md={3} lg={2}>
                                <FormControl className={classes.formControl} variant="outlined">
                                    <InputLabel id="bundle-select-label">
                                        <Translate text="Release"/>
                                    </InputLabel>
                                    <Select
                                        labelId="bundle-select-label"
                                        label={<Translate text="Release"/>}
                                        id="bundle-select"
                                        value={props.bundle}
                                        onChange={props.onChangeBundle}
                                    >
                                        <MenuItem value=""><Translate text={"All"}/></MenuItem>
                                        {props.filterValues.bundle.map((bundle) => {
                                            return <MenuItem value={bundle.value}>{bundle.display}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        ) : ""}
                        {props.filterValues !== undefined && props.filterValues.shop !== undefined && props.filterValues.shop.length > 0 ? (
                            <Grid item xs={12} md={3} lg={2}>
                                <FormControl className={classes.formControl} variant="outlined">
                                    <InputLabel id="shop-select-label">
                                        <Translate text="Shop"/>
                                    </InputLabel>
                                    <Select
                                        labelId="shop-select-label"
                                        label={<Translate text="Shop"/>}
                                        id="shop-select"
                                        value={props.shop}
                                        onChange={props.onChangeShop}
                                    >
                                        <MenuItem value=""><Translate text={"All"}/></MenuItem>
                                        {props.filterValues.shop.map((shop) => {
                                            return <MenuItem value={shop.value}>{shop.display}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        ) : ""}
                        {props.filterValues !== undefined && props.filterValues.country !== undefined && props.filterValues.country.length > 0 ? (
                            <Grid item xs={12} md={3} lg={2}>
                                <FormControl className={classes.formControl} variant="outlined">
                                    <InputLabel id="country-select-label">
                                        <Translate text="Country"/>
                                    </InputLabel>
                                    <Select
                                        labelId="country-select-label"
                                        label={<Translate text="Country"/>}
                                        id="country-select"
                                        value={props.country}
                                        onChange={props.onChangeCountry}
                                    >
                                        <MenuItem value=""><Translate text={"All"}/></MenuItem>
                                        {props.filterValues.country.map((country) => {
                                            return <MenuItem
                                                value={country.value}>{country.display}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        ) : ""}
                        <Grid item xs={12} md={3} lg={4}>
                            <Button
                                variant="contained"
                                startIcon={<FilterListIcon/>}
                                onClick={props.hideFilterMenu}
                            >
                                <Translate text="Close Filter"/>
                            </Button>
                        </Grid>
                    </Grid>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Grid container item xs={12} spacing={3}>
                        <Grid item xs={12} md={3} lg={4}>
                            <Button
                                variant="contained"
                                startIcon={<FilterListIcon/>}
                                onClick={props.showFilterMenu}
                            >
                                <Translate text="Filter"/>
                            </Button>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}