import React, {useState} from "react";
import DataTable from "../datatable/DataTable";
import {doTranslate, Translate} from "../Translate";
import {FeiyrApi} from "../../js/FeiyrApi";
import IsoDate from "../../js/IsoDate";
import BackButton from "../BackButton";
import InfoIcon from "@mui/icons-material/Info";
import ErrorBox from "../ErrorBox";
import Details from "./Details";
import makeStyles from "@mui/styles/makeStyles";
import {Link} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {default as ExternalLink} from "@mui/material/Link";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        textAlign: "center",
        textDecoration: "none",
        "&:hover, &:focus": {
            boxShadow: "0px 2px 4px 1px rgb(0 0 0 / 20%)"
        },
        height: "100%"
    },
    flexContainer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    },
    mb10: {
        marginBottom: "10px",
        maxWidth: "113px",
        maxHeight: "113px"
    }
}));

export function LicenseRequests() {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={6} md={3} lg={3}>
                    <Paper
                        className={classes.paper}
                        name={"feiyr"}
                        component={Link}
                        to="/licenserequests/feiyr"
                    >
                        <div className={classes.flexContainer}>
                            <img src={"/img/feiyrIcons/feiyr.png"} alt={doTranslate("Feiyr")} className={classes.mb10}/>
                            <Typography variant="body1" component={"span"}>
                                <Translate text="Feiyr Compilations"/>
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <Paper
                        className={classes.paper}
                        name={"ugc"}
                        component={Link}
                        to="/licenserequests/ugc"
                    >
                        <div className={classes.flexContainer}>
                            <img src={"/img/feiyrIcons/ugc.png"} alt={doTranslate("UGC")} className={classes.mb10}/>
                            <Typography variant="body1" component={"span"}>
                                <Translate text="UGC"/>
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export function OpenLicenseRequests(props) {
    const [error, setError] = useState(null);
    const [data, setData] = useState(false);
    const [entryCount, setEntryCount] = useState(0);
    const [approvalTracks, setApprovalTracks] = useState({});

    const columns = [
        {
            name: "bundle_cover",
            label: doTranslate("Release"),
            options: {
                filter: false,
                sort: true,
                searchable: true,
                display: true,
                setCellHeaderProps: () => ({
                    style: {width: "20px", paddingLeft: 0},
                }),
                setCellProps: () => ({style: {width: "20px", paddingLeft: 0}}),
                customBodyRender: (value, tableMeta) => {
                    if (value === undefined || value === null || value.toString().trim() === "") {
                        return (
                            <React.Fragment>
                                <img
                                    alt={tableMeta.rowData[9]}
                                    src="https://accounts.feiyr.com/img/icons/no_image2.png"
                                    style={{width: "100%", padding: "0px", margin: "0px"}}
                                />
                                <div>
                                    <p style={{fontSize: "0.5rem", margin: "0"}}>{tableMeta.rowData[8]}</p>
                                    <p
                                        style={{
                                            fontWeight: "700",
                                            paddingTop: "0rem",
                                            paddingBottom: "0.5rem",
                                            margin: "0",
                                        }}
                                    >
                                        {tableMeta.rowData[9]}
                                    </p>
                                </div>
                            </React.Fragment>
                        );
                    }
                    else {
                        return (
                            <React.Fragment>
                                <img
                                    alt={tableMeta.rowData[9]}
                                    src={`${process.env.REACT_APP_DOWNLOAD_URL}/cover/65/0/${tableMeta.rowData[10]}_80.jpg`}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = "https://accounts.feiyr.com/img/icons/no_image2.png";
                                    }}
                                    style={{width: "100%", padding: "0px", margin: "0px"}}
                                />
                                <div>
                                    <p style={{fontSize: "0.5rem", margin: "0"}}>{tableMeta.rowData[8]}</p>
                                    <p
                                        style={{
                                            fontWeight: "700",
                                            paddingTop: "0rem",
                                            paddingBottom: "0.5rem",
                                            margin: "0",
                                        }}
                                    >
                                        {tableMeta.rowData[9]}
                                    </p>
                                </div>
                            </React.Fragment>
                        );
                    }
                }
            }
        },
        {
            name: "track",
            label: doTranslate("Track"),
            options: {
                filter: false,
                sort: true,
                searchable: true,
                display: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div>
                            <p style={{fontSize: "0.7rem", margin: "0"}}>{tableMeta.rowData[6]}</p>
                            <p
                                style={{
                                    fontWeight: "700",
                                    paddingTop: "0rem",
                                    paddingBottom: "0.5rem",
                                    margin: "0",
                                }}
                            >
                                {tableMeta.rowData[7]}
                            </p>
                        </div>
                    );
                }
            }
        },
        {
            name: "track_list",
            label: "track_list",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                display: false
            }
        },
        {
            name: "country_list",
            label: "country_list",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                display: false
            }
        },
        {
            name: "digital_release_date",
            label: "digital_release_date",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                display: false
            }
        },
        {
            name: "end_date",
            label: doTranslate("End Date"),
            options: {
                filter: false,
                sort: false,
                searchable: false,
                display: true,
                customBodyRender: value => value.toLocaleString(navigator.language, {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: "numeric",
                    minute: "numeric"
                })
            }
        },
        {
            name: "track_artist",
            label: "track_artist",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                display: false
            }
        },
        {
            name: "track_title",
            label: "track_title",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                display: false
            }
        },
        {
            name: "bundle_artist",
            label: "bundle_artist",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                display: false
            }
        },
        {
            name: "bundle_title",
            label: "bundle_title",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                display: false
            }
        },
        {
            name: "bundle_id",
            label: "bundle_id",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                display: false
            }
        },
        {
            name: "track_id",
            label: "track_id",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                display: false
            }
        },
        {
            name: "approval",
            label: "approval",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                display: false
            }
        },
    ];

    const fetchData = (dataTable) => {
        new FeiyrApi().get("/LicenseRequests/ListOpen/" + localStorage.getItem("userId"), {ugc: props.ugc}, dataTable)
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    let approval = {};
                    let values = res.data.values.map((el) => {
                        approval[el.track_id] = parseInt(el.approval);
                        return {
                            track: el.track_id,
                            track_id: el.track_id,
                            track_artist: el.artist,
                            track_title: el.display_name,
                            bundle_artist: el.bundleArtist,
                            bundle_title: el.title,
                            bundle_cover: el.cover_file_name,
                            digital_release_date: IsoDate(el.digital_release_date),
                            end_date: IsoDate(el.endDate),
                            bundle_id: el.bundle_id,
                            track_list: el.tracks.tracks,
                            country_list: el.countries,
                            approval: el.approval
                        };
                    });
                    setData(values);
                    setEntryCount(res.data.display.iTotalRecords);
                    setApprovalTracks(prev => {
                        return {...prev, ...approval};
                    });
                }
                else {
                    setError({userMessage: "Error fetching data"});
                }
            })
            .catch((err) => {
                err.userMessage = "Error fetching data";
                setError(err);
            });
    };

    const renderExpandableRow = (rowData) => {
        return <Details
            trackList={rowData[2]}
            countryList={rowData[3]}
            trackId={rowData[11]}
            bundleId={rowData[10]}
            approvalTracks={approvalTracks}
            setApprovalTracks={setApprovalTracks}
            ugc={props.ugc}
        />
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={10}>
                <BackButton/>
            </Grid>
            <Grid item xs={2} style={{textAlign: "right"}}>
                {props.ugc === true ? (
                    <IconButton
                        component={ExternalLink}
                        href={"https://accounts.feiyr.com/music/tools/licenseRequests/explanation/ugc.html"}
                        target={"_blank"} color={"primary"}
                        style={{padding: "6px"}}
                    >
                        <InfoIcon/>
                    </IconButton>
                ) : (
                    <IconButton
                        component={ExternalLink}
                        href={"https://accounts.feiyr.com/music/tools/licenseRequests/explanation/feiyr.html"}
                        target={"_blank"} color={"primary"}
                        style={{padding: "6px"}}
                    >
                        <InfoIcon/>
                    </IconButton>
                )}
            </Grid>
            <Grid item xs={12}>
                {error === null ? (
                    <DataTable
                        title={doTranslate("License Requests")}
                        data={data}
                        entryCount={entryCount}
                        columns={columns}
                        fetchData={fetchData}
                        tableOptions={{
                            "renderExpandableRow": renderExpandableRow
                        }}
                        showBackButton={false}
                    />
                ) : (
                    <ErrorBox error={error}/>
                )}
            </Grid>
        </Grid>
    );
}