import React, {useEffect, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {getLanguagePack, Translate} from "../Translate";
import ListItemIcon from "@mui/material/ListItemIcon";
import Language from "@mui/icons-material/Language";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
}));

export default function LanguageSelector() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [lang, setLang] = useState(
        navigator.language.slice(0, 2) || navigator.userLanguage.slice(0, 2)
    );

    useEffect(() => {
        localStorage.getItem("lang") !== null
            ? setLang(localStorage.getItem("lang"))
            : localStorage.setItem("lang", lang);
    }, [open]);

    const handleChange = (event) => {
        let l = event.target.value || "";
        localStorage.setItem("lang", l);
        getLanguagePack().then(r => setLang(l));
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event) => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <ListItem button onClick={handleClickOpen}>
                <ListItemIcon>
                    <Language/>
                </ListItemIcon>
                <ListItemText>
                    <Translate text="LanguagePicker"/>
                </ListItemText>
            </ListItem>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <Translate text="PleaseSelectLanguage"/>
                </DialogTitle>
                <DialogContent>
                    <form className={classes.container}>
                        <FormControl className={classes.formControl} variant="outlined">
                            <InputLabel id="demo-dialog-select-label">
                                <Translate text="SelectLanguage"/>
                            </InputLabel>
                            <Select
                                labelId="demo-dialog-select-label"
                                label={<Translate text="SelectLanguage"/>}
                                id="demo-dialog-select"
                                value={lang}
                                onChange={handleChange}
                            >
                                <MenuItem value={"de"}>
                                    {lang === "de" && <em><Translate text="German"/></em>}
                                    {lang !== "de" && <Translate text="German"/>}
                                </MenuItem>
                                <MenuItem value={"en"}>
                                    {lang === "en" && <em><Translate text="English"/></em>}
                                    {lang !== "en" && <Translate text="English"/>}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        <Translate text="Close"/>
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
