import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import LoadingBar from "../../template/LoadingBar";
import Entries from "./AccordionEntry";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    }
}));

export default function SystemAccordion({systemNews}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {typeof systemNews !== "object" || Object.keys(systemNews).length === 0 ? (
                <LoadingBar/>
            ) : (
                <Entries classes={classes} systemNews={systemNews}/>
            )}
        </div>
    );
}
