import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {doTranslate, Translate} from "../Translate";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        textAlign: "center",
        textDecoration: "none",
        "&:hover, &:focus": {
            boxShadow: "0px 2px 4px 1px rgb(0 0 0 / 20%)"
        },
        height: "100%"
    },
    flexContainer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    },
    mb10: {
        marginBottom: "10px"
    }
}));

export default function Nova() {
    const classes = useStyles();
    return (
        <Grid container spacing={3}>
            <Grid item xs={6} md={3} lg={3}>
                <Paper
                    className={classes.paper}
                    name={"inventory"}
                    component={Link}
                    to="/nova/inventory"
                >
                    <div className={classes.flexContainer}>
                        <img src={"/img/feiyrIcons/inventory.png"} alt={doTranslate("Physical")} className={classes.mb10}/>
                        <Typography variant="body1" component={"span"}>
                            <Translate text="Nova Inventory"/>
                        </Typography>
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
                <Paper
                    className={classes.paper}
                    name={"sales"}
                    component={Link}
                    to="/statistics/nova"
                >
                    <div className={classes.flexContainer}>
                        <img src={"/img/feiyrIcons/physicalStatistics.png"} alt={doTranslate("Physical")} className={classes.mb10}/>
                        <Typography variant="body1" component={"span"}>
                            <Translate text="Nova Sales"/>
                        </Typography>
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
                <Paper
                    className={classes.paper}
                    name={"orderrequest"}
                    component={Link}
                    to="/nova/orderrequest"
                >
                    <div className={classes.flexContainer}>
                        <img src={"/img/feiyrIcons/orderRequest.png"} alt={doTranslate("Order Request")} className={classes.mb10}/>
                        <Typography variant="body1" component={"span"}>
                            <Translate text="Order Request"/>
                        </Typography>
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
                <Paper
                    className={classes.paper}
                    name={"returnrequest"}
                    component={Link}
                    to="/nova/returnrequest"
                >
                    <div className={classes.flexContainer}>
                        <img src={"/img/feiyrIcons/returnRequest.png"} alt={doTranslate("Return Request")} className={classes.mb10}/>
                        <Typography variant="body1" component={"span"}>
                            <Translate text="Return Request"/>
                        </Typography>
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
                <Paper
                    className={classes.paper}
                    name={"linkit"}
                    component={Link}
                    to="/linkit/physical"
                >
                    <div className={classes.flexContainer}>
                        <img src={"/img/feiyrIcons/physical.png"} alt={doTranslate("Link-It")}
                             className={classes.mb10}/>
                        <Typography variant="body1" component={"span"}>
                            <Translate text="Link-It"/>
                        </Typography>
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
}