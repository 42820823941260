import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {doTranslate, Translate} from "../Translate";
import React, {useEffect, useState} from "react";
import {FeiyrApi} from "../../js/FeiyrApi";
import ErrorBox from "../ErrorBox";
import LoadingBar from "../template/LoadingBar";
import List from "@mui/material/List";
import DisposalArticleEntry from "./DisposalArticleEntry";

export default function ReturnRequestConfirmDialog(props) {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [price, setPrice] = useState(0);
    const [disposalPrice, setDisposalPrice] = useState(0);
    const [hasDisposal, setHasDisposal] = useState(false);

    useEffect(() => {
        const feiyrApi = new FeiyrApi();
        if (props.disposalArticles !== null && Object.keys(props.disposalArticles).length > 0) {
            let hasDisposal = false;
            Object.entries(props.disposalArticles).forEach(entry => {
                if (entry[1] > 0) {
                    hasDisposal = true;
                }
            });
            setHasDisposal(hasDisposal);
            if (hasDisposal) {
                setLoading(true);
                let disposalParams = {
                    user_id: localStorage.getItem("userId"),
                    articles: props.disposalArticles,
                };
                feiyrApi.get("/NovaDisposalRequest/Price", disposalParams)
                    .then(res => {
                        if (res !== undefined && (res.success === true || res.success === "true")) {
                            setPrice(parseFloat(res.price));
                            setDisposalPrice(parseFloat(res.price));
                            setLoading(false);
                        }
                        else {
                            setError({userMessage: "Error fetching price"});
                        }
                    })
                    .catch(err => {
                        err.userMessage = "Error fetching price";
                        setError(err);
                    });
            }
        }
        else {
            setHasDisposal(false);
        }
    }, [props.open]);

    return (
        <Dialog
            open={props.open}
            keepMounted
            onClose={props.onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                <Translate text={"Confirm Return Request"}/>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {error === null ? (
                        <React.Fragment>
                            {loading === true ? <LoadingBar/> : (
                                <React.Fragment>
                                    {hasDisposal ? (
                                        <React.Fragment>
                                            <Translate text={"Disposal Articles:"}/>
                                            <List>
                                                {Object.entries(props.disposalArticles).map(entry => {
                                                    if (entry[1] <= 0) {
                                                        return null;
                                                    }
                                                    return (
                                                        <DisposalArticleEntry
                                                            articleList={props.articleList}
                                                            articleId={parseInt(entry[0])}
                                                            quantity={entry[1]}
                                                        />
                                                    );
                                                })}
                                            </List>
                                            {doTranslate("DisposalPrice").replace(/#PRICE#/, new Intl.NumberFormat(navigator.language, {
                                                style: 'currency',
                                                currency: 'EUR'
                                            }).format(disposalPrice))}
                                            <br/><br/>
                                            {doTranslate("ConfirmRequestConfirmText").replace(/#PRICE#/, new Intl.NumberFormat(navigator.language, {
                                                style: 'currency',
                                                currency: 'EUR'
                                            }).format(price))}
                                        </React.Fragment>
                                    ) : (
                                        <Translate text={"ConfirmRequestTextOnlyShipping"}/>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ) : <ErrorBox error={error}/>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onDeny} color="secondary">
                    <Translate text="Close"/>
                </Button>
                {loading === false && error === null && (
                    <Button onClick={props.onAccept} color="primary">
                        <Translate text="Confirm"/>
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}