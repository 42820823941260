import {FeiyrApi} from "../js/FeiyrApi";
import FormattedTextParser from "../js/FormattedTextParser";

export function Translate({text}) {
    return doTranslate(text);
}

export function doTranslate(text) {
    let translation;
    if (localStorage.getItem("languagePack") === null ||
        localStorage.getItem("languagePack") === undefined ||
        JSON.parse(localStorage.getItem("languagePack")).success === undefined
    ) {
        translation = text;
    }
    else if (text in JSON.parse(localStorage.getItem("languagePack")).data) {
        translation = JSON.parse(localStorage.getItem("languagePack")).data[text];
    }
    else {
        translation = text;
        if (process.env.REACT_APP_ENV !== "production") {
            translation += " (missing)";
        }
    }
    return FormattedTextParser(translation, true, true);
}

export async function getLanguagePack() {
    new FeiyrApi().get("/Language/WebAppContents")
        .then(result => {
            localStorage.setItem("languagePack", JSON.stringify(result));
            return result;
        })
        .catch(e => console.error(e));
}