import React, {useEffect, useState} from "react";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";

export default function DisposalArticleEntry(props) {
    const [artist, setArtist] = useState("");
    const [title, setTitle] = useState("");
    const [ean, setEan] = useState("");
    const [quantity, setQuantity] = useState(0);

    useEffect(() => {
        props.articleList.forEach(article => {
            if (article.ARTICLEID === props.articleId) {
                setArtist(article.ARTIST);
                setTitle(article.TITLE1);
                setEan(article.EAN);
            }
        });
        setQuantity(props.quantity);
    }, [props]);

    return (
        <ListItem>
            <ListItemAvatar>
                {quantity} x
            </ListItemAvatar>
            <ListItemText>
                <p style={{fontSize: "0.7rem", margin: "0"}}>{artist}</p>
                <p style={{
                    fontWeight: "700",
                    paddingTop: "0rem",
                    paddingBottom: "0.5rem",
                    margin: "0",
                }}>{title}</p>
                <p style={{fontSize: "0.7rem", margin: "0"}}>{ean}</p>
            </ListItemText>
        </ListItem>
    );
}