/**
 *
 * @param {string} date
 * @returns {Date}
 */
export default function IsoDate(date) {
    if (date == null) {
        return null;
    }
    if (date instanceof Date) {
        return date;
    }
    date = date.trim();
    if (date.match(/^\d{4}-\d{2}-\d{2}$/)) {
        let year = parseInt(date.substr(0, 4)),
            month = parseInt(date.substr(5, 2)) - 1,
            day = parseInt(date.substr(8, 2));
        if (!checkDateValues(year, month, day)) {
            throw new Error("Invalid date given: " + date);
        }
        return new Date(Date.UTC(year, month, day));
    }
    else if (date.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/)) {
        let year = parseInt(date.substr(0, 4)),
            month = parseInt(date.substr(5, 2)) - 1,
            day = parseInt(date.substr(8, 2)),
            hour = parseInt(date.substr(11, 2)),
            minute = parseInt(date.substr(14, 2));
        if (!checkDateValues(year, month, day, hour, minute)) {
            throw new Error("Invalid date given: " + date);
        }
        return new Date(Date.UTC(year, month, day, hour, minute));
    }
    else if (date.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)) {
        let year = parseInt(date.substr(0, 4)),
            month = parseInt(date.substr(5, 2)) - 1,
            day = parseInt(date.substr(8, 2)),
            hour = parseInt(date.substr(11, 2)),
            minute = parseInt(date.substr(14, 2)),
            second = parseInt(date.substr(17, 2));
        if (!checkDateValues(year, month, day, hour, minute, second)) {
            throw new Error("Invalid date given: " + date);
        }
        return new Date(Date.UTC(year, month, day, hour, minute, second));
    }
    return new Date(date);
}

/**
 *
 * @param {number} year
 * @param {number} month
 * @param {number} day
 * @param {?number} hour
 * @param {?number} minute
 * @param {?number} second
 */
function checkDateValues(year, month, day, hour = null, minute = null, second = null) {
    if (month < 0 || month >= 12) {
        return false;
    }
    else if (day <= 0 || day > 31) {
        return false;
    }
    else if (hour != null && (hour < 0 || hour > 23)) {
        return false;
    }
    else if (minute != null && (minute < 0 || minute > 59)) {
        return false;
    }
    else if (second != null && (second < 0 || second > 59)) {
        return false;
    }
    return true;
}