import React, {useEffect, useState} from "react";
import {FeiyrApi} from "../../js/FeiyrApi";
import ErrorBox from "../ErrorBox";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {Translate} from "../Translate";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function SupportFaq(props) {
    const [error, setError] = useState(null);
    const [faq, setFaq] = useState(null);

    useEffect(() => {
        if (props.categoryId > 0) {
            let params = {
                category_id: props.categoryId
            };
            if (props.subCategoryId > 0) {
                params["sub_category_id"] = props.subCategoryId;
            }
            new FeiyrApi().get("/SupportFaq", params)
                .then(res => {
                    if (res !== undefined && (res.success === "true" || res.success === true)) {
                        setError(null);
                        if (res.data.length > 0) {
                            setFaq(res.data);
                            props.setFaqRead(false);
                        } else {
                            setFaq(null);
                            props.setFaqRead(null);
                        }
                    } else {
                        setFaq(null);
                        props.setFaqRead(null);
                        setError({userMessage: "Error fetching FAQs"});
                    }
                })
                .catch(err => {
                    err.userMessage = "Error fetching FAQs";
                    setError(err);
                    setFaq(null);
                    props.setFaqRead(null);
                });
        } else {
            setFaq(null);
            props.setFaqRead(null);
        }
    }, [props.categoryId, props.subCategoryId]);

    const htmlDecode = function(input) {
        let doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    };

    return (
        <React.Fragment>
            {error === null ? (
                <React.Fragment>
                    {faq !== null && (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <Translate text="SupportFaqCheck"/>
                                <List>
                                    {Object.entries(faq).map(entry => {
                                        return (
                                            <ListItem key={entry[1].id}>
                                                {entry[1].type === "entry" ? (
                                                    <Link
                                                        href={process.env.REACT_APP_URL + "/" + localStorage.getItem("lang") + "/faq/" + entry[1].id + "/," + entry[1].link + ".html"}
                                                        target="_blank">
                                                        {htmlDecode(entry[1].name)}
                                                    </Link>
                                                ) : (
                                                    <Link
                                                        href={process.env.REACT_APP_URL + "/" + localStorage.getItem("lang") + "/faq/category/" + entry[1].id + "/," + entry[1].link + ".html"}
                                                        target="_blank">
                                                        {htmlDecode(entry[1].name)}
                                                    </Link>
                                                )}
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox required onChange={(e) => props.setFaqRead(e.target.checked)}/>} label={<Translate text={"FaqRead"}/>}/>
                            </Grid>
                        </React.Fragment>
                    )}
                </React.Fragment>
            ) : (
                <ErrorBox error={error}/>
            )}
        </React.Fragment>
    );
}