import CustomFooter from "./CustomFooter";
import React, {useEffect} from "react";
import MUIDataTable from "mui-datatables";
import LoadingBar from "../template/LoadingBar";
import useWindowDimensions from "../../js/windowDimensions";
import {doTranslate} from "../Translate";
import BackButton from "../BackButton";
import Grid from "@mui/material/Grid";

export default function DataTable(props) {
    let {width} = useWindowDimensions();
    let {entryCount, columns, fetchData} = props;
    const showBackButton = props.showBackButton === undefined || props.showBackButton === true;

    let filter = false;

    let dataTableColumns = [];
    for (let column in columns) {
        if (columns.hasOwnProperty(column)) {
            let dataTableCol = new DataTableColumn(false, false);
            if (columns[column].options !== undefined) {
                if (columns[column].options.sort === true) {
                    dataTableCol.sortable = true;
                }
                dataTableCol.searchable = columns[column].options.searchable === true;
                if (columns[column].options.filter === true) {
                    filter = true;
                }
            }
            dataTableColumns.push(dataTableCol);
        }
    }
    let dataTable = new DataTableParams(dataTableColumns);

    useEffect(() => {
        fetchData(dataTable);
    }, []);

    let options = {
        filter: filter,
        filterType: "dropdown",
        responsive: "standard",
        tableBodyMaxHeight: "100%",
        download: false,
        print: false,
        expandableRows: true,
        expandableRowsOnClick: true,
        selectableRows: "none",
        enableNestedDataAccess: ".",
        customFooter: (
            count,
            page,
            rowsPerPage,
            changeRowsPerPage,
            changePage,
            textLabels
        ) => {
            return (
                <CustomFooter
                    changePage={changePage}
                    count={entryCount}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    changeRowsPerPage={changeRowsPerPage}
                    textLabels={textLabels}
                />
            );
        },
        filterOptions: {
            fullWidth: true,
        },
        serverSide: true,
        onTableChange: (action, tableState) => {
            if (action === "sort") {
                try {
                    dataTable.setSortCol(tableState.activeColumn, tableState.sortOrder.direction);
                    fetchData(dataTable);
                }
                catch (e) {
                    console.error(e);
                }
            }
            else if (action === "changePage") {
                dataTable.iDisplayLength = tableState.rowsPerPage;
                dataTable.iDisplayStart = tableState.page * tableState.rowsPerPage;
                fetchData(dataTable);
            }
            else if (action === "changeRowsPerPage") {
                dataTable.iDisplayLength = tableState.rowsPerPage;
                fetchData(dataTable);
            }
            else if (action === "search") {
                dataTable.setSearchText(tableState.searchText);
                fetchData(dataTable);
            }
        },
        onFilterChange: (column, filterList) => {
            for (let i = 0; i < filterList.length; i++) {
                if (filterList[i].length === 0) {
                    dataTable.removeFilter(i);
                }
                else {
                    let value = filterList[i][0];
                    if (props.weirdFilter !== undefined && typeof props.weirdFilter === "function") {
                        value = props.weirdFilter(i, value);
                    }
                    dataTable.addFilter(i, value);
                }
            }
            fetchData(dataTable);
        },
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: doTranslate("Sorry, no matching records found"),
                toolTip: doTranslate("Sort"),
                columnHeaderTooltip: column => doTranslate("Sort for") + ` ${column.label}`
            },
            pagination: {
                next: doTranslate("Next Page"),
                previous: doTranslate("Previous Page"),
                rowsPerPage: doTranslate("Rows per page") + ":",
                displayRows: doTranslate("of"),
            },
            toolbar: {
                search: doTranslate("Search"),
                filterTable: doTranslate("Filter Table"),
            },
            filter: {
                all: doTranslate("All"),
                title: doTranslate("Filters"),
                reset: doTranslate("Reset"),
            },
        }
    };
    if (props.tableOptions !== undefined && props.tableOptions !== null && Object.keys(props.tableOptions).length > 0) {
        for (let key in props.tableOptions) {
            if (props.tableOptions[key] !== undefined && props.tableOptions[key] !== null) {
                options[key] = props.tableOptions[key];
            }
        }
    }

    return (
        <Grid container spacing={3}>
            {showBackButton && (
                <Grid item xs={12}>
                    <BackButton/>
                </Grid>
            )}
            <Grid item xs={12}>
                {props.data !== false ? (
                    <MUIDataTable
                        title={props.title}
                        data={props.data}
                        columns={columns}
                        options={options}
                        width={width}
                    />
                ) : (
                    <LoadingBar/>
                )}
            </Grid>
        </Grid>
    );
}

export class DataTableParams {
    /**
     *
     * @param columns
     * @param {number} [displayStart]
     * @param {number} [displayLength]
     */
    constructor(columns, displayStart = 0, displayLength = 10) {
        this.sEcho = 1;
        this.iColumns = columns.length;
        this.sColumns = "";
        this.sSearch = "";
        this.iDisplayStart = displayStart;
        this.iDisplayLength = displayLength;
        this.bSortables = [];
        this.bSearchables = [];
        for (let i = 0; i < this.iColumns; i++) {
            this.bSortables.push(columns[i].sortable);
            this.bSearchables.push(columns[i].searchable);
        }
        for (let i = 0; i < this.iColumns - 1; i++) {
            this.sColumns += ",";
        }
        this.iSortingCols = "";
        this.filter = {};
    }

    /**
     *
     * @returns {string}
     */
    toString() {
        let obj = {
            sEcho: this.sEcho,
            sColumns: this.sColumns,
            iColumns: this.iColumns,
            iDisplayStart: this.iDisplayStart,
            iDisplayLength: this.iDisplayLength,
            sSearch: this.sSearch,
            iSortingCols: this.iSortingCols,
            bSortables: this.bSortables,
            bSearchables: this.bSearchables
        };
        if (this.sortCol !== undefined && this.sortDir !== undefined) {
            obj.iSortCol_0 = this.sortCol;
            obj.sSortDir_0 = this.sortDir;
        }
        let queryString = "";
        if (Object.keys(this.filter).length > 0) {
            let filter = {},
                keys = Object.keys(this.filter);
            for (let i = 0; i < keys.length; i++) {
                if (keys[i] !== null && this.filter[keys[i]] !== null) {
                    filter[keys[i]] = this.filter[keys[i]];
                }
            }
            obj.filter = filter;
        }
        queryString += serializeObject(obj, "table_params");
        return queryString;
    }

    setSortCol(col, dir) {
        if (col < 0 || col > this.iColumns) {
            throw new Error("Col is out of range");
        }
        if (dir !== "asc" && dir !== "desc") {
            throw new Error("Dir must be either 'asc' or 'desc'");
        }
        this.sortCol = col;
        this.sortDir = dir;
        this.iSortingCols = col;
    }

    setSearchText(searchText) {
        if (searchText === undefined || searchText === null) {
            this.sSearch = "";
        }
        else {
            this.sSearch = searchText.toString().trim();
        }
    }

    addFilter(columnIndex, value) {
        this.filter[columnIndex] = value;
    }

    removeFilter(columnIndex) {
        this.filter[columnIndex] = null;
    }
}

export class DataTableColumn {
    /**
     *
     * @param {boolean} sortable
     * @param {boolean} searchable
     */
    constructor(sortable, searchable) {
        this.sortable = sortable;
        this.searchable = searchable;
    }

    setSortable(sortable) {
        this.sortable = sortable;
    }

    setSearchable(searchable) {
        this.searchable = searchable;
    }
}

function serializeObject(obj, prefix) {
    let str = [],
        p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            str.push((v !== null && typeof v === "object") ?
                serializeObject(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}