import React, {useEffect, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import BarChartIcon from "@mui/icons-material/BarChart";
import MailIcon from "@mui/icons-material/Mail";
import {Link} from "react-router-dom";
import {Translate} from "../Translate";
import PublicIcon from "@mui/icons-material/Public";

const useStyles = makeStyles({
    root: {
        width: "100vw",
        position: "fixed",
        bottom: 0,
        zIndex: 1000,
        backgroundColor: "black",
    },
    actionButton: {
        color: "white",
    },
});

export default function BottomNav(props) {
    const classes = useStyles();
    const [active, setActive] = useState("/");

    useEffect(() => {
        let temp = props.route.split("/");
        if (temp.length > 1) {
            setActive("/" + temp[1]);
        } else {
            setActive("/");
        }
    }, [props.route]);

    return (
        <BottomNavigation
            value={active}
            className={classes.root}
        >
            <BottomNavigationAction
                label={<Translate text="Home"/>}
                value="/"
                icon={<HomeIcon/>}
                component={Link}
                to="/"
                className={classes.actionButton}
            />
            <BottomNavigationAction
                label={<Translate text="Statistics"/>}
                value="/statistics"
                icon={<BarChartIcon/>}
                component={Link}
                to="/statistics"
                className={classes.actionButton}
            />
            <BottomNavigationAction
                label={<Translate text="Bundles"/>}
                value="/bundles"
                icon={<PublicIcon/>}
                component={Link}
                to="/bundles"
                className={classes.actionButton}
            />
            <BottomNavigationAction
                label={<Translate text="Inbox"/>}
                value="/inbox"
                icon={<MailIcon/>}
                component={Link}
                to="/inbox"
                className={classes.actionButton}
            />
        </BottomNavigation>
    );
}
