import React, {useEffect, useState} from "react";
import Chart from "../charts/Chart";
import makeStyles from "@mui/styles/makeStyles";
import DefaultPieChart from "../charts/DefaultPieChart";
import TinyBar from "../charts/TinyBar";
import Grid from "@mui/material/Grid";
import MusicTopTable from "./MusicTopTable";
import {Translate} from "../Translate";
import {FeiyrApi} from "../../js/FeiyrApi";
import IsoDate from "../../js/IsoDate";
import ErrorBox from "../ErrorBox";
import FilterMenu from "./FilterMenu";
import LoadingBar from "../template/LoadingBar";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import BackButton from "../BackButton";
import Alert from "@mui/material/Alert";

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: "white",
    },
    paper: {
        paddingTop: '1rem',
        display: "flex",
        overflow: "hidden",
        flexDirection: "column",
        padding: "16px"
    }
}));

export default function Music() {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    /*
     * 0: loading
     * 1: available, all good
     * 2: share calculation disabled (e.g. sales are being inserted)
     * 3: error, please try again later
     * 4: currently in creation, please try again in a few minutes
     */
    const [available, setAvailable] = useState(0);
    const [shops, setShops] = useState();
    const [devices, setDevices] = useState();
    const [memberships, setMemberships] = useState();
    const [sources, setSources] = useState();
    const [operatingSystem, setOperatingSystem] = useState();
    const [gender, setGender] = useState();
    const [ageGroup, setAgeGroup] = useState();
    const [topTracks, setTopTracks] = useState();
    const [topArtists, setTopArtists] = useState();
    const [trend, setTrend] = useState();
    const [countries, setCountries] = useState();
    const [filterValues, setFilterValues] = useState();

    const [filterMenuOpen, setFilterMenuOpen] = useState(false);
    const [format, setFormat] = useState("streaming");
    const [startDate, setStartDate] = useState(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000));
    const [endDate, setEndDate] = useState(new Date(Date.now()));
    const [artist, setArtist] = useState("");
    const [country, setCountry] = useState("");
    const [shop, setShop] = useState("");
    const [track, setTrack] = useState("");
    const [bundle, setBundle] = useState("");

    useEffect(() => {
        let feiyrApi = new FeiyrApi();
        feiyrApi.get("/LiveSales/StatsAvailable/" + localStorage.getItem("userId"))
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    setAvailable(1);
                    feiyrApi.get("/LiveSales/FilterableValues/" + localStorage.getItem("userId"), {language: localStorage.getItem("lang")})
                        .then((res) => {
                            if (res !== undefined && (res.success === true || res.success === "true")) {
                                setFilterValues(res.data);
                            }
                        })
                        .catch(err => {
                            err.userMessage = "Error fetching filter values";
                            setError(err);
                        });
                }
                else if (res !== undefined && res.data !== undefined) {
                    if (res.data === "Currently not available") {
                        setAvailable(2);
                    } else if (res.data === "error creating") {
                        setAvailable(3); // error @ view creation
                    } else if (res.data === "currently in creation") {
                        setAvailable(4);
                    } else {
                        setAvailable(3); // error @ view creation
                    }
                }
                else {
                    setAvailable(3); // error @ view creation
                }
            })
            .catch(err => {
                err.userMessage = "Error determining available status";
                setError(err);
            });
    }, []);

    useEffect(() => {
        if (available) {
            setLoading(true);
            let params = {
                filterValues: {
                    format: format,
                    start_date: startDate.toISOString().substr(0, 10),
                    end_date: endDate.toISOString().substr(0, 10),
                    content: {
                        artist: [],
                        track: [],
                        bundle: [],
                        shop: [],
                        country: []
                    }
                }
            };
            if (artist !== "") {
                params.filterValues.content.artist.push(artist);
            }
            if (country !== "") {
                params.filterValues.content.country.push(country);
            }
            if (shop !== "") {
                params.filterValues.content.shop.push(shop);
            }
            if (track !== "") {
                params.filterValues.content.track.push(track);
            }
            if (bundle !== "") {
                params.filterValues.content.bundle.push(bundle);
            }
            let tableParams = {
                filterValues: params.filterValues,
                tableParams: {
                    sSearch: "",
                    iDisplayStart: 0,
                    iDisplayLength: 3
                }
            };
            let feiyrApi = new FeiyrApi();
            Promise.all([
                feiyrApi.get("/LiveSales/Trend/" + localStorage.getItem("userId"), params),
                feiyrApi.get("/LiveSales/Shops/" + localStorage.getItem("userId"), params),
                feiyrApi.get("/LiveSales/Devices/" + localStorage.getItem("userId"), params),
                feiyrApi.get("/LiveSales/memberships/" + localStorage.getItem("userId"), params),
                feiyrApi.get("/LiveSales/sources/" + localStorage.getItem("userId"), params),
                feiyrApi.get("/LiveSales/operatingsystems/" + localStorage.getItem("userId"), params),
                feiyrApi.get("/LiveSales/genders/" + localStorage.getItem("userId"), params),
                feiyrApi.get("/LiveSales/agegroups/" + localStorage.getItem("userId"), params),
                feiyrApi.get("/LiveSales/toptracks/" + localStorage.getItem("userId"), tableParams),
                feiyrApi.get("/LiveSales/topartists/" + localStorage.getItem("userId"), tableParams),
                feiyrApi.get("/LiveSales/countries/" + localStorage.getItem("userId"), params),
            ]).then(res => new Promise((resolve, reject) => {
                let success = true;
                res.forEach(response => {
                    if (response === undefined || (response.success !== true && response.success !== "true")) {
                        success = false;
                    }
                });
                if (success) {
                    resolve(res);
                }
                else {
                    reject(new Error("Error fetching data"));
                }
            })).then(res => {
                setTrend(res[0].data);
                setShops(res[1].data);
                setDevices(res[2].data);
                setMemberships(res[3].data);
                setSources(res[4].data);
                setOperatingSystem(res[5].data);
                setGender(res[6].data);
                setAgeGroup(res[7].data);
                setTopTracks(res[8]);
                setTopArtists(res[9]);
                setCountries(res[10].data);
                setLoading(false);
            }).catch(err => {
                err.userMessage = "Error fetching data";
                setError(err);
                setLoading(false);
            });
        }
    }, [available, format, startDate, endDate, artist, country, shop, track, bundle]);

    const classes = useStyles();

    const onChangeFormat = (event) => {
        setFormat(event.target.value);
    };
    const onChangeStartDate = (event) => {
        if (event.target.value === undefined || event.target.value === null || event.target.value.trim() === "") {
            setStartDate(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000));
        }
        else {
            setStartDate(new IsoDate(event.target.value));
        }
    };
    const onChangeEndDate = (event) => {
        if (event.target.value === undefined || event.target.value === null || event.target.value.trim() === "") {
            setStartDate(new Date(Date.now()));
        }
        else {
            setEndDate(new IsoDate(event.target.value));
        }
    };
    const onChangeArtist = (event) => {
        setArtist(event.target.value);
    };
    const onChangeTrack = (event) => {
        setTrack(event.target.value);
    };
    const onChangeBundle = (event) => {
        setBundle(event.target.value);
    };
    const onChangeShop = (event) => {
        setShop(event.target.value);
    };
    const onChangeCountry = (event) => {
        setCountry(event.target.value);
    };
    const showFilterMenu = () => {
        setFilterMenuOpen(true);
    };
    const hideFilterMenu = () => {
        setFilterMenuOpen(false);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <BackButton/>
            </Grid>
            {available === 1 ? (
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography variant={"h5"} component={"h1"}>
                            <Translate text={"Music Live Trends"}/>
                        </Typography>
                    </Grid>
                    {loading ? (
                        <Grid item xs={12}>
                            <LoadingBar/>
                        </Grid>
                    ) : ""}
                    {error === null ? (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <FilterMenu
                                    filterMenuOpen={filterMenuOpen}
                                    format={format}
                                    onChangeFormat={onChangeFormat}
                                    startDate={startDate}
                                    onChangeStartDate={onChangeStartDate}
                                    endDate={endDate}
                                    onChangeEndDate={onChangeEndDate}
                                    filterValues={filterValues}
                                    artist={artist}
                                    onChangeArtist={onChangeArtist}
                                    track={track}
                                    onChangeTrack={onChangeTrack}
                                    bundle={bundle}
                                    onChangeBundle={onChangeBundle}
                                    shop={shop}
                                    onChangeShop={onChangeShop}
                                    country={country}
                                    onChangeCountry={onChangeCountry}
                                    hideFilterMenu={hideFilterMenu}
                                    showFilterMenu={showFilterMenu}
                                />
                            </Grid>

                            {/* Chart */}
                            <Grid item xs={12} md={8} lg={8}>
                                <Paper className={classes.paper}>
                                    {typeof trend !== "undefined" ? (
                                        <Chart
                                            liveTrend={trend}
                                            graphName={<Translate text="LiveSalesMusic"/>}
                                        />
                                    ) : (
                                        <Translate text="NoDataAvailable"/>
                                    )}
                                </Paper>
                            </Grid>
                            {/* Shops */}
                            {format !== "ugc" && (
                                <Grid item xs={12} md={4} lg={4}>
                                    <Paper className={classes.paper}>
                                        {typeof shops !== "undefined" ? (
                                            <DefaultPieChart
                                                fetchData={shops}
                                                graphName={<Translate text="Shops"/>}
                                            />
                                        ) : (
                                            <Translate text="NoDataAvailable"/>
                                        )}
                                    </Paper>
                                </Grid>
                            )}
                            {/* Countries */}
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                <Paper className={classes.paper}>
                                    {typeof countries !== "undefined" ? (
                                        <TinyBar
                                            graphName={<Translate text="Countries"/>}
                                            fetchData={countries}
                                        />
                                    ) : (
                                        <Translate text="NoDataAvailable"/>
                                    )}
                                </Paper>
                            </Grid>
                            {format === "streaming" ? (
                                <React.Fragment>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <Paper className={classes.paper}>
                                            {typeof devices !== "undefined" ? (
                                                <DefaultPieChart
                                                    fetchData={devices}
                                                    graphName={<Translate text="Devices"/>}
                                                />
                                            ) : (
                                                <Translate text="NoDataAvailable"/>
                                            )}
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <Paper className={classes.paper}>
                                            {typeof sources !== "undefined" ? (
                                                <DefaultPieChart
                                                    fetchData={sources}
                                                    graphName={<Translate text="Sources"/>}
                                                />
                                            ) : (
                                                <Translate text="NoDataAvailable"/>
                                            )}
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <Paper className={classes.paper}>
                                            {typeof memberships !== "undefined" ? (
                                                <DefaultPieChart
                                                    fetchData={memberships}
                                                    graphName={<Translate text="Memberships"/>}
                                                />
                                            ) : (
                                                <Translate text="NoDataAvailable"/>
                                            )}
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <Paper className={classes.paper}>
                                            {typeof operatingSystem !== "undefined" ? (
                                                <DefaultPieChart
                                                    fetchData={operatingSystem}
                                                    graphName={<Translate text="OperatingSystem"/>}
                                                />
                                            ) : (
                                                <Translate text="NoDataAvailable"/>
                                            )}
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <Paper className={classes.paper}>
                                            {typeof gender !== "undefined" ? (
                                                <DefaultPieChart
                                                    fetchData={gender}
                                                    graphName={<Translate text="Gender"/>}
                                                />
                                            ) : (
                                                <Translate text="NoDataAvailable"/>
                                            )}
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <Paper className={classes.paper}>
                                            {typeof ageGroup !== "undefined" ? (
                                                <DefaultPieChart
                                                    fetchData={ageGroup}
                                                    graphName={<Translate text="AgeGroup"/>}
                                                />
                                            ) : (
                                                <Translate text="NoDataAvailable"/>
                                            )}
                                        </Paper>
                                    </Grid>
                                </React.Fragment>
                            ) : ""}
                            <Grid item xs={12} md={8} lg={8}>
                                <Paper className={classes.paper}>
                                    {typeof topTracks !== "undefined" ? (
                                        <MusicTopTable
                                            fetchData={topTracks}
                                            type={"tracks"}
                                            title={<Translate text="TopTracks"/>}
                                        />
                                    ) : (
                                        <Translate text="NoDataAvailable"/>
                                    )}
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <Paper className={classes.paper}>
                                    {typeof topArtists !== "undefined" ? (
                                        <MusicTopTable
                                            fetchData={topArtists}
                                            type={"artist"}
                                            title={<Translate text="TopArtists"/>}
                                        />
                                    ) : (
                                        <Translate text="NoDataAvailable"/>
                                    )}
                                </Paper>
                            </Grid>
                        </React.Fragment>
                    ) : (
                        <Grid item xs={12}>
                            <ErrorBox error={error}/>
                        </Grid>
                    )}
                </React.Fragment>
            ) : (
                <Grid item xs={12}>
                    {available === 0 && <LoadingBar/>}
                    {available === 2 && <Alert severity="info"><Translate text="SalesRefreshMessage"/></Alert>}
                    {available === 3 && <Alert severity="error"><Translate text="SalesPreparationError"/></Alert>}
                    {available === 4 && <Alert severity="info"><Translate text="Prepare Data Text"/></Alert>}
                </Grid>
            )}
        </Grid>
    );
}