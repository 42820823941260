import parse from 'html-react-parser';
import React from "react";

export default function FormattedTextParser(text, nl2br = false, link = true) {
    text = text
        .replace(/\[bold\]/g, "<b>").replace(/\[\/bold\]/g, "</b>")
        .replace(/\[strong\]/g, "<strong>").replace(/\[\/strong\]/g, "</strong>")
        .replace(/\[highlite\]/g, "<span class='fc-orange'>").replace(/\[\/highlite\]/g, "</span>")
        .replace(/#ORDERED_LIST#/g, "<ol>").replace(/#END_ORDERED_LIST#/gi, "</ol>")
        .replace(/#UNORDERED_LIST#/gi, "<ul>").replace(/#END_UNORDERED_LIST#/gi, "</ul>")
        .replace(/#LIST_ITEM#/g, "<li>").replace(/#END_LIST_ITEM#/g, "</li>")
        .replace(/\[headline1\]/gi, "<h1>").replace(/\[\/headline1\]/gi, "<h1>")
        .replace(/\[headline2\]/gi, "<h2>").replace(/\[\/headline2\]/gi, "<h2>")
        .replace(/\[headline3\]/gi, "<h3>").replace(/\[\/headline3\]/gi, "<h3>")
        .replace(/\[headline4\]/gi, "<h4>").replace(/\[\/headline4\]/gi, "<h4>")
        .replace(/\[headline5\]/gi, "<h5>").replace(/\[\/headline5\]/gi, "<h5>")
        .replace(/\[headline6\]/gi, "<h6>").replace(/\[\/headline6\]/gi, "<h6>")
        .replace(/#COLOR#/gi, "<span style='color: ").replace(/#\/COLOR#/gi, ";'>").replace(/#END_COLOR#/gi, "</span>")
        .replace(/#RED#/gi, "<span style='color: red;'>").replace(/#END_RED#/gi, "</span>")
        .replace(/#BOLD#/gi, "<b>").replace(/#END_BOLD#/gi, "</b>")
        .replace(/#UNDERLINE#/gi, "<span style='text-decoration: underline;'>").replace(/#END_UNDERLINE#/gi, "</span>")
        .replace(/#LINK#/gi, "<a style='color: #f5a200;' href='")
        .replace(/#LINK_NEW_WINDOW#/gi, "<a style='color: #f5a200;' target='_blank' href='")
        .replace(/#\/LINK#/gi, "'>")
        .replace(/#\/LINK_NEW_WINDOW#/gi, "'>")
        .replace(/#END_LINK#/gi, "</a>");
    if (nl2br) {
        text = text
            .replace(/\r\n/g, "<br/>")
            .replace(/\n/g, "<br/>");
    }
    if (link) {
        return parse(text);
    }
    else {
        return parse(text, {
            replace: (element) => {
                if (element.name === "a" && element.children.length === 1) {
                    return <>{element.children[0].data}</>;
                }
                return element;
            }
        });
    }
}