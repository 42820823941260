import React, {useEffect, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import ListSubheader from "@mui/material/ListSubheader";
import {Translate} from "../../Translate";
import List from "@mui/material/List";
import * as rssParser from "react-native-rss-parser";

import ListItems from './List'

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
    },
}));

export default function BlogList({blogType, lang}) {
    const [data, setData] = useState(false);
    const classes = useStyles();

    const getBlogEntries = (type, lang) => {
        return fetch(`https://blog.feiyr.com/${lang}/category/${type}/feed/`)
            .then((response) => response.text())
            .then((responseData) => rssParser.parse(responseData))
            .then((rss) => {
                return rss.items;
            })
            .catch((e) => console.error(e));
    };

    useEffect(() => {
        async function getFeed(blogType) {
            const blogData = await getBlogEntries(blogType, lang);
            if (blogData !== undefined && blogData !== null) {
                setData(blogData);
            }
        }
        blogType !== "" && getFeed(blogType);
    }, [blogType, lang]);

    const blogTypeFormat = blogType.charAt(0).toUpperCase() + blogType.slice(1);

    return (
        <List
            className={classes.root}
            subheader={
                <ListSubheader>
                    <Translate text={`${blogTypeFormat}Blog`}/>
                </ListSubheader>
            }
        >
            {data === false ? (
                <Translate text="Loading"/>
            ) : (
                <ListItems data={data} blogType={blogType} classes={classes}/>
            )}
        </List>
    );
}
