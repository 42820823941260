import React, {useEffect, useState} from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Copyright from "./Copyright";
import BlogList from "./blog/BlogList";
import SystemAccordion from "./accordion/SystemAccordion";
import Title from "../template/Title";
import {Translate} from "../Translate";
import Typography from "@mui/material/Typography";
import LoadingBar from "../template/LoadingBar";
import {Link} from "react-router-dom";
import {FeiyrApi} from "../../js/FeiyrApi";
import ErrorBox from "../ErrorBox";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Cookies from "js-cookie";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    doubleHeight: {
        height: "100%",
    },
    fixedHeight: {
        height: "100%",
    },
    depositContext: {
        flex: 0,
    },
}));

export default function Dashboard(props) {
    const [error, setError] = useState(null);

    const [mfaEnabled, setMfaEnabled] = useState(true);
    const [backupCodesGenerated, setBackupCodesGenerated] = useState(true);
    const [mfaNoticesDisabled, setMfaNoticesDisabled] = useState(Cookies.get("mfaNoticesDisabled") !== undefined);
    const [systemNews, setSystemNews] = useState([]);
    const [balance, setBalance] = useState(0);
    const [formattedBalance, setFormattedBalance] = useState("0.0");

    const classes = useStyles();
    const doubleHeightPaper = clsx(classes.paper, classes.doubleHeight);
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const lang =
        localStorage.getItem("lang") !== null
            ? localStorage.getItem("lang")
            : navigator.language.slice(0, 2) || navigator.userLanguage.slice(0, 2);

    const disableMfaNotices = function() {
        Cookies.set("mfaNoticesDisabled", 1, {expires: 30});
        setMfaNoticesDisabled(true);
    };

    useEffect(() => {
        if (props.userId.userId !== 0) {
            const lang = localStorage.getItem("lang") !== null
                ? localStorage.getItem("lang")
                : navigator.language.slice(0, 2) || navigator.userLanguage.slice(0, 2);
            let feiyrApi = new FeiyrApi();
            feiyrApi.get("/Systemnews/latestcontent", {lang: lang})
                .then((res) => {
                    if (res !== undefined && (res.success === true || res.success === "true")) {
                        setSystemNews(res.data);
                    }
                    else {
                        setError({systemNews: {userMessage: "Error fetching system news"}});
                    }
                })
                .catch((err) => {
                    err.userMessage = "Error fetching system news";
                    setError({systemNews: err});
                });
            feiyrApi.get("/User/Balance/" + localStorage.getItem("userId"))
                .then((res) => {
                    if (res !== undefined && (res.success === true || res.success === "true")) {
                        setBalance(res.balance);
                        let accountBalance = new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                        }).format(res.balance);
                        setFormattedBalance(accountBalance);
                    }
                    else {
                        setError({balance: {userMessage: "Error fetching balance"}});
                    }
                })
                .catch((err) => {
                    err.userMessage = "Error fetching balance";
                    setError({balance: err});
                });
            feiyrApi.get("/User/MfaEnabled/"+localStorage.getItem("userId"))
                .then(res => {
                    if (res!==undefined) {
                        if(res.success===true&&res.enabled===false) {
                            setMfaEnabled(false);
                        } else {
                            feiyrApi.get("/User/BackupCodesGenerated/"+localStorage.getItem("userId"))
                                .then(res => {
                                    if (res!==undefined&&res.success===true&&res.generated===false) {
                                        setBackupCodesGenerated(false);
                                    }
                                })
                                .catch(err => console.log(err));
                        }
                    }
                })
                .catch(err => console.log(err))
        }
    }, [props.userId]);

    return (
        <React.Fragment>
            <div/>
            <Grid container spacing={3}>
                {!mfaNoticesDisabled && mfaEnabled === false && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Alert severity="warning" onClose={() => disableMfaNotices()}>
                            <Translate text={"MfaNotEnabled"}/>
                        </Alert>
                    </Grid>
                )}
                {!mfaNoticesDisabled && backupCodesGenerated === false && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Alert severity="warning" onClose={() => disableMfaNotices()}>
                            <Translate text={"BackupCodesNotGenerated"}/>
                        </Alert>
                    </Grid>
                )}
                {/* Account Balance */}
                <Grid item xs={12} md={4} lg={4}>
                    <Paper className={fixedHeightPaper}>
                        <Title>
                            <Translate text={"Account_Balance"}/>
                        </Title>
                        {error === null || error.balance === undefined ? (
                            <React.Fragment>
                                <Typography component={"span"} variant="h4">
                                    {balance !== 0 ? (formattedBalance) : (<LoadingBar/>)}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    className={classes.depositContext}
                                    component={"span"}
                                >
                                    <Translate text="on"/> {props.date}
                                </Typography>
                                <div style={{alignSelf: "flex-end", height: '100%'}}>
                                    <Button component={Link} color="primary" to="/statements"
                                            style={{marginTop: '50%'}}>
                                        <Translate text={"ViewMore"}/>
                                    </Button>
                                </div>
                            </React.Fragment>
                        ) : (
                            <ErrorBox error={error.balance}/>
                        )}
                    </Paper>
                </Grid>
                {/* SystemNews */}
                <Grid item xs={12} s={12} md={8} lg={8}>
                    <Paper className={fixedHeightPaper}>
                        {error === null || error.systemNews === undefined ? (
                            <React.Fragment>
                                <Title>
                                    <Translate text="SystemNews"/>
                                </Title>
                                <SystemAccordion systemNews={systemNews}/>
                            </React.Fragment>
                        ) : (
                            <ErrorBox error={error.systemNews}/>
                        )}
                    </Paper>
                </Grid>
                {/* Feiyr Blog */}
                <Grid item xs={12} s={6} md={6} lg={6}>
                    <Paper className={doubleHeightPaper}>
                        <BlogList
                            blogType={lang === "de" ? "musik" : "music"}
                            lang={lang}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} s={6} md={6} lg={6}>
                    <Paper className={doubleHeightPaper}>
                        <BlogList
                            blogType={lang === "de" ? "buch" : "book"}
                            lang={lang}
                        />
                    </Paper>
                </Grid>
            </Grid>
            {/* Copyright */}
            <Box pt={4}>
                <Copyright/>
            </Box>
        </React.Fragment>
    );
}
