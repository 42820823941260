import React, {useCallback, useEffect, useState} from "react";
import useTheme from "@mui/material/styles/useTheme";
import {Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis,} from "recharts";
import Title from "../template/Title";
import {Translate} from "../Translate";

// Generate Sales Data
function createData(time, amount) {
    let dateConverter = new Date(time);
    let timeStampToDate = dateConverter
        .toDateString()
        .slice(4, dateConverter.length)
        .split(" ");
    let date = `${timeStampToDate[1]}. ${timeStampToDate[0]} ${timeStampToDate[2]}`;
    return {date, amount};
}

export default function Chart({graphName, YAxisLabel, liveTrend}) {
    const [data, setData] = useState([]);
    const theme = useTheme();

    const result = useCallback(() => {
        let result = [];
        if (typeof liveTrend !== "undefined") {
            if (liveTrend.length > 0) {
                for (const value of Object.values(liveTrend)) {
                    result.push(createData(value[0], value[1]));
                }
            }
        }
        return result;
    }, [liveTrend]);

    useEffect(() => {
        setData(result);
    }, [liveTrend, result]);

    return (
        <React.Fragment>
            <Title>{graphName}</Title>
            {data.length > 0 ? (
                <ResponsiveContainer height={220} width="100%">
                    <LineChart
                        data={data}
                        margin={{
                            top: 16,
                            right: 16,
                            bottom: 0,
                            left: 0,
                        }}
                    >
                        <XAxis
                            dataKey="date"
                            stroke={theme.palette.text.secondary}
                            minTickGap={50}
                            interval="preserveStartEnd"
                        />
                        <YAxis stroke={theme.palette.text.secondary}>
                            <Label
                                angle={270}
                                position="left"
                                style={{
                                    textAnchor: "middle",
                                    fill: theme.palette.text.primary,
                                }}
                            >
                                {YAxisLabel}
                            </Label>
                        </YAxis>
                        <Tooltip/>
                        <Line
                            type="monotone"
                            dataKey="amount"
                            stroke={theme.palette.primary.main}
                            dot={false}
                        />
                    </LineChart>
                </ResponsiveContainer>
            ) : (
                <Translate text="NoDataAvailable"/>
            )}
        </React.Fragment>
    );
}
