import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {doTranslate, Translate} from "../../Translate";
import IconButton from "@mui/material/IconButton";
import Remove from "@mui/icons-material/Remove";
import Add from "@mui/icons-material/Add";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

export default function OrderRequestArticle(props) {
    return (
        <React.Fragment>
            <Grid item xs={6}>
                <FormControl style={{width: "100%"}} variant="outlined">
                    <InputLabel id="article-select-label">
                        <Translate text={"Article"}/>
                    </InputLabel>
                    <Select
                        labelId="article-select-label"
                        label={<Translate text={"Article"}/>}
                        id="article-select"
                        value={props.articleId}
                        onChange={event => props.setArticle(event.target.value, props.quantity)}
                    >
                        <MenuItem key={0} value={0}>
                            <Translate text={"Please select"}/>
                        </MenuItem>
                        {props.articleList.map(article => {
                            return (
                                <MenuItem key={article.article_id} value={article.article_id}>
                                    {article.artist} - {article.title}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    type="number"
                    style={{width: "100%"}}
                    required
                    id={"product-" + props.index}
                    label={doTranslate("Quantity")}
                    onChange={event => props.setArticle(props.articleId, event.target.value)}
                    value={props.quantity}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={2}>
                {props.removable && (
                    <IconButton edge="end" color="inherit" onClick={() => props.onRemove(props.articleId)}>
                        <Remove/>
                    </IconButton>
                )}
                <IconButton edge="end" color="inherit" onClick={() => props.onAdd()}>
                    <Add/>
                </IconButton>
            </Grid>
        </React.Fragment>
    );
}