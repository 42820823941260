import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import React from "react";
import {Translate} from "./Translate";

export function ConfirmDialog(props) {
    return (
        <Dialog
            open={props.open}
            keepMounted
            onClose={props.onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {props.text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onDeny} color="secondary">
                    <Translate text="Close"/>
                </Button>
                <Button onClick={props.onAccept} color="primary">
                    <Translate text="Confirm"/>
                </Button>
            </DialogActions>
        </Dialog>
    );
}