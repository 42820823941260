import React from "react";
import Title from "../template/Title";
import {Translate} from "../Translate";
import {Bar, BarChart, Label, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import makeStyles from "@mui/styles/makeStyles";
import useTheme from "@mui/material/styles/useTheme";

const useStyles = makeStyles(theme => ({
    axis: {
        fill: theme.palette.text.primary
    },
    label: {
        textAnchor: "middle",
        fill: theme.palette.text.primary
    },
    tooltip: {
        border: "1px solid rgba(0, 0, 0, 0.25)",
        backgroundColor: theme.palette.background.default
    }
}));

export default function TinyBar({fetchData, graphName}) {
    const theme = useTheme();
    const classes = useStyles();
    let data = [];
    let sum = 0;
    if (typeof fetchData !== "undefined") {
        Object.entries(fetchData).forEach((el) => {
            sum += el[1][1];
        });
        data = Object.entries(fetchData).map((el, i) => {
            return {
                name: el[1][0],
                value: Number(((el[1][1] * 100) / sum).toFixed(2)),
            };
        });
    }

    return (
        <React.Fragment>
            <Title>{graphName}</Title>
            {typeof fetchData !== "undefined" && data.length > 0 ? (
                <ResponsiveContainer height={220} width="100%">
                    <BarChart
                        margin={{left: 50}}
                        width={100}
                        height={80}
                        data={data.slice(0, 5)}
                        layout="vertical"
                    >
                        <Tooltip content={<CustomTooltip/>}/>
                        <XAxis type="number" ticks={[0, 20, 40, 60, 80, 100]} stroke={theme.palette.text.primary}/>
                        <YAxis dataKey="name" type="category" stroke={theme.palette.text.primary}/>
                        <Bar dataKey="value" fill="#8884d8"/>

                        <Label
                            angle={270}
                            position="bottom"
                            className={classes.label}
                        />
                    </BarChart>
                </ResponsiveContainer>
            ) : (
                <Translate text="NoDataAvailable"/>
            )}
        </React.Fragment>
    );
}

function CustomTooltip({payload, label, active}) {
    const classes = useStyles();
    if (active && payload && payload.length) {
        const labelStyle = {
            padding: "0 10px"
        };
        return (
            <div className={classes.tooltip}>
                <p className="label" style={labelStyle}>{`${label}: ${payload[0].value}%`}</p>
            </div>
        );
    }
    return null;
}
