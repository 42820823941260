import React, {useEffect, useState} from "react";
import {FeiyrApi} from "../../js/FeiyrApi";
import LoadingBar from "../template/LoadingBar";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import {doTranslate, Translate} from "../Translate";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import NewOrExisting from "./NewOrExisting";
import List from "@mui/material/List";
import Send from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {getSpotifyUri, isValidSpotifyUri, isValidURL} from "../../js/URL";

export default function MyProductIncorrect(props) {
    const [shopLinkValidationError, setShopLinkValidationError] = useState("");

    const [bundlesLoading, setBundlesLoading] = useState(false);
    const [contributorsLoading, setContributorsLoading] = useState(false);

    const [bundles, setBundles] = useState({});
    const [contributors, setContributors] = useState({});

    useEffect(() => {
        setBundlesLoading(true);
        new FeiyrApi().get("/ArtistProfile/Bundles/" + localStorage.getItem("userId") + "/" + props.partnerShopId)
            .then(res => {
                setBundlesLoading(false);
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    setBundles(res.data);
                }
                else {
                    props.setError({userMessage: "Error fetching releases"});
                }
            })
            .catch(err => {
                setBundlesLoading(false);
                err.userMessage = "Error fetching releases";
                props.setError(err);
            });
    }, [props.partnerShopId]);

    useEffect(() => {
        if (props.bundleId > 0) {
            setContributorsLoading(true);
            new FeiyrApi().get("/ArtistProfile/Contributors/" + localStorage.getItem("userId"), {bundleIds: [props.bundleId]})
                .then(res => {
                    setContributorsLoading(false);
                    if (res !== undefined && (res.success === true || res.success === "true")) {
                        setContributors(res.data);
                    }
                    else {
                        props.setError({userMessage: "Error fetching contributors"});
                    }
                })
                .catch(err => {
                    setContributorsLoading(false);
                    err.userMessage = "Error fetching contributors";
                    props.setError(err);
                });
        }
    }, [props.bundleId]);

    const onChangeShopLink = (event) => {
        let value = event.target.value;
        if (props.partnerShopId === 158) {
            value = getSpotifyUri(value, "album");
            if (!isValidSpotifyUri(value, "album")) {
                setShopLinkValidationError(doTranslate("Invalid Spotify URI"));
            }
            else {
                setShopLinkValidationError("");
            }
        }
        else if (!isValidURL(value)) {
            setShopLinkValidationError(doTranslate("Invalid URI"));
        }
        else {
            setShopLinkValidationError("");
        }
        props.setShopLink(value);
    };

    return (
        <React.Fragment>
            {bundlesLoading ? (
                <Grid item xs={12}>
                    <LoadingBar/>
                </Grid>
            ) : (
                <React.Fragment>
                    <Grid item xs={12}>
                        <FormControl style={{width: "100%"}} variant="outlined">
                            <InputLabel id="bundle-select-label">
                                <Translate text={"Release"}/>
                            </InputLabel>
                            <Select
                                labelId="bundle-select-label"
                                label={<Translate text={"Release"}/>}
                                id="bundle-select"
                                value={props.bundleId}
                                onChange={event => props.setBundleId(parseInt(event.target.value))}
                            >
                                <MenuItem key={0} value={0}>
                                    <Translate text={"Please select"}/>
                                </MenuItem>
                                {Object.entries(bundles).map(bundle => {
                                    return (
                                        <MenuItem key={bundle[0]} value={bundle[0]}>
                                            {bundle[1]}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            style={{width: "100%"}}
                            required
                            id="shop-link"
                            label={doTranslate("Shop Link")}
                            onChange={onChangeShopLink}
                            value={props.shopLink}
                            variant="outlined"
                            error={shopLinkValidationError !== ""}
                            helperText={shopLinkValidationError}
                        />
                    </Grid>
                    {contributorsLoading ? (
                        <Grid item xs={12}>
                            <LoadingBar/>
                        </Grid>
                    ) : (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <Typography variant="h6" component="h2">
                                    <Translate text="Contributors"/>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <List>
                                    {Object.entries(contributors).map(entry => (
                                        <NewOrExisting
                                            partnerShopId={props.partnerShopId}
                                            contributorId={entry[0]}
                                            contributorName={entry[1]}
                                            contributorData={props.contributorData}
                                            setContributorData={props.setContributorData}
                                        />
                                    ))}
                                </List>
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit" size="large" startIcon={<Send/>}>
                            <Translate text={"Submit"}/>
                        </Button>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}