import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {Link} from "react-router-dom";
import {Translate} from "../Translate";

export const MenuItem = ({url, value, text, icon, external}) => {
    const iconBody = (
        <>
            <ListItemIcon>{icon}</ListItemIcon>{" "}
            <ListItemText primary={<Translate text={text}/>}/>
        </>
    );
    if (!external) {
        return (
            <ListItem button component={Link} to={url} value={value}>
                {iconBody}
            </ListItem>
        );
    }
    if (external) {
        return (
            <ListItem button component="a" target="_blank" href={url} value={value}>
                {iconBody}
            </ListItem>
        );
    }
};
