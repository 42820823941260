import React, {useEffect, useState} from "react";
import ErrorBox from "../../ErrorBox";
import LoadingBar from "../../template/LoadingBar";
import {doTranslate, Translate} from "../../Translate";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Send from "@mui/icons-material/Send";
import Typography from "@mui/material/Typography";
import OrderRequestArticle from "./OrderRequestArticle";
import {FeiyrApi} from "../../../js/FeiyrApi";

export default function OrderRequestSpecial(props) {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);

    const [articleList, setArticleList] = useState([]);
    const [articles, setArticles] = useState([{articleId: "0", quantity: 0}]);
    const [note, setNote] = useState("");

    useEffect(() => {
        new FeiyrApi().get("/Nova/ArticleList/" + localStorage.getItem("userId"))
            .then(res => {
                if (res !== undefined && res.success !== undefined && res.success === true) {
                    setArticleList(res.data);
                    setLoading(false);
                }
                else {
                    setError({userMessage: "Error fetching articles"});
                }
            })
            .catch(err => {
                err.userMessage = "Error fetching articles";
                setError(err);
            });
    }, []);

    const setArticle = (index, articleId, quantity) => {
        setArticles(prev => {
            let tmp = JSON.parse(JSON.stringify(prev));
            tmp[index].articleId = articleId;
            tmp[index].quantity = parseInt(quantity);
            return tmp;
        });
    };

    const onAdd = () => {
        setArticles(prev => {
            let tmp = JSON.parse(JSON.stringify(prev));
            tmp.push({articleId: "0", quantity: 0});
            return tmp;
        });
    };

    const onRemove = (index) => {
        setArticles(prev => {
            let tmp = [];
            for(let i=0;i<prev.length;i++){
                if (i !== index) {
                    tmp.push(prev[i]);
                }
            }
            return tmp;
        });
    };

    const onNoteChange = event => {
        setNote(event.target.value);
    }

    const onFormSubmit = event => {
        event.preventDefault();
        event.stopPropagation();
        setLoading(true);
        const body = {
            articles: {},
            note: note
        };
        articles.forEach(article => body.articles[article.articleId] = article.quantity);
        new FeiyrApi().post("/OrderRequests/SpecialRequest/" + localStorage.getItem("userId"), body)
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    setLoading(false);
                    setSuccess(true);
                }
                else {
                    setError({userMessage: "Error submitting request"});
                }
            })
            .catch(err => {
                err.userMessage = "Error submitting request";
                setError(err);
            });
    };

    const renderArticleList = () => {
        let data = [];
        for (let i=0; i<articles.length; i++) {
            data.push((
                <OrderRequestArticle
                    articleList={articleList}
                    articleId={articles[i].articleId}
                    quantity={articles[i].quantity}
                    setArticle={(articleId, quantity) => setArticle(i, articleId, quantity)}
                    onRemove={() => onRemove(i)}
                    onAdd={() => onAdd()}
                    removable={i > 0}
                />
            ));
        }
        return data;
    };

    return (
        <React.Fragment>
            {error === null ? (
                <React.Fragment>
                    {loading === false ? (
                        <React.Fragment>
                            {success === false ? (
                                <React.Fragment>
                                    <form onSubmit={onFormSubmit} style={{width: "100%"}}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Typography variant={"h5"} component={"h1"}>
                                                    <Translate text={"Special Order Request"}/>
                                                </Typography>
                                            </Grid>
                                            {renderArticleList()}
                                            <Grid item xs={12}>
                                                <TextField
                                                    style={{width: "100%"}}
                                                    id="order-request-note"
                                                    label={doTranslate("Note")}
                                                    multiline
                                                    rows={5}
                                                    maxRows={100}
                                                    onChange={onNoteChange}
                                                    value={note}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={6}/>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" type="submit"
                                                        startIcon={<Send/>} fullWidth={true}>
                                                    <Translate text={"Request"}/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </React.Fragment>
                            ) : (
                                <Alert severity="success">
                                    <Translate text="OrderRequestSpecialSuccess"/>
                                </Alert>
                            )}
                        </React.Fragment>
                    ) : <LoadingBar/>}
                </React.Fragment>
            ) : (
                <ErrorBox error={error}/>
            )}
        </React.Fragment>
    );
}