import React, {useEffect, useState} from "react";
import ErrorBox from "../ErrorBox";
import LoadingBar from "../template/LoadingBar";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import {Translate} from "../Translate";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {FeiyrApi} from "../../js/FeiyrApi";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import MyProductIncorrect from "./MyProductIncorrect";
import OtherProductIncorrect from "./OtherProductIncorrect";

export default function ArtistProfiles() {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [partnerShops, setPartnerShops] = useState({});

    // all problem types
    const [partnerShopId, setPartnerShopId] = useState(0);
    const [problemType, setProblemType] = useState(1);
    const [currentArtistProfile, setCurrentArtistProfile] = useState("");
    // my product is listed incorrectly (= type id 1)
    const [bundleId, setBundleId] = useState(0);
    const [shopLink, setShopLink] = useState("");
    const [contributorData, setContributorData] = useState({});
    // other product is listed on my profile (= type id 2)
    const [productLinks, setProductLinks] = useState([""]);

    useEffect(() => {
        new FeiyrApi().get("/ArtistProfile/Stores/" + localStorage.getItem("userId"))
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    let storeNames = [], stores = [];
                    Object.entries(res.data).forEach(store => {
                        storeNames.push(store[1]);
                    });
                    storeNames = storeNames.sort();
                    storeNames.forEach(name => {
                        Object.entries(res.data).forEach(store => {
                            if (name === store[1]) {
                                stores.push(store);
                            }
                        });
                    });
                    setPartnerShops(stores);
                }
                else {
                    setError({userMessage: "Error fetching partner shops"});
                }
            })
            .catch(err => {
                err.userMessage = "Error fetching partner shops";
                setError(err);
            });
    }, []);

    const onProblemTypeChange = (event) => {
        setProblemType(parseInt(event.target.value));
    };

    const onPartnerShopChange = (event) => {
        setPartnerShopId(parseInt(event.target.value));
    };

    const onSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setSubmitting(true);
        const body = {
            type_id: problemType,
            partner_shop_id: partnerShopId,
            artist_profile_current: currentArtistProfile,
            contributors: {},
            artist_profile_existing: {},
        };
        if (body.type_id === 1) {
            let thisShopLink = shopLink;
            body.products = {};
            body.products[bundleId] = thisShopLink;
            Object.keys(contributorData).forEach(contributorId => {
                if (contributorData[contributorId].newProfile) {
                    body.contributors[contributorId] = {
                        "current_profile": contributorData[contributorId]["currentProfile"],
                        "profile_type": "new"
                    };
                    body.artist_profile_existing[contributorId] = "";
                }
                else if (contributorData[contributorId]["existingProfile"] !== undefined && contributorData[contributorId]["existingProfile"] !== "") {
                    body.contributors[contributorId] = {
                        "current_profile": contributorData[contributorId]["currentProfile"],
                        "profile_type": "existing"
                    };
                    body.artist_profile_existing[contributorId] = contributorData[contributorId]["existingProfile"];
                }
            });
        }
        else {
            body.products = productLinks;
        }
        new FeiyrApi().post("/ArtistProfile/" + localStorage.getItem("userId"), body)
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    setSubmitting(false);
                    setSuccess(true);
                }
                else {
                    setError({userMessage: "Error submitting request"});
                }
            })
            .catch(err => {
                err.userMessage = "Error submitting request";
                setError(err);
            });
    };

    return (
        <React.Fragment>
            {error === null ? (
                <Paper style={{width: "100%", padding: "16px", marginBottom: "16px"}}>
                    {submitting ? (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <LoadingBar/>
                            </Grid>
                        </Grid>
                    ) : (
                        <React.Fragment>
                            {success ? (
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Alert severity="success">
                                            <Translate text="ArtistProfileSuccessText"/>
                                        </Alert>
                                    </Grid>
                                </Grid>
                            ) : (
                                <React.Fragment>
                                    {Object.keys(partnerShops).length > 0 ? (
                                        <form onSubmit={onSubmit} style={{width: "100%"}}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5" component="h1">
                                                        <Translate text="Artist Profiles"/>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">
                                                            <Translate text={"Problem Type"}/>
                                                        </FormLabel>
                                                        <RadioGroup
                                                            name="problem-type"
                                                            value={problemType}
                                                            onChange={onProblemTypeChange}
                                                        >
                                                            <FormControlLabel
                                                                value={1}
                                                                control={<Radio/>}
                                                                label={<Translate text="MyProductIncorrect"/>}
                                                            />
                                                            <FormControlLabel
                                                                value={2}
                                                                control={<Radio/>}
                                                                label={<Translate text="OtherProductIncorrect"/>}
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl style={{width: "100%"}} variant="outlined">
                                                        <InputLabel id="partner-shop-select-label">
                                                            <Translate text={"Partner Shop"}/>
                                                        </InputLabel>
                                                        <Select
                                                            labelId="partner-shop-select-label"
                                                            label={<Translate text={"Partner Shop"}/>}
                                                            id="partner-shop-select"
                                                            value={partnerShopId}
                                                            onChange={onPartnerShopChange}
                                                        >
                                                            <MenuItem key={0} value={0}>
                                                                <Translate text={"Please select"}/>
                                                            </MenuItem>
                                                            {partnerShops.map(partnerShop => {
                                                                return (
                                                                    <MenuItem key={partnerShop[0]}
                                                                              value={partnerShop[0]}>
                                                                        {partnerShop[1]}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                {partnerShopId > 0 && problemType > 0 && (
                                                    <React.Fragment>
                                                        {partnerShopId === 62 ? (
                                                            <Grid item xs={12}>
                                                                <Alert severity="info">
                                                                    <Translate text={"AmazonArtistProfileInfoText"}/>
                                                                </Alert>
                                                            </Grid>
                                                        ) : (
                                                            <React.Fragment>
                                                                {problemType === 1 && (
                                                                    <MyProductIncorrect
                                                                        setError={setError}
                                                                        partnerShopId={partnerShopId}
                                                                        bundleId={bundleId}
                                                                        setBundleId={setBundleId}
                                                                        shopLink={shopLink}
                                                                        setShopLink={setShopLink}
                                                                        currentArtistProfile={currentArtistProfile}
                                                                        setCurrentArtistProfile={setCurrentArtistProfile}
                                                                        contributorData={contributorData}
                                                                        setContributorData={setContributorData}
                                                                    />
                                                                )}
                                                                {problemType === 2 && (
                                                                    <OtherProductIncorrect
                                                                        partnerShopId={partnerShopId}
                                                                        currentArtistProfile={currentArtistProfile}
                                                                        setCurrentArtistProfile={setCurrentArtistProfile}
                                                                        productLinks={productLinks}
                                                                        setProductLinks={setProductLinks}
                                                                    />
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </Grid>
                                        </form>
                                    ) : <LoadingBar/>}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </Paper>
            ) : <ErrorBox error={error}/>}
        </React.Fragment>
    );
}