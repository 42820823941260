import React from 'react';
import Emoji from '../../js/Emoji';
import Typography from '@mui/material/Typography';
import packageJson from "../../../package.json"
import {default as ExternalLink} from "@mui/material/Link";
import {Link} from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    link: {
        color: theme.palette.primary.main
    }
}));

export default function Copyright() {
    const classes = useStyles();
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Made with '}
            <Emoji symbol='❤️' label='heart'/>
            {' by '}
            <ExternalLink href="https://www.feiyr.com/">
                Feiyr
            </ExternalLink>
            {' '}
            {new Date().getFullYear()}
            {" - "}
            <Link to="/changes" className={classes.link}>{"v " + packageJson.version}</Link>
        </Typography>
    );
}