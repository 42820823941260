import React, {useEffect, useState} from "react";
import "../../styles/Message.css";
import makeStyles from "@mui/styles/makeStyles";
import Timeline from "@mui/lab/Timeline";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ReplyIcon from "@mui/icons-material/Reply";
import TextField from "@mui/material/TextField";
import {Translate} from "../Translate";
import MessageContainer from "./MessageContainer";
import {FeiyrApi} from "../../js/FeiyrApi";
import LoadingBar from "../template/LoadingBar";
import Grid from "@mui/material/Grid";
import ErrorBox from "../ErrorBox";
import BackButton from "../BackButton";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "6px 32px",
        margin: "6px 0px",
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
    centerText: {
        flex: "0",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "1em",
    },
    textLeft: {
        textAlign: "left",
    },
    dialogue: {
        flex: 0,
    },
}));

export const Message = (props) => {
    const classes = useStyles();
    const threadId = `${props.location.pathname.replace("/inbox/", "")}`;
    const [error, setError] = useState(null);
    const [replyError, setReplyError] = useState(null);
    const [message, setMessage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [replySending, setReplySending] = useState(false);
    const [reply, setReply] = useState("");
    const [replyOpen, setReplyOpen] = useState(false);
    const [replyDisabled, setReplyDisabled] = useState(false);

    const onSubmit = () => {
        if (!replyOpen) {
            setReplyOpen(true);
        }
        else if (reply !== "") {
            setReplySending(true);
            new FeiyrApi().post("/supportmessages/answer/" + localStorage.getItem("userId"), {
                thread_id: threadId,
                content: reply,
                read: 0
            })
                .then(res => {
                    if (res === undefined || res.success === undefined || (res.success !== true && res.success !== "true")) {
                        setReplyError({userMessage: "Error sending message"});
                    }
                })
                .then(() => {
                    let timestamp = new Date(Date.now())
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " ");
                    setMessage(prev => {
                        return [...prev, {
                            from: "User",
                            content: reply,
                            date: timestamp,
                            read: "0",
                            attachment: false,
                            read_date: "",
                            user_action_needed: false,
                        }];
                    });
                    setReply("");
                    setReplyOpen(false);
                    setReplyError(null);
                    setReplySending(false);
                })
                .catch(err => {
                    err.userMessage = "Error sending message";
                    setReplyError(err);
                    setReplySending(false);
                });
        }
        else {
            setReplyError({userMessage: "Empty message content"});
        }
    };

    const onChangeReply = (e) => {
        setReply(e.target.value);
    };

    useEffect(() => {
        setLoading(true);
        new FeiyrApi().get("/SupportMessages/Messages/" + localStorage.getItem("userId") + "/" + threadId)
            .then(res => {
                setLoading(false);
                let data = Object.values(Object.values(res.data[Object.keys(res.data)[0]])[2]);
                if (data.length > 0) {
                    let lastMsg = data[data.length - 1];
                    if (lastMsg.from === "System") {
                        setReplyDisabled(true);
                    }
                }
                setMessage(data);
                setError(null);
            })
            .catch(err => {
                setLoading(false);
                err.userMessage = "Error loading thread";
                setError(err);
            });
    }, [threadId]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <BackButton/>
                <Typography
                    className={classes.centerText}
                    variant="body2"
                    color="textSecondary"
                >
                    <Translate text={"Conversation start"}/>
                </Typography>
                {loading ? (
                    <LoadingBar/>
                ) : (
                    <React.Fragment>
                        {error === null ? (
                            <Timeline align="left" style={{padding: 0}}>
                                {message.map((item, i) => {
                                    return <MessageContainer message={item} key={i}/>;
                                })}
                                {replyError !== null && <ErrorBox error={replyError}/>}
                                {replySending && <LoadingBar/>}
                                {replyOpen && (
                                    <Paper elevation={3} className={classes.paper}>
                                        <Typography variant="h6" component="h1">
                                            <Translate text="Reply"/>
                                        </Typography>
                                        <Typography>
                                            <TextField
                                                id="text"
                                                type="text"
                                                name="reply"
                                                autoFocus={true}
                                                multiline
                                                rows={5}
                                                maxRows={100}
                                                fullWidth={true}
                                                onChange={onChangeReply}
                                                value={reply}
                                                variant="outlined"
                                            />
                                        </Typography>
                                    </Paper>
                                )}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    startIcon={<ReplyIcon/>}
                                    onClick={() => onSubmit()}
                                    disabled={replyDisabled}
                                >
                                    {!replyOpen ? (
                                        <Translate text="ClickToAnswer"/>
                                    ) : (
                                        <Translate text="SendMessage"/>
                                    )}
                                </Button>
                            </Timeline>
                        ) : (
                            <ErrorBox error={error}/>
                        )}
                    </React.Fragment>
                )}
            </Grid>
        </Grid>
    );
};
