import React from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import MenuIcon from "@mui/icons-material/Menu";
import useWindowDimensions from "../../js/windowDimensions";
import Icon from "@mui/material/Icon";
import MobileDrawer from "./MobileDrawer";
import {Translate} from "../Translate";

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginBottom: "1.75rem"
    },
    appBarShift: {
        marginLeft: 240,
        width: `calc(100% - 240px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: theme.palette.text.primary
    },
    menuButtonHidden: {
        display: "none",
    },
    icon: {
        fontSize: "2rem",
        maxWidth: "40px"
    },
    betaBadge: {
        fontSize: ".8rem",
        borderRadius: "10px",
        backgroundColor: "#f5a200",
        padding: "2px 5px",
        marginLeft: "15px"
    },
    separator: {
        flexGrow: 1,
    },
    img: {
        width: "100%"
    },
}));

const TopNav = ({navStatus, handleDrawerChange, appConnected, setAppConnected}) => {
    const {width} = useWindowDimensions();

    const classes = useStyles();

    return (
        <AppBar position={"static"} color={"default"} className={clsx(classes.appBar, navStatus && classes.appBarShift)}>
            <Toolbar>
                {width > 1024 && (
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => handleDrawerChange(true)}
                        className={clsx(
                            classes.menuButton,
                            navStatus && classes.menuButtonHidden
                        )}
                    >
                        <MenuIcon/>
                    </IconButton>
                )}
                <Icon edge="start" className={classes.icon}>
                    <img src={"/img/feiyrIcons/feiyr.png"} className={classes.img} alt={"icon"}/>
                </Icon>
                <span className={classes.betaBadge}>
                    <Translate text="Beta"/>
                </span>
                <span className={classes.separator}/>

                {width < 1024 && <MobileDrawer appConnected={appConnected} setAppConnected={setAppConnected}/>}
            </Toolbar>
        </AppBar>
    );
};

export default TopNav;
