import Grid from "@mui/material/Grid";
import {Translate} from "../Translate";
import React, {useEffect, useState} from "react";
import ErrorBox from "../ErrorBox";
import {FeiyrApi} from "../../js/FeiyrApi";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

export default function AdminInCharge() {
    const [error, setError] = useState(null);
    const [adminInCharge, setAdminInCharge] = useState(null);

    useEffect(() => {
        new FeiyrApi().get("/User/Data/" + localStorage.getItem("userId"))
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    if (res.data.adminInCharge !== null && res.data.adminInChargeImage !== null) {
                        setAdminInCharge({
                            name: res.data.adminInCharge,
                            image: res.data.adminInChargeImage
                        });
                    }
                }
                else {
                    setError({userMessage: "Unable to fetch user data"});
                }
            })
            .catch(err => {
                err.userMessage = "Unable to fetch user data";
                setError(err);
            });
    }, []);
    return (
        <React.Fragment>
            {error === null ? (
                <React.Fragment>
                    {adminInCharge !== null && (
                        <Paper style={{width: "100%", padding: "16px", marginBottom: "16px"}}>
                            <Grid container spacing={3}>
                                <Grid item xs={3} lg={1}>
                                    <img src={adminInCharge.image} alt={adminInCharge.name}
                                         style={{width: "100%", height: "auto"}}/>
                                </Grid>
                                <Grid item xs={9} lg={11}>
                                    <Typography variant="h6" gutterBottom>
                                        <Translate text="Your contact person"/>
                                    </Typography>
                                    {adminInCharge.name}
                                </Grid>
                            </Grid>
                        </Paper>
                    )}
                </React.Fragment>
            ) : (
                <ErrorBox error={error}/>
            )}
        </React.Fragment>
    );
}