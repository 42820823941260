import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import LogoutIcon from "../LogoutIcon";
import ListItemText from "@mui/material/ListItemText";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {Link} from "react-router-dom";
import {Translate} from "../Translate";
import {postRevokeToken} from "../../js/FeiyrApi";

export const Logout = (props) => {

    const handleLogout = (event) => {
        event.preventDefault();
        event.stopPropagation();
        postRevokeToken()
            .then(() => {
                localStorage.clear();
                props.setAppConnected(false);
            })
            .catch(err => {
                console.error(err);
            });
    };

    return (
        <React.Fragment>
            {props.appConnected ? (
                <ListItem
                    button
                    onClick={event => handleLogout(event)}
                    value="logout"
                    component={Link}
                    to="/logout"
                >
                    <ListItemIcon>
                        <LogoutIcon/>
                    </ListItemIcon>
                    <ListItemText primary={<Translate text="Logout"/>}/>
                </ListItem>
            ) : (
                <ListItem
                    button
                    component="a"
                    href={`${process.env.REACT_APP_URL}/authorize?response_type=code&client_id=${process.env.REACT_APP_OAUTH_CLIENT_ID}&state=${process.env.REACT_APP_OAUTH_CLIENT_SECRET}`}
                    value="login"
                >
                    <ListItemIcon>
                        <ExitToAppIcon/>
                    </ListItemIcon>
                    <ListItemText primary={<Translate text="Login"/>}/>
                </ListItem>
            )}
        </React.Fragment>
    );
};
