import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import {Translate} from "../Translate";
import React, {useEffect, useState} from "react";
import LoadingBar from "../template/LoadingBar";
import {FeiyrApi} from "../../js/FeiyrApi";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import parse from 'html-react-parser';

export default function Employees() {
    const [employees, setEmployees] = useState({});

    useEffect(() => {
        new FeiyrApi().get("/Employees", {forWebApp: 1})
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    let data = [];
                    Object.entries(res.data).forEach(entry => {
                        let name = entry[1].name;
                        let workers = Object.entries(entry[1].employees).map(entry => {
                            return (
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={3}>
                                            <img
                                                src={process.env.REACT_APP_URL + "/img/employees/" + entry[1].image}
                                                alt={entry[1].name.trim()}
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = "https://accounts.feiyr.com/img/icons/no_image2.png";
                                                }}
                                                style={{width: "100%"}}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography variant={"subtitle1"}>{entry[1].name.trim()}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        });
                        data.push(
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant={"h6"} component={"h3"}>
                                        {parse(name)}
                                    </Typography>
                                </Grid>
                                {workers}
                            </Grid>
                        );
                    });
                    setEmployees(data);
                }
            })
            .catch(e => console.error(e));
    }, []);

    return (
        <Paper style={{width: "100%", padding: "16px", marginBottom: "16px"}}>
            {Object.keys(employees).length > 0 ? (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" component={"h2"}>
                            <Translate text={"Our Team"}/>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {employees}
                    </Grid>
                </Grid>
            ) : <LoadingBar/>}
        </Paper>
    );
}