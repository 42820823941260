import Cookies from "js-cookie";

export class FeiyrApi {

    async get(endpoint, params, dataTable) {
        if (typeof Cookies.get("token" + process.env.REACT_APP_COOKIE_VERSION) !== "undefined") {
            await postRefreshToken(JSON.parse(Cookies.get("token" + process.env.REACT_APP_COOKIE_VERSION)).refresh_token);
        }
        else {
            await postRefreshToken();
        }
        params = this.addParams(params);
        let url = process.env.REACT_APP_API_URL;
        if (endpoint.substr(0, 1) !== "/") {
            url += "/";
        }
        url += endpoint + ".json";
        url += "?" + this.serializeObject(params);
        if (dataTable !== undefined && dataTable !== null) {
            url += "&" + dataTable.toString();
        }
        let requestOptions = {
            method: "GET",
            headers: this.getHeaders(),
            redirect: "follow",
        };
        return fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => result)
            .catch((error) => console.error(error));
    }

    async post(endpoint, data) {
        if (typeof Cookies.get("token" + process.env.REACT_APP_COOKIE_VERSION) !== "undefined") {
            await postRefreshToken(JSON.parse(Cookies.get("token" + process.env.REACT_APP_COOKIE_VERSION)).refresh_token);
        }
        else {
            await postRefreshToken();
        }
        let url = process.env.REACT_APP_API_URL;
        if (endpoint.substr(0, 1) !== "/") {
            url += "/";
        }
        url += endpoint + ".json";
        url += "?key=" + process.env.REACT_APP_FEIYR_API_KEY;
        let payload = {
            data: data
        };
        payload = this.addParams(payload);
        payload = this.serializeObject(payload);
        let headers = this.getHeaders();
        headers.append("Content-Type", "application/x-www-form-urlencoded");
        headers.append("Content-Length", payload.length);
        let requestOptions = {
            method: "POST",
            redirect: "follow",
            headers: headers,
            body: payload,
        };
        return fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => result)
            .catch((error) => console.error(error));
    }

    async put(endpoint, data) {
        if (typeof Cookies.get("token") !== "undefined") {
            await postRefreshToken(JSON.parse(Cookies.get("token")).refresh_token);
        }
        else {
            await postRefreshToken();
        }
        let url = process.env.REACT_APP_API_URL;
        if (endpoint.substr(0, 1) !== "/") {
            url += "/";
        }
        url += endpoint + ".json";
        url += "?key=" + process.env.REACT_APP_FEIYR_API_KEY;
        let payload = {
            data: data
        };
        payload = this.addParams(payload);
        payload = this.serializeObject(payload);
        let headers = this.getHeaders();
        headers.append("Content-Type", "application/x-www-form-urlencoded");
        headers.append("Content-Length", payload.length);
        let requestOptions = {
            method: "PUT",
            redirect: "follow",
            headers: headers,
            body: payload,
        };
        return fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => result)
            .catch((error) => console.error(error));
    }

    getHeaders() {
        let token = JSON.parse(Cookies.get("token" + process.env.REACT_APP_COOKIE_VERSION));
        token.valid_to = new Date(token.valid_to);
        let headers = new Headers();
        headers.append(
            "Authorization",
            `${JSON.parse(Cookies.get("token" + process.env.REACT_APP_COOKIE_VERSION)).token_type} ${JSON.parse(Cookies.get("token" + process.env.REACT_APP_COOKIE_VERSION)).access_token}`
        );
        return headers;
    }

    addParams(params) {
        if (params === undefined || params === null) {
            params = {};
        }
        if (params.key === undefined || params.key === null) {
            params.key = process.env.REACT_APP_FEIYR_API_KEY;
        }
        if (params.language === undefined || params.language === null) {
            params.language = this.getLanguage();
        }
        return params;
    }

    getLanguage() {
        return localStorage.getItem("lang") !== null
            ? localStorage.getItem("lang")
            : navigator.language.slice(0, 2) || navigator.userLanguage.slice(0, 2);
    }

    serializeObject(obj, prefix) {
        let str = [],
            p;
        for (p in obj) {
            if (obj.hasOwnProperty(p)) {
                let k = prefix ? prefix + "[" + p + "]" : p,
                    v = obj[p];
                str.push((v !== null && typeof v === "object") ?
                    this.serializeObject(v, k) :
                    encodeURIComponent(k) + "=" + encodeURIComponent(v));
            }
        }
        return str.join("&");
    }
}

export const postRefreshToken = (refreshToken) => {
    return new Promise((resolve, reject) => {
        if (Cookies.get("token" + process.env.REACT_APP_COOKIE_VERSION) == null) {
            reject(new Error("No Token found"));
        }
        else if (Date.now() - JSON.parse(Cookies.get("token" + process.env.REACT_APP_COOKIE_VERSION)).valid_to > 0 && (window.refreshRequestRunning === undefined || window.refreshRequestRunning === false)) {
            window.refreshRequestRunning = true;
            let myHeaders = new Headers();
            myHeaders.append("Authorization", process.env.REACT_APP_TOKEN_AUTH);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            let urlencoded = new URLSearchParams();
            urlencoded.append("grant_type", "refresh_token");
            urlencoded.append("refresh_token", refreshToken);
            myHeaders.append("Content-Length", urlencoded.toString().length.toString());

            let requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
                credentials: 'include'
            };
            fetch(`${process.env.REACT_APP_URL}/token`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result !== undefined && result.access_token !== undefined) {
                        //result.valid_to = Date.now() + (result.expires_in * 1000);
                        //Cookies.set("token", result, {expires: 365});
                        window.refreshRequestRunning = false;
                        resolve(result);
                    }
                    else {
                        window.refreshRequestRunning = false;
                        reject(new Error("Error refreshing access token (1)"));
                    }
                })
                .catch((error) => {
                    console.error(error);
                    window.refreshRequestRunning = false;
                    reject(new Error("Error refreshing access token (2)"));
                });
        }
        else {
            resolve(Cookies.get("token" + process.env.REACT_APP_COOKIE_VERSION));
        }
    });
}

export const postRevokeToken = () => {
    return new Promise((resolve, reject) => {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", process.env.REACT_APP_TOKEN_AUTH);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        let urlencoded = new URLSearchParams();
        urlencoded.append("token_type_hint", "access_token");
        urlencoded.append("token", JSON.parse(Cookies.get("token" + process.env.REACT_APP_COOKIE_VERSION)).access_token);
        myHeaders.append("Content-Length", urlencoded.toString().length.toString());

        let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow",
            credentials: 'include'
        };
        fetch(`${process.env.REACT_APP_URL}/oauthLogout`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result !== undefined && result.revoked !== undefined && result.revoked === true) {
                    resolve(result);
                }
                else {
                    reject(new Error("Error revoking token"));
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
}