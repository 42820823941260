import React from "react";
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";
import {Translate} from "../Translate";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import IsoDate from "../../js/IsoDate";

export default function SingleThread(props) {
    const id = props.threads.id;
    const messageText =
        typeof props.threads.content !== "undefined"
            ? props.threads.content
            : "error";

    const from =
        typeof props.threads.from !== "undefined"
            ? props.threads.from
            : "not available";
    const messageUrl = `/inbox/${id}`;

    const fullDate = props.threads.date;
    const newDate = new Date();
    const date = IsoDate(fullDate.substr(0, 10)).toLocaleDateString();
    const time = fullDate.slice(fullDate.length - 8);
    const now = new Date(Date.now()).toLocaleDateString();

    function getDate(myDate, days) {
        return new Date(myDate.getTime() + days * 24 * 60 * 60 * 1000);
    }

    const yesterday = getDate(newDate, -1).toISOString().slice(0, 10);

    return typeof props.threads.content === "undefined" ? (
        <p>
            <Translate text="Loading"/>
        </p>
    ) : (
        <Link to={messageUrl} className={"unstyled"} key={id}>
            <ListItem alignItems="flex-start" style={{paddingLeft: 0, paddingRight: 0}}>
                <ListItemAvatar>
                    {from === "System" ? (
                        <Avatar alt={from === "System" ? "Feiyr" : "User"} src={"/img/feiyrIcons/feiyr.png"}/>
                    ) : (
                        <Avatar alt={from === "System" ? "Feiyr" : "User"}/>
                    )}
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <>
                            <Box display="flex">
                                <Box flexGrow={1}>
                                    <span style={{fontSize: "0.8rem"}}>
                                        {from === "System" ? "Feiyr" : "User"}
                                    </span>
                                </Box>
                                <Box>
                                    <span style={{fontSize: "0.8rem"}}>
                                        {now === date
                                            ? time.slice(0, time.length - 3)
                                            : yesterday === date
                                                ? "yesterday"
                                                : date}
                                    </span>
                                </Box>
                            </Box>
                            <p className={"truncate"}>
                                {props.threads.subject}
                            </p>
                        </>
                    }
                    secondary={
                        <p className={"truncate"}>
                            {messageText.replaceAll("<br />", "")}
                        </p>
                    }
                />
            </ListItem>
            <Divider component="li"/>
        </Link>
    );
}
