import React from "react";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip,} from "recharts";
import Title from "../template/Title";
import {Translate} from "../Translate";

const style = {
    lineHeight: "1rem",
    left: 0
};

export default function DefaultPieChart({fetchData, graphName}) {
    const COLORS = [
        "#0088FE",
        "#00C49F",
        "#FFBB28",
        "#FF8042",
        "#8884d8",
        "#83a6ed",
        "#8dd1e1",
        "#82ca9d",
        "#a4de6c",
    ];

    let sum = 0;
    let data;

    const formatTooltip = (value) => {
        return `${value} %`;
    };

    if (Object.entries(fetchData).length > 0 && typeof fetchData !== "undefined") {
        Object.entries(fetchData).map((el) => {
            return (sum += el[1][1]);
        });
        data = Object.entries(fetchData).map((el) => {
            return {
                name: el[1][0],
                value: Number(((el[1][1] * 100) / sum).toFixed(2)),
            };
        });
    }

    return (
        <React.Fragment>
            <Title>{graphName}</Title>
            {typeof fetchData !== "undefined" && data !== undefined && data.length > 0 ? (
                <ResponsiveContainer height={220} width="100%">
                    <PieChart>
                        <Tooltip formatter={(value) => formatTooltip(value)}/>
                        <Pie
                            data={data}
                            outerRadius="90%"
                            fill="#8884d8"
                            paddingAngle={1}
                            dataKey="value"
                        >
                            {Object.entries(data).map((entry, index) => {
                                return (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                );
                            })}
                        </Pie>

                        <Legend
                            iconSize={10}
                            width={120}
                            layout="vertical"
                            verticalAlign="middle"
                            align="left"
                            wrapperStyle={style}
                        />
                    </PieChart>
                </ResponsiveContainer>
            ) : (
                <Translate text="NoDataAvailable"/>
            )}
        </React.Fragment>
    );
}
