import React, {useEffect} from "react";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {iosChecker, thirdPartyBrowser} from "../../js/BrowserCheck";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },

    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
    img: {
        // width: "80%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "50%",
    },
    root: {
        display: "flex",
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

export default function CustomizedDialogs() {
    const isIos = iosChecker();
    const notSafari = thirdPartyBrowser();
    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState({
        dontShowAgain: false,
    });
    const lang =
        localStorage.getItem("lang") !== null
            ? localStorage.getItem("lang")
            : navigator.language.slice(0, 2) || navigator.userLanguage.slice(0, 2);

    const handleClose = () => {
        state.dontShowAgain &&
        localStorage.setItem("a2hsModal", state.dontShowAgain);
        setOpen(false);
    };

    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.checked});
    };

    const classes = useStyles();

    useEffect(() => {
        localStorage.getItem("a2hsModal") === null && isIos
            ? setOpen(true)
            : setOpen(false);
    }, [isIos]);

    return (
        <div>
            {isIos ? (
                <Dialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {lang === "de"
                            ? "Auf Startbildschirm hinzufügen"
                            : "Add to Home Screen"}
                    </DialogTitle>
                    <DialogContent dividers>
                        {isIos && !notSafari ? (
                            <>
                                <Typography gutterBottom>
                                    {lang === "de"
                                        ? "Um die Feiyr App auf Deinem Startbildschirm abzulegen, führe bitte folgende Schritte durch:"
                                        : "To add the Feiyr app to your home screen, please follow the instructions:"}
                                </Typography>
                                <Typography gutterBottom>
                                    {lang === "de"
                                        ? '1. Tap auf den "Teilen"-Button'
                                        : '1. Tap on the "share" button'}
                                    <img alt="share" src={"/img/iosNav.jpg"} className={classes.img}/>
                                </Typography>
                                <Typography gutterBottom>
                                    {lang === "de"
                                        ? '"Zum Home-Bildschirm" aus dem Menü auswählen'
                                        : "'Select 'Add to Home Screen' from the menu"}
                                    <img
                                        alt="add2homescreen"
                                        src={"/img/a2hs.png"}
                                        className={classes.img}
                                    />
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography gutterBottom>
                                    Please use Safari to get the best Experience.
                                </Typography>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.root}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={state.dontShowAgain}
                                                onChange={handleChange}
                                                name="dontShowAgain"
                                            />
                                        }
                                        label={
                                            lang === "de"
                                                ? "Meldung nicht mehr anzeigen"
                                                : "Don't show this message again"
                                        }
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>

                        <Button autoFocus onClick={handleClose} color="primary">
                            {lang === "de" ? "Speichern" : "Save"}
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : null}
        </div>
    );
}
