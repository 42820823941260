import React, {useState} from "react";
import DataTable from "../../datatable/DataTable";
import {doTranslate, Translate} from "../../Translate";
import ErrorBox from "../../ErrorBox";
import {FeiyrApi} from "../../../js/FeiyrApi";
import IsoDate from "../../../js/IsoDate";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import OrderRequestForm from "./OrderRequestForm";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import BackButton from "../../BackButton";
import IconButton from "@mui/material/IconButton";
import {default as ExternalLink} from "@mui/material/Link/Link";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    expandableRow: {
        backgroundColor: theme.palette.expandableRow.main
    }
}));

export default function OrderRequest() {
    const classes = useStyles();
    const [error, setError] = useState(null);
    const [data, setData] = useState(false);
    const [entryCount, setEntryCount] = useState(0);

    const columns = [
        {
            name: "RELEASEDATE",
            label: "RELEASEDATE",
            options: {
                display: false,
                sort: true,
                filter: false,
                searchable: false,
            }
        },
        {
            name: "EAN",
            label: "EAN",
            options: {
                display: false,
                sort: true,
                filter: false,
                searchable: true,
            }
        },
        {
            name: "ARTIST",
            label: doTranslate("Artist, Title"),
            options: {
                display: true,
                sort: true,
                filter: false,
                searchable: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div>
                            <p style={{fontSize: "0.7rem", margin: "0"}}>{tableMeta.rowData[2]}</p>
                            <p
                                style={{
                                    fontWeight: "700",
                                    paddingTop: "0rem",
                                    paddingBottom: "0.5rem",
                                    margin: "0",
                                }}
                            >
                                {tableMeta.rowData[3]}
                            </p>
                        </div>
                    );
                },
            }
        },
        {
            name: "TITLE1",
            label: "TITLE1",
            options: {
                display: false,
                sort: true,
                filter: false,
                searchable: true,
            }
        },
        {
            name: "ARTICLENO",
            label: "ARTICLENO",
            options: {
                display: false,
                sort: true,
                filter: false,
                searchable: true,
            }
        },
        {
            name: "STOCK",
            label: doTranslate("Stock"),
            options: {
                display: true,
                sort: true,
                filter: false,
                searchable: false,
            }
        },
        {
            name: "ORDERED",
            label: doTranslate("Ordered"),
            options: {
                display: false,
                sort: true,
                filter: false,
                searchable: false,
            }
        },
        {
            name: "BACKORDER",
            label: "BACKORDER",
            options: {
                display: false,
                sort: true,
                filter: false,
                searchable: false,
            }
        },
        {
            name: "ARTICLEID",
            label: "ARTICLEID",
            options: {
                display: false,
                sort: false,
                filter: false,
                searchable: false,
            }
        },
    ];

    function fetchData(dataTable) {
        new FeiyrApi().get("/OrderRequests/Articles/" + localStorage.getItem("userId"), {}, dataTable)
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    let values = res.data.values.map((el) => {
                        return {
                            RELEASEDATE: el.RELEASEDATE,
                            EAN: el.EAN,
                            ARTIST: el.ARTIST,
                            TITLE1: el.TITLE1,
                            ARTICLENO: el.ARTICLENO,
                            STOCK: el.STOCK,
                            ORDERED: el.ORDERED,
                            BACKORDER: el.BACKORDER,
                            ARTICLEID: el.ARTICLEID
                        };
                    });
                    setData(values);
                    setEntryCount(res.data.display.iTotalRecords);
                }
                else {
                    setError({userMessage: "Error fetching data"});
                }
            })
            .catch(err => {
                err.userMessage = "Error fetching data";
                setError(err);
            });
    }

    /*
    0 => RELEASEDATE
    1 => EAN
    2 => ARTIST
    3 => TITLE1
    4 => ARTICLENO
    5 => STOCK
    6 => ORDERED
    7 => BACKORDER
    8 => ARTICLEID
     */

    const renderExpandableRow = (rowData) => {
        return (
            <tr>
                <td colSpan={8}>
                    <TableContainer component={Paper}>
                        <Table
                            aria-label=""
                            style={{width: "100%"}}
                            className={classes.expandableRow}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell><Translate text="Details"/></TableCell>
                                    <TableCell align="right"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Translate text="EAN"/>:
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {rowData[1]}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Translate text="Article Number"/>:
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {rowData[4]}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Translate text="Release Date"/>:
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {IsoDate(rowData[0]).toLocaleDateString()}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Translate text="Ordered"/>:
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {rowData[6]}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Translate text="Backorder"/>:
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {rowData[7]}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <OrderRequestForm articleId={rowData[8]}/>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </td>
            </tr>
        );
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={8}>
                <BackButton/>
            </Grid>
            <Grid item xs={4} style={{textAlign: "right"}}>
                <IconButton
                    component={Link}
                    to="/nova/orderrequest/special"
                    style={{padding: "6px", color: "#f5a200"}}
                >
                    <AddIcon/>
                </IconButton>
                <IconButton
                    component={ExternalLink}
                    href={"https://accounts.feiyr.com/bookdistribution/tools/orderRequest/info.html"}
                    target={"_blank"} color={"primary"}
                    style={{padding: "6px"}}
                >
                    <InfoIcon/>
                </IconButton>
            </Grid>
            {error === null ? (
                <Grid item xs={12}>
                    <DataTable
                        title={doTranslate("Order Request")}
                        data={data}
                        entryCount={entryCount}
                        columns={columns}
                        fetchData={fetchData}
                        tableOptions={{
                            "renderExpandableRow": renderExpandableRow
                        }}
                        showBackButton={false}
                    />
                </Grid>
            ) : (
                <ErrorBox error={error}/>
            )}
        </Grid>
    );
}