import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../template/Title";
import {Translate} from "../Translate";

export default function TopEbooks({fetchData}) {
    let data = [];
    if (typeof fetchData.error !== 'object') {
        data =
            typeof fetchData.error !== 'object'
                ? Object.entries(fetchData.data.values).map((el, i) => {
                    return {
                        id: el[1].book_id,
                        artist: el[1].bookauthor,
                        title: el[1].booktitle,
                        amount: el[1].qnt
                    }
                })
                : false;
    }

    return (
        <React.Fragment>
            <Title>
                <Translate text="TopEbooks"/>
            </Title>
            {data !== false && data.length > 0 ? (
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell><Translate text={"Author"}/></TableCell>
                            <TableCell><Translate text={"Title"}/></TableCell>
                            <TableCell><Translate text={"Amount"}/></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data !== false ? (
                            data.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.artist}</TableCell>
                                    <TableCell>{row.title}</TableCell>
                                    <TableCell align="right">{row.amount}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <Translate text="Loading"/>
                        )}
                    </TableBody>
                </Table>
            ) : (
                <Translate text="NoDataAvailable"/>
            )}
        </React.Fragment>
    );
}
