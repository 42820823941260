import React, {useMemo, useState} from "react";
import Grid from "@mui/material/Grid";
import {doTranslate, Translate} from "../Translate";
import TextField from "@mui/material/TextField";
import ProductLink from "./ProductLink";
import Button from "@mui/material/Button";
import Send from "@mui/icons-material/Send";
import {getSpotifyUri, isValidSpotifyUri, isValidURL} from "../../js/URL";

export default function OtherProductIncorrect(props) {
    const [validationError, setValidationError] = useState("");

    const setLink = (index, link) => {
        props.setProductLinks(prev => {
            // create a deep copy, doesn't work with references...
            let current = prev.map(item => item);
            current[index] = link;
            return current;
        });
    };

    const onRemove = (index) => {
        props.setProductLinks(prev => {
            // create a deep copy, doesn't work with references...
            return prev
                .map(item => item)
                .slice(0, index)
                .concat(prev.slice(index + 1, prev.length));
        });
    };

    const onAdd = () => {
        props.setProductLinks(prev => {
            // create a deep copy, doesn't work with references...
            let current = prev.map(item => item);
            current.push("");
            return current;
        });
    };

    const onChangeCurrentArtistProfile = (event) => {
        let value = event.target.value;
        if (props.partnerShopId === 158) {
            value = getSpotifyUri(value, "artist");
            if (!isValidSpotifyUri(value, "artist")) {
                setValidationError(doTranslate("Invalid Spotify URI"));
            }
            else {
                setValidationError("");
            }
        }
        else if (!isValidURL(value)) {
            setValidationError(doTranslate("Invalid URI"));
        }
        else {
            setValidationError("");
        }
        props.setCurrentArtistProfile(value);
    }

    const renderProductLinks = useMemo(() => {
        return () => {
            let links = [];
            for (let i = 0; i < props.productLinks.length; i++) {
                links.push((
                    <ProductLink
                        removable={i > 0}
                        index={i}
                        link={props.productLinks[i]}
                        setLink={(index, link) => setLink(index, link)}
                        onRemove={index => onRemove(index)}
                        onAdd={() => onAdd()}
                    />
                ))
            }
            return links;
        }
    }, [props.productLinks]);

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <TextField
                    style={{width: "100%"}}
                    required
                    id="current-artist-profile"
                    label={doTranslate("Current Artist Profile")}
                    onChange={onChangeCurrentArtistProfile}
                    value={props.currentArtistProfile}
                    variant="outlined"
                    error={validationError !== ""}
                    helperText={validationError}
                />
            </Grid>
            {renderProductLinks()}
            <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit" size="large" startIcon={<Send/>}>
                    <Translate text={"Submit"}/>
                </Button>
            </Grid>
        </React.Fragment>
    );
}