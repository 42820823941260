// Detects if device is on iOS
const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
};

export const iosChecker = () => {
    // Detects if device is in standalone mode
    const iOs = isIos();

    // Detect Chrome
    let chromeAgent = window.navigator.userAgent.indexOf("Chrome") > -1;
    // Detect Safari
    let safariAgent = window.navigator.userAgent.indexOf("Safari") > -1;

    // Discard Safari since it also matches Chrome
    if (chromeAgent && safariAgent) safariAgent = false;
    // Checks if should display install popup notification:
    return iOs && safariAgent;
};

export const thirdPartyBrowser = () => {
    // Detects if device is in standalone mode
    const iOs = isIos();

    // Detect Chrome Mobile
    let chromeMobileAgent = window.navigator.userAgent.indexOf("CriOS") > -1;

    let firefoxMobileAgent = window.navigator.userAgent.indexOf("FxiOS") > -1;

    let thirdParty = firefoxMobileAgent || chromeMobileAgent;
    // Detect Safari
    // let safariAgent = window.navigator.userAgent.indexOf("Safari") > -1;
    // Checks if should display install popup notification:
    return iOs && thirdParty;
};
