import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {doTranslate, Translate} from "../Translate";
import List from "@mui/material/List";
import React, {useEffect, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import LoadingBar from "../template/LoadingBar";
import ErrorBox from "../ErrorBox";
import {FeiyrApi} from "../../js/FeiyrApi";
import Send from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import ReturnRequestArticle from "./ReturnRequestArticle";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import ReturnRequestConfirmDialog from "./ReturnRequestConfirmDialog";

const useStyles = makeStyles(theme => ({
    expandableRow: {
        backgroundColor: theme.palette.expandableRow.main
    }
}));

export default function ReturnRequestForm(props) {
    const classes = useStyles();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState(null);
    const [articleList, setArticleList] = useState(null);
    const [returnArticles, setReturnArticles] = useState(null);
    const [disposalArticles, setDisposalArticles] = useState(null);
    const [quantities, setQuantities] = useState({});
    const [address, setAddress] = useState({});
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setLoading(true);
        const feiyrApi = new FeiyrApi();
        Promise.all([
            feiyrApi.get("/ContentData/ListCountries/id"),
            feiyrApi.get("/NovaReturnRequest/JobDetails/" + localStorage.getItem("userId") + "/" + props.jobId),
        ]).then(res => new Promise((resolve, reject) => {
            let success = true;
            res.forEach(response => {
                if (response === undefined || (response.success !== true && response.success !== "true")) {
                    success = false;
                }
            });
            if (success) {
                resolve(res);
            }
            else {
                reject(new Error("Error fetching data"));
            }
        })).then(res => {
            setCountries(res[0].data);
            let articles = res[1].data.articles.map(article => {
                article.ARTICLEID = parseInt(article.ARTICLEID);
                article.quantity = parseInt(article.quantity);
                return article;
            });
            setArticleList(articles);
            let totalQuantities = {};
            let returns = {};
            let disposals = {};
            articles.forEach(article => {
                returns[article.ARTICLEID] = article.quantity;
                disposals[article.ARTICLEID] = 0;
                totalQuantities[article.ARTICLEID] = article.quantity;
            });
            setQuantities(totalQuantities);
            setReturnArticles(returns);
            setDisposalArticles(disposals);
            setAddress(res[1].data.job);
            setLoading(false);
        }).catch(err => {
            err.userMessage = "Error fetching data";
            setError(err);
            setLoading(false);
        });
    }, []);

    const onDisposalQuantityChange = (articleId, disposalQuantity) => {
        let thisReturnQuantities = {},
            thisDisposalQuantities = {};
        thisReturnQuantities[articleId] = quantities[articleId] - disposalQuantity;
        thisDisposalQuantities[articleId] = disposalQuantity;
        setReturnArticles(previous => {
            return {...previous, ...thisReturnQuantities};
        });
        setDisposalArticles(previous => {
            return {...previous, ...thisDisposalQuantities};
        });
    };

    const onChangeAddress = (key, value) => {
        let temp = {};
        temp[key] = value;
        setAddress(previous => {
            return {...previous, ...temp}
        });
    };

    const onFormSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setConfirmDialogOpen(true);
    };

    const onConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    const onAcceptConfirm = () => {
        let postData = {
            address: JSON.stringify({
                email: address.email,
                company: address.company,
                first_name: address.first_name,
                last_name: address.last_name,
                zip: address.zip,
                street: address.street,
                city: address.city,
                country_id: address.country_id,
            }),
            ip: "0.0.0.0",
            disposal: {}
        };
        if (disposalArticles !== null && Object.keys(disposalArticles).length > 0) {
            postData.disposal = disposalArticles;
        }
        setLoading(true);
        new FeiyrApi().put("/NovaReturnRequest/AcceptJob/" + localStorage.getItem("userId") + "/" + props.jobId, postData)
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true")) {
                    setSuccess(true);
                    setLoading(false);
                }
                else {
                    setError({userMessage: "Error accepting job"});
                    setLoading(false);
                }
            })
            .catch(err => {
                err.userMessage = "Error accepting job";
                setError(err);
                setLoading(false);
            });
    };

    return (
        <React.Fragment>
            {error === null ? (
                <React.Fragment>
                    {loading === false && articleList !== null && countries !== null ? (
                        <React.Fragment>
                            {success === false ? (
                                <form onSubmit={onFormSubmit} style={{width: "100%"}}>
                                    <Grid container spacing={3} className={classes.expandableRow}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">
                                                <Translate text="Article List:"/>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <List>
                                                {articleList.map(article => (
                                                    <ReturnRequestArticle
                                                        jobId={props.jobId}
                                                        article={article}
                                                        onDisposalQuantityChange={onDisposalQuantityChange}
                                                        key={props.jobId + "-" + article.ARTICLEID}
                                                    />
                                                ))}
                                            </List>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">
                                                <Translate text="Address:"/>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                style={{width: "100%"}}
                                                required
                                                id={"email-" + props.jobId}
                                                label={doTranslate("E-Mail Address")}
                                                onChange={event => onChangeAddress("email", event.target.value)}
                                                value={address.email}
                                                variant="outlined"
                                                type="email"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                style={{width: "100%"}}
                                                id={"company-" + props.jobId}
                                                label={doTranslate("Company")}
                                                onChange={event => onChangeAddress("company", event.target.value)}
                                                value={address.company}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                style={{width: "100%"}}
                                                required
                                                id={"first-name-" + props.jobId}
                                                label={doTranslate("First Name")}
                                                onChange={event => onChangeAddress("first_name", event.target.value)}
                                                value={address.first_name}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                style={{width: "100%"}}
                                                required
                                                id={"last-name-" + props.jobId}
                                                label={doTranslate("Last Name")}
                                                onChange={event => onChangeAddress("last_name", event.target.value)}
                                                value={address.last_name}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                style={{width: "100%"}}
                                                required
                                                id={"street-" + props.jobId}
                                                label={doTranslate("Street")}
                                                onChange={event => onChangeAddress("street", event.target.value)}
                                                value={address.street}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                style={{width: "100%"}}
                                                required
                                                id={"zip-" + props.jobId}
                                                label={doTranslate("Zip")}
                                                onChange={event => onChangeAddress("zip", event.target.value)}
                                                value={address.zip}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                style={{width: "100%"}}
                                                required
                                                id={"city-" + props.jobId}
                                                label={doTranslate("City")}
                                                onChange={event => onChangeAddress("city", event.target.value)}
                                                value={address.city}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl style={{width: "100%"}} variant="outlined">
                                                <InputLabel id={"country-select-label-" + props.jobId}>
                                                    <Translate text={"Country"}/>
                                                </InputLabel>
                                                <Select
                                                    labelId={"country-select-label-" + props.jobId}
                                                    label={<Translate text={"Country"}/>}
                                                    id={"country-select-" + props.jobId}
                                                    value={address.country_id}
                                                    onChange={(event) => onChangeAddress("country_id", event.target.value)}
                                                >
                                                    <MenuItem key={0} value={0}>
                                                        <Translate text={"Please select"}/>
                                                    </MenuItem>
                                                    {Object.entries(countries).map(country => {
                                                        return (
                                                            <MenuItem key={country[0]} value={country[0]}>
                                                                {country[1]}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}/>
                                        <Grid item xs={6}>
                                            <Button variant="contained" color="primary" type="submit"
                                                    startIcon={<Send/>}
                                                    fullWidth={true}>
                                                <Translate text={"Submit"}/>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <ReturnRequestConfirmDialog
                                        open={confirmDialogOpen}
                                        onClose={onConfirmDialogClose}
                                        onDeny={onConfirmDialogClose}
                                        onAccept={onAcceptConfirm}
                                        address={address}
                                        returnArticles={returnArticles}
                                        disposalArticles={disposalArticles}
                                        articleList={articleList}
                                    />
                                </form>
                            ) : (
                                <Alert severity="success">
                                    <Translate text={"ReturnRequestAcceptedMessage"}/>
                                </Alert>
                            )}
                        </React.Fragment>
                    ) : (
                        <LoadingBar/>
                    )}
                </React.Fragment>
            ) : (
                <ErrorBox error={error}/>
            )}
        </React.Fragment>
    )
}