import {Translate} from "../Translate";
import React, {useState} from "react";
import Public from "@mui/icons-material/Public";
import LoadingBar from "../template/LoadingBar";
import {FeiyrApi} from "../../js/FeiyrApi";
import ErrorBox from "../ErrorBox";
import Alert from "@mui/material/Alert";
import {ConfirmDialog} from "../ConfirmDialog";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Button from '@mui/material/Button';

const useStyles = makeStyles(theme => ({
    expandableRow: {
        backgroundColor: theme.palette.expandableRow.main
    },
    green: {
        color: theme.palette.success.main
    }
}));

export default function Details(props) {
    const classes = useStyles();
    const {trackId, bundleId} = props;

    const [success, setSuccess] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [acceptDialogOpen, setAcceptDialogOpen] = useState(false);
    const [denyDialogOpen, setDenyDialogOpen] = useState(false);

    const onClickAccept = () => {
        setAcceptDialogOpen(true);
    };

    const onClickDeny = () => {
        setDenyDialogOpen(true);
    };

    const onAcceptDialogClose = () => {
        setAcceptDialogOpen(false);
    };

    const onDenyDialogClose = () => {
        setDenyDialogOpen(false);
    };

    const onAcceptConfirm = () => {
        setLoading(true);
        setAcceptDialogOpen(false);
        let params = {
            ugc: props.ugc
        };
        new FeiyrApi().post("/LicenseRequests/ApproveRequest/" + localStorage.getItem("userId") + "/" + bundleId + "/" + trackId, params)
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true") && (res.approved === true || res.approved === "true")) {
                    let approval = {};
                    approval[trackId] = 1;
                    setSuccess("approved");
                    props.setApprovalTracks(prev => {
                        return {...prev, ...approval};
                    });
                }
                else {
                    setError({userMessage: "Error accepting the request"});
                }
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                err.userMessage = "Error accepting the request";
                setError(err);
            });
    };

    const onDenyConfirm = () => {
        setLoading(true);
        setDenyDialogOpen(false);
        let params = {
            ugc: props.ugc
        };
        new FeiyrApi().post("/LicenseRequests/DenyRequest/" + localStorage.getItem("userId") + "/" + bundleId + "/" + trackId, params)
            .then(res => {
                if (res !== undefined && (res.success === true || res.success === "true") && (res.denied === true || res.denied === "true")) {
                    let approval = {};
                    approval[trackId] = 2;
                    setSuccess("denied");
                    props.setApprovalTracks(prev => {
                        return {...prev, ...approval};
                    });
                }
                else {
                    setError({userMessage: "Error denying the request"});
                }
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                err.userMessage = "Error denying the request";
                setError(err);
            });
    };

    return (
        <tr>
            <td colSpan={6}>
                <Paper style={{padding: "16px"}}>
                    <Grid container spacing={3} className={classes.expandableRow}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                <Translate text="Track List:"/>
                            </Typography>
                            <List>
                                {Object.entries(props.trackList).map(entry => {
                                    return (
                                        <ListItem style={{paddingTop: 0, paddingBottom: 0}} key={entry[1].track_id}>
                                            <ListItemIcon>
                                                <Typography variant="body1">
                                                    {parseInt(entry[0]) + 1}.
                                                </Typography>
                                            </ListItemIcon>
                                            <ListItemText>
                                                {entry[1].track_id === props.trackId
                                                    ? <b>{entry[1].artist} - {entry[1].title}</b>
                                                    : entry[1].artist + " - " + entry[1].title}
                                            </ListItemText>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                <Translate text="Country List:"/>
                            </Typography>
                            <List>
                                {props.countryList.length === 0 || (props.countryList.length === 1 && props.countryList[0].iso.toLowerCase() === "ww") ? (
                                    <ListItem>
                                        <ListItemIcon>
                                            <Public style={{color: "green"}}/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Translate text="Worldwide"/>
                                        </ListItemText>
                                    </ListItem>
                                ) : (
                                    <React.Fragment>
                                        {props.countryList.map(country => (
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img
                                                        src={"https://accounts.feiyr.com/img/flags_svg/" + country.iso.toLowerCase() + ".svg"}
                                                        alt={country.iso}/>
                                                </ListItemIcon>
                                                <ListItemText>
                                                    {country.name}
                                                </ListItemText>
                                            </ListItem>
                                        ))}
                                    </React.Fragment>
                                )}
                            </List>
                        </Grid>
                        {loading === false ? (
                            <React.Fragment>
                                {success === "" ? (
                                    <React.Fragment>
                                        {props.approvalTracks[trackId] === 0 ? (
                                            <React.Fragment>
                                                {error === null ? (
                                                    <React.Fragment>
                                                        <Grid item xs={6}>
                                                            <Button
                                                                variant="contained"
                                                                style={{width: "100%"}}
                                                                onClick={onClickDeny}
                                                            >
                                                                <Translate text="Deny"/>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                style={{width: "100%"}}
                                                                onClick={onClickAccept}
                                                            >
                                                                <Translate text="Accept"/>
                                                            </Button>
                                                        </Grid>
                                                    </React.Fragment>
                                                ) : (
                                                    <Grid item xs={12}>
                                                        <ErrorBox error={error}/>
                                                    </Grid>
                                                )}
                                            </React.Fragment>
                                        ) : (
                                            <Grid item xs={12}>
                                                <Alert severity="info">
                                                    <Translate text="Already Accepted or Denied"/>
                                                </Alert>
                                            </Grid>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {success === "approved" ? (
                                            <Grid item xs={12}>
                                                <Alert severity="success">
                                                    <Translate text="License Request Accepted"/>
                                                </Alert>
                                            </Grid>
                                        ) : (
                                            <Grid item xs={12}>
                                                <Alert severity="success">
                                                    <Translate text="License Request Denied"/>
                                                </Alert>
                                            </Grid>
                                        )}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        ) : (
                            <Grid item xs={12}>
                                <LoadingBar/>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
                <ConfirmDialog
                    open={acceptDialogOpen}
                    onClose={onAcceptDialogClose}
                    title={<Translate text={"Accept License Request"}/>}
                    text={<Translate text={"AcceptLicenseRequestText"}/>}
                    onDeny={onAcceptDialogClose}
                    onAccept={onAcceptConfirm}
                />
                <ConfirmDialog
                    open={denyDialogOpen}
                    onClose={onDenyDialogClose}
                    title={<Translate text={"Deny License Request"}/>}
                    text={<Translate text={"DenyLicenseRequestText"}/>}
                    onDeny={onDenyDialogClose}
                    onAccept={onDenyConfirm}
                />
            </td>
        </tr>
    );
}