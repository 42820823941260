import React, {useEffect, useState} from "react";
import * as serviceWorker from "../serviceWorkerRegistration";
import {Translate} from "./Translate";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";

export const Notification = () => {
    const [showReload, setShowReload] = useState(false);
    const [waitingWorker, setWaitingWorker] = useState(null);

    const onSWUpdate = (registration) => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
    };

    useEffect(() => {
        serviceWorker.register({onUpdate: onSWUpdate});
    }, []);

    const reloadPage = () => {
        waitingWorker.postMessage({type: "SKIP_WAITING"});
        setShowReload(false);
        window.location.reload(true);
    };

    return (
        <Snackbar
            open={showReload}
            message="A new version is available!"
            onClick={reloadPage}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            action={
                <Button
                    color="inherit"
                    size="small"
                    onClick={reloadPage}
                >
                    <Translate text={"Reload"}/>
                </Button>
            }
        />
    );
};


