import React, {useEffect} from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Cookies from "js-cookie";
import {Translate} from "../Translate";

export default function SuccessModal(props) {
    const [open, setOpen] = React.useState(true);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        const queryString = props.location.search;
        const urlParams = new URLSearchParams(queryString);
        const authorizationCode = urlParams.get("code");

        if (authorizationCode !== null && Cookies.get("token" + process.env.REACT_APP_COOKIE_VERSION) !== undefined) {
            setOpen(true);
        }
    }, [props.location.search]);

    return (
        <>
            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" elevation={6} variant="filled">
                    <Translate text="AccountConnected"/>
                </Alert>
            </Snackbar>
        </>
    );
}
